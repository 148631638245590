import React, { useEffect, useMemo, useState } from "react";
import ReactTableCustom from "components/ReactTableCustom";
import { format } from "date-fns";
import { currencyFormat, classForTransactionType, classForTransactionListingStatus } from "utils";
import "./Users.css";
import { FetchUserTransactionList } from "./Queries";
import TransactionModel  from "pages/Transaction/TransactionModel";
    
const UserTransactionsTable = ({userId}) => { 
    const [fetchUserTransactionListPayload, setFetchUserTransactionListPayload] = useState(null);
    const {data: userTransactionList}= FetchUserTransactionList(fetchUserTransactionListPayload);
    const listData = useMemo(() => userTransactionList?.results || [], [userTransactionList]);

    
    const columns = useMemo(
        () => [
            
          {
            Header: "Transection ID",
            accessor: "transaction_number",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p className="fw600 maincolor">{original?.transaction_number}</p>
                )
              },
          },          
          {
            Header: "Stock",
            accessor: "listing__unique_id",
            width: "100px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p className="fw600 greycolor underline">{original?.listing_unique_id}</p>
                )
              },
          },
          {
            Header: "Date",
            accessor: "created_at",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>
                        <p>{original && original.charge?.created ? format(new Date(original.charge.created), "MMM dd, yyyy") : null}</p>
                        <p className="font10 fw400 grey60color">{original && original.charge?.created ? format(new Date(original.charge?.created), "hh:mm b") : null}</p>
                    </div>
                )
                  
                },
           
          },   
          {
            Header: "Type",
            accessor: "transaction_for",            
            width: "90px",
            Cell: ({ row: { original } }) => {
                return ( 
                    classForTransactionType(original?.transaction_for)
                )
              },
          },
          {
            Header: "Amount",
            accessor: "amount",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return (
                    <p className="fw700 greenColour">{original && original.amount ? currencyFormat(original.amount) : null}</p>
                )
                
              },           
          },                 
          {
            Header: "Status",
            accessor: "status",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    classForTransactionListingStatus(original?.status)
                )
            },
          },    
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">
                  <button
                    onClick={() => {
                      handleViewItem(original);
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/actions-icon.png" alt="Actions" />
                    </span>
                  </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
      );
    
    const [search, setSearch] = useState(null); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownPageSize, setDropdownPageSize] = useState(30);
    const [sorts, setSorts] = useState([ {
                                id: "id",
                                desc: false,
                                },
                            ]);
    const pageChangeHandler = (pageNo) => {
        setCurrentPage(pageNo);
    };
    const setSearchHandler = (searchKeyword) => {
        setSearch(searchKeyword);
    };
    const setSortsHandler = (col) => {
        setSorts(col);
    };
    const [openTransactionModel, setOpenTransactionModel] = useState(false);
    const [transactionModelData, setTransactionModelData] = useState(null);
    
    const handleViewItem = (value) => {
        setOpenTransactionModel(true);
        setTransactionModelData(value);
    };
    const handleOkTransaction = (link) => {
      window.open(`${link}`, "_blank");
      setOpenTransactionModel(false);
    };
    const handleCancelTransaction = () => {
      setOpenTransactionModel(false);
    };
    

    useEffect(() => {
      setFetchUserTransactionListPayload({
            user_id: userId,
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts
        });
    }, [currentPage]);

    useEffect(() => {
        if (search || search === "") {
        setCurrentPage(1);
        setFetchUserTransactionListPayload({
            user_id: userId,
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts
        });
        }
    }, [search]);

    useEffect(() => {
        if (sorts) {
          setFetchUserTransactionListPayload({
            user_id: userId,
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts
        });
        }
    }, [sorts]);

    return (  
            <div className='mtm15'>                
                <ReactTableCustom
                    dropdownPageSize={dropdownPageSize}
                    setDropdownPageSize={''}
                    currentPage={currentPage}
                    pageChangeHandler={pageChangeHandler}
                    data={listData}
                    columns={columns}                    
                    paginationProps={userTransactionList?.pagination_option}
                    setSearchHandler={setSearchHandler}                    
                    setFiltersHandler={setSortsHandler}                    
                    placeholder={"User Transaction Listing"}
                    initialStateSortBy={sorts}
                    showPaginationBottom={true}                   
                    hideTopbar={true}
                    showShowSearchTop={true}
                    />
                <TransactionModel
                    open={Boolean(openTransactionModel)}
                    data={transactionModelData}                    
                    handleOk={handleOkTransaction}
                    handleCancel={handleCancelTransaction}
                />
            </div>       
    );
}

export default UserTransactionsTable;