import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAPI, useToast } from "services";

const ValidationLoginDetails = (body) => {
  const method = "POST";
  const url = `auth/admin-login/`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleLoginFormAPI = () => {
  
  const navigate = useNavigate();
  const responseToast = useToast();
  const queryClient = useQueryClient();
  
  return useMutation(ValidationLoginDetails, {    
    onSuccess: (response) => {      
      localStorage.clear();
      queryClient.invalidateQueries();
      localStorage.setItem("token", response.data.access_token);     
      localStorage.setItem("refresh_token", response.data.refresh_token);     
      localStorage.setItem("user_details", JSON.stringify(response.data.user));
      responseToast.toastSuccess(response.description || "Logged In successfully");      
      navigate('/dashboard');
    },
    onError: (error) => {
      if (error && error.errors) {        
        responseToast.toastError(error.errors[0].message || "Invalid Credentials" );
      }
    },
  });
};

// //////Refresh token

const RefreshAccessToken = () => {
  const method = "POST";
  const url = `api/auth/refresh-token`;
  return useAPI({ method, url });
};

export const HandleRefreshAccesTokenAPI = () => {
  const responseToast = useToast();
  return useMutation(RefreshAccessToken, {
    onSuccess: (response) => {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("expires", response.data.expires);
      localStorage.setItem("refresh_token", response.data.refresh_token);
    },
    retry: 3,
  });
};

const FetchUserDetails = () => {
  const method = "GET";
  const url = `api/auth/me`;
  return useAPI({ method, url });
};

export const FetchUserDetailsAPI = () => {
  return useQuery("GET_LOGGED_IN_USER_DETAILS", FetchUserDetails, {
    enabled: true,
  });
};












/*


import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useAPI, useToast } from "services";

const ValidationLoginDetails = (body) => {
  const method = "POST";
  const url = `auth/admin-login/`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleLoginFormAPI = () => {
  const navigate = useNavigate();
  const responseToast = useToast();
  
  return useMutation(ValidationLoginDetails, {
    onSuccess: (response) => {
      localStorage.setItem("token", response.data.access_token.token);
      localStorage.setItem("expires", response.data.access_token.expires);
      localStorage.setItem("refresh_token", response.data.access_token.refresh_token );
      //localStorage.setItem("user_role", JSON.stringify(response.data.role));
      responseToast.toastSuccess(response.description || "Logged In successfully");      
      navigate('/dashboard');
    },
    onError: (error) => {
      if (error && error.errors) {
        responseToast.toastError(
          error.errors[0].message || "Invalid Credentials"
        );
      }
    },
  });
};

// //////Refresh token

const RefreshAccessToken = () => {
  const method = "POST";
  const url = `auth/refresh-token`;
  return useAPI({ method, url });
};

export const HandleRefreshAccesTokenAPI = () => {
  const responseToast = useToast();
  return useMutation(RefreshAccessToken, {
    onSuccess: (response) => {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("expires", response.data.expires);
      localStorage.setItem("refresh_token", response.data.refresh_token);
    },
    retry: 3,
  });
};
*/
