import { useQuery,useMutation} from "@tanstack/react-query";
import { useAPI, useToast } from "services";


const FetchNotificationsList = (body) => {
  const method = "GET"; 
  let url = `notification/?per_page=1000`;  
  
  return useAPI({ method, url });
};

export const FetchNotificationsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_NOTIFICATIONS"],
    () => FetchNotificationsList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const DeleteNotificationsItem = (id) => {  
  const method = "DELETE";
  const url = `notification/${id}/`;
  return useAPI({ method, url });
};

export const DeleteNotificationsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_NOTIF_LIST", payload],
    () => DeleteNotificationsItem(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};


const SaveFCMToken = (body) => {
  const method = "POST";  
  const url = `devices/`;
  const payload = {
    device_id: null,
    registration_id: body,
    type: 'web'
  };
  return useAPI({ method, url, body: payload });
};

export const SaveFCMTokenMutation = () => {  
  return useMutation(SaveFCMToken, {
    onSuccess: (response) => {
      console.log('saveFCMTokenMutation Success', response);
      console.log('FCM Token Saved On Server');
    },
    onError: (error) => {
      console.log('saveFCMTokenMutation Error', error);
    }
  });
};

const UpdateNotifications = (body) => {
  const method = "PATCH";  
  const url = `notification/${body.id}/`;  
  return useAPI({ method, url, body: body });
};

export const UpdateNotificationsQuery = () => {
  const responseToast = useToast();

  return useMutation(UpdateNotifications, {
    onSuccess: (response) => null,
    onError: (error) => responseToast.toastError(error && error.errors[0]?.message),
  });
};

const FetchFinance = (body) => {
  const method = "GET"; 
  let url = `finance/${body}/`;  
  
  return useAPI({ method, url });
};

export const FetchFinanceQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_UP_finance", payload],
    () => FetchFinance(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};