const classForDealsListingStatus = (status, isDot=true) => {
  switch (status) {
    case "QUOTE_SENT":
        return ( 
            <p className={"yellowColour " + (isDot? 'disList' : '')}>
                Quote Sent
            </p>
        )
    case "DEPOSIT":
        return ( 
            <p className={"greenColour " + (isDot? 'disList' : '')}>
                Deposit
            </p>
        )
    case "DEPOSIT_SUCCESSFUL":
        return ( 
            <p className={"greenColour " + (isDot? 'disList' : '')}>
                Deposit Successful
            </p>
        )
    case "COMPLETE":
        return ( 
            <p className={"grayColour " + (isDot? 'disList' : '')}>
                Complete
            </p>
        )
   
  default:
    return status;
}

};

export default classForDealsListingStatus;
