import { useQuery } from "@tanstack/react-query";
import { useAPI, useToast } from "services";


const FetchInventory = (body) => {
  const method = "GET"; 
  let url = `dashboard-inventory/`;  
  return useAPI({ method, url });
};

export const FetchInventoryQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DAS_Inventory", payload],
    () => FetchInventory(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchUser = (body) => {
  const method = "GET"; 
  let url = `dashboard-user/`;  
  return useAPI({ method, url });
};

export const FetchUserQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DAS_USER", payload],
    () => FetchUser(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchInquiry = (body) => {
  const method = "GET"; 
  let url = `dashboard-inquiry/`;  
  return useAPI({ method, url });
};

export const FetchInquiryQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DAS_Inquiry", payload],
    () => FetchInquiry(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchFinancing = (body) => {
  const method = "GET"; 
  let url = `dashboard-financing/`;  
  return useAPI({ method, url });
};

export const FetchFinancingQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DAS_financing", payload],
    () => FetchFinancing(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchTopDeals = (body) => {
  const method = "GET"; 
  let url = `dashboard-top-deals/`;  
  return useAPI({ method, url });
};

export const FetchTopDealsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DAS_TOP_DEALS", payload],
    () => FetchTopDeals(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchPastInquiry = (body) => {
  const method = "GET"; 
  let url = `dashboard-top-quotes/`;  
  return useAPI({ method, url });
};

export const FetchPastInquiryQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DAS_PastInquiry", payload],
    () => FetchPastInquiry(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchInProgressGraph = (body) => {
  const method = "GET"; 
  let url = `dashboard-graph/`;  
  return useAPI({ method, url });
};

export const FetchInProgressGraphQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DAS_GRAPH", payload],
    () => FetchInProgressGraph(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};





