import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { LabelledTextField,LabelledTextFieldWithAdornments } from "components";
import { PrimaryBlueButton } from "components/CustomButtons";
import OTPInput, { ResendOTP } from "otp-input-react";

import * as yup from "yup";
import { HandleForgotPasswordFormAPI, HandleOTPFormAPI,HandlePasswordFormAPI } from "./Queries";
import './ForgotPassword.css';

const userValidSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Please enter a valid email"),
});
const passwordValidSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be atleast 8 characters long")
    .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
    'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
),
  confirm_password: yup
    .string()
    .required("Confirm password is a required field")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ForgotPassword = () => {
  const [fPPageMode, setFPPageMode] = useState('Email');
  const [emailData, setemailData] = useState(null);
  const [OTP, setOTP] = useState("");
  const {
    register,
    handleSubmit,
    watch: fpFormCredentials,
    setValue,
    formState: { errors },
    setFocus,
  } = useForm({ resolver: yupResolver(userValidSchema) });

  //useEffect
  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const handleChangeValue = (field, value) => {
    setValue(field, value, { shouldValidate: true });   
  };
  const { mutateAsync: ValidationFpCredentials } =  HandleForgotPasswordFormAPI(fpFormCredentials);
  const submitFpForm = (data) => {
    ValidationFpCredentials(data).then((respData) => {      
      if(respData?.status_code===200){          
        setemailData(data.email);
        setFPPageMode('OTP');
      }
    });
  };
  const handleResendOTP = () => {
    const resendemail = {"email": emailData};
    ValidationFpCredentials(resendemail);
  };
  
  const { mutateAsync: ValidationOTPCredentials } =  HandleOTPFormAPI();
  const handleOTP = () => {
    const otpdata = {"channel": "EMAIL_OTP","email": emailData, "otp": OTP};
    ValidationOTPCredentials(otpdata).then((respData) => {      
      if(respData?.status_code===200){
        setFPPageMode('password');
      }
    });
  };
  
  /*********New Password**********/
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: passwordFormCredentials,
    setValue: setValue2,
    formState: { errors: errors2  },
   
  } = useForm({ resolver: yupResolver(passwordValidSchema) });  

  const handleChangePValue = (field, value) => {
    setValue2(field, value, { shouldValidate: true });   
  };
  const { mutateAsync: ValidationPasswordCredentials } = HandlePasswordFormAPI(passwordFormCredentials);
  
  const submitPasswordForm = (data) => {
    data.email = emailData;
    ValidationPasswordCredentials(data).then((respData) => {      
      if(respData?.status_code===null){          
        setFPPageMode('success');
      }
    });
  };
  
  return (
     <div>
        <Grid container spacing={0}>            
            <Grid xs={12}>
                <div className="login-side">
                  <img src="/assets/full-logo.png"  className='logo' alt="Logo" />                   
                </div>
                {fPPageMode==='Email' && (
                  <div className="fp-container">
                    <div style={{display: 'inline-block', width:'100%'}}>
                      <div className="fpIcon">
                        <img src="/assets/password-key-icon.png"  className='pKeyIcon' alt="key" />                   
                      </div>                
                      <div className='fp-title'>Forgot Password</div>
                      <div className='fp-text'>No worries, we'll send you reset instructions.</div>
                      <form
                        onSubmit={handleSubmit(submitFpForm)}
                        id="login-form"
                        className=''
                      >
                        <LabelledTextField
                            label="Email"
                            type="text"
                            placeholder="Enter email"
                            onChange={(e) =>
                                handleChangeValue("email", e?.target?.value)
                              }
                            inputRef={register("email").ref}                                
                            error={Boolean(errors.email)}
                            helperText={errors && errors.email && errors.email.message}
                        />
                        
                        <div className="loginfull" style={{paddingTop:'30px'}}>
                          <PrimaryBlueButton
                            variant="contained"
                            type="submit"
                            form="login-form"
                            disableElevation
                            className="loginbtn"
                          >
                            Continue
                          </PrimaryBlueButton>
                        </div>

                        
                      </form>
                      <div className="fp-BackToLogin">
                        <a href="/login" className="BackToLogin">
                          <ArrowBack className="fp-icon"/> &nbsp;&nbsp;<span>Back to log in</span>
                        </a>
                      </div>
                    </div>
                </div>
                )}
                {fPPageMode==='OTP' && (
                <div className="fp-container">
                  <div style={{display: 'inline-block', width:'100%'}}>
                    <div className="fpIcon">
                      <img src="/assets/email-ring-icon.png"  className='pKeyIcon' alt="email" />                   
                    </div>                
                    <div className='fp-title'>Check your email</div>
                    <div className='fp-text'>A verification code was sent to <br/>{emailData}</div>                    
                      <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} className="otpinput" />                      
                      <div className="loginfull" style={{color: '#757575', textAlign:'center',paddingTop:'30px'}}>
                        Please do not share this code with anyone. Sell My Rig and its associates will never ask for your verification code.
                      </div>
                      <div className="loginfull resentdiv" style={{display:'flex',justifyContent:'center'}}>
                        <span style={{textAlign:'center'}}>Didn't receive the email?</span>
                        <span className="resentSec"><ResendOTP onResendClick={handleResendOTP} /></span>
                      </div>
                      <div className="loginfull">
                        <PrimaryBlueButton
                          variant="contained"
                          type="buttion"
                          onClick={handleOTP}                          
                          disableElevation
                          className="loginbtn"
                        >
                          Continue
                        </PrimaryBlueButton>
                      </div>                      
                    
                    <div className="fp-BackToLogin">
                      <a href="/login" className="BackToLogin">
                        <ArrowBack className="fp-icon"/> &nbsp;&nbsp;<span>Back to log in</span>
                      </a>
                    </div>
                  </div>
                </div>
                )}
                {fPPageMode==='password' && (
                  <div className="fp-container">
                    <div style={{display: 'inline-block', width:'100%'}}>
                      <div className="fpIcon">
                        <img src="/assets/password-key-icon.png"  className='pKeyIcon' alt="key" />                   
                      </div>                
                      <div className='fp-title'>Set new password</div>
                      <div className='fp-text'>Your new password must be different to previously used passwords.</div>
                      <form
                        onSubmit={handleSubmit2(submitPasswordForm)}
                        id="newPass-form"
                        className=''
                      >
                        <LabelledTextFieldWithAdornments
                          label="Enter Password"
                          type="password"
                          placeholder="Enter password"
                          onChange={(e) =>
                            handleChangePValue("password", e?.target?.value)
                            }
                          inputRef={register2("password").ref} 
                          error={Boolean(errors2.password)}
                          helperText={errors2 && errors2.password && errors2.password.message}
                          className='loginInputField'
                        />
                        <LabelledTextFieldWithAdornments
                          label="Confirm Password"
                          type="password"
                          placeholder="Confirm password"
                          onChange={(e) =>
                            handleChangePValue("confirm_password", e?.target?.value)
                            }
                          inputRef={register2("confirm_password").ref} 
                          error={Boolean(errors2.confirm_password)}
                          helperText={errors2 && errors2.confirm_password && errors2.confirm_password.message}
                          className='loginInputField'                         
                        />
                        
                        <div className="loginfull">
                          <PrimaryBlueButton
                            variant="contained"
                            type="submit"
                            form="newPass-form"
                            disableElevation
                            className="loginbtn"
                          >
                            Reset password
                          </PrimaryBlueButton>
                        </div>                        
                      </form>
                      <div className="fp-BackToLogin">
                        <a href="/login" className="BackToLogin">
                          <ArrowBack className="fp-icon"/> &nbsp;&nbsp;<span>Back to log in</span>
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {fPPageMode==='success' && (
                  <div className="fp-container">
                    <div style={{display: 'inline-block', width:'100%'}}>
                      <div className="fpIcon">
                        <img src="/assets/success-arrow-icon.png"  className='pKeyIcon' alt="key" />                   
                      </div>                
                      <div className='fp-title'>Password reset</div>
                      <div className='fp-text'>Your password has been successfully reset.</div>
                      <div className="loginfull">
                        <a href="/login" className="BackToLogin">
                          <PrimaryBlueButton
                            variant="contained"
                            type="buttion"                            
                            disableElevation
                            className="loginbtn"
                          >
                            Login
                          </PrimaryBlueButton>
                        </a>
                      </div>
                    </div>
                  </div>
                )}                
            </Grid>
        </Grid>
    </div>    
  );
};

export default ForgotPassword;
