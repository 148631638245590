import React, { useEffect, useMemo, useState } from "react";
import ReactTableCustom from "components/ReactTableCustom";
import { format } from "date-fns";
import { currencyFormat, classForSellerListingStatus } from "utils";
import "./Users.css";
import { FetchUserSellerList } from "./Queries";
    
const UserProductsTable = ({userId}) => { 
    const [fetchUserSellerListPayload, setFetchUserSellerListPayload] = useState(null);
    const {data: userSellerList}= FetchUserSellerList(fetchUserSellerListPayload);
    const listData = useMemo(() => userSellerList?.results || [], [userSellerList]);
    
    const columns = useMemo(
        () => [
          {
            Header: "Stock",
            accessor: "unique_id",
            width: "100px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p className="fw600 greycolor underline">{original?.unique_id}</p>
                )
              },
          },
          
          {
            Header: "Category",
            accessor: "category__name",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.category}</p>
                )
            },
            
          },
          {
            Header: "Make",
            accessor: "vehicle__make",          
            width: "120px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.brand}</p>
                )
              },
          },          
          {
            Header: "Date",
            accessor: "created_at",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>
                        <p>{original && original.created_at ? format(new Date(original.created_at), "MMM dd, yyyy") : null}</p>
                        <p className="font10 fw400 grey60color">{original && original.created_at ? format(new Date(original.created_at), "hh:mm b") : null}</p>
                    </div>
                )
                  
                },
           
          },         
          {
            Header: "Price",
            accessor: "price",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return (
                    <p className="fw700 maincolor">{original && original.price ? currencyFormat(original.price) : null}</p>
                )
                
              },           
          },
          {
            Header: "Status",
            accessor: "status",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    classForSellerListingStatus(original?.status)
                )
            },
          },    
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">
                  <button
                    onClick={() => {
                      handleViewItem(original?.id);
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/actions-icon.png" alt="Actions" />
                    </span>
                  </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
      );
    
    const [search, setSearch] = useState(null); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownPageSize, setDropdownPageSize] = useState(30);
    const [sorts, setSorts] = useState([ {
                                id: "id",
                                desc: false,
                                },
                            ]);
    const pageChangeHandler = (pageNo) => {
        setCurrentPage(pageNo);
    };
    const setSearchHandler = (searchKeyword) => {
        setSearch(searchKeyword);
    };
    const setSortsHandler = (col) => {
        setSorts(col);
    };
    const handleViewItem = (value) => {        
        window.open(`/seller-listing/details?id=${value}`, "_blank")
    };

    useEffect(() => {
      setFetchUserSellerListPayload({
            user_id: userId,
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts
        });
    }, [currentPage]);

    useEffect(() => {
        if (search || search === "") {
        setCurrentPage(1);
        setFetchUserSellerListPayload({
            user_id: userId,
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts
        });
        }
    }, [search]);

    useEffect(() => {
        if (sorts) {
          setFetchUserSellerListPayload({
            user_id: userId,
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts
        });
        }
    }, [sorts]);

    return (  
            <div className='mtm15'>                
                <ReactTableCustom
                    dropdownPageSize={dropdownPageSize}
                    setDropdownPageSize={''}
                    currentPage={currentPage}
                    pageChangeHandler={pageChangeHandler}
                    data={listData}
                    columns={columns}                    
                    paginationProps={userSellerList?.pagination_option}
                    setSearchHandler={setSearchHandler}                    
                    setFiltersHandler={setSortsHandler}                    
                    placeholder={"User Sellar Listing"}
                    initialStateSortBy={sorts}
                    showPaginationBottom={true}                   
                    hideTopbar={true}
                    showShowSearchTop={true}
                    />
            </div>       
    );
}

export default UserProductsTable;