import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid, FormControlLabel} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import './login.css';
import { LabelledTextField, LabelledTextFieldWithAdornments,CheckboxField } from "components";
import { PrimaryBlueButton } from "components/CustomButtons";
import * as yup from "yup";
import { HandleLoginFormAPI } from "./LoginQueries";
// import { CustomCheckbox } from "components/CustomCheckbox";


const useStyles = makeStyles(theme => ({
  checkboxRoot: {
    // Custom styles for the root element of the Checkbox component
    // Add your desired styles here
    // Example: backgroundColor: '#ff0000',
    backgroundColor:"cyan",
    ".CheckboxField-icon":{
      backgroundColor:"cyan"
    }
  },
  checkboxIcon: {
    // Custom styles for the icon element of the Checkbox component
    // Add your desired styles here
    // Example: color: '#00ff00',
  },
}));

const userValidSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Please enter a valid email"),
  password: yup.string().required("Password is a required field"),
});


const LoginPage = () => {
  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    setFocus,
  } = useForm({ resolver: yupResolver(userValidSchema) });

  //useEffect
  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const { mutate: ValidationLoginCredentials } =
    HandleLoginFormAPI(loginFormCredentials);

  const submitLoginForm = (data) => {
    ValidationLoginCredentials(data);
  };

  const { ref: refRegisterEmail, ...RegisterLoginEmail } = register("email");
  const { ref: refRegisterPassword, ...RegisterLoginPassword } = register("password");
  const classes = useStyles();

  return (
    
    
     <div>
        <Grid container spacing={0}>
            <Grid item xs={6}>
                <div className='loginTurckImage'>
                  <img src="/assets/turck-login.png" className='loginTurck' alt="Turck Login" />
                </div>
            </Grid>
            <Grid item xs={6} style={{marginLeft:'-41px'}}>
                <div className="login-side">
                  <img src="/assets/full-logo.png"  className='logo' alt="Logo" />                   
                </div>
                <div className="login-container">
                
                   <div className='login-title'>Log in </div>
                   <div className='login-text'>Welcome back! Please enter your details.</div>
                   <form
                      onSubmit={handleSubmit(submitLoginForm)}
                      id="login-form"
                      className=''
                    >
                      <LabelledTextField
                        label="Email"
                        type="email"
                        placeholder="Enter your Email"
                        inputRef={refRegisterEmail}
                          {...RegisterLoginEmail}
                        error={Boolean(errors.email)}
                        helperText={errors && errors.email && errors.email.message}
                        className='loginInputField'
                        autoComplete="email"
                      />
                      <LabelledTextFieldWithAdornments
                        label="Password"
                        type="password"
                        placeholder="Enter your password"                        
                        inputRef={refRegisterPassword}
                        {...RegisterLoginPassword}
                        error={Boolean(errors.password)}
                        helperText={errors && errors.password && errors.password.message}
                        className='loginInputField'
                        autoComplete="password"
                      />
                      <div className='loginfull'>                      
                        <div className='loginremb'>
                        <FormControlLabel
                          sx={{
                            "& .CheckboxField-icon":{
                              backgroundColor:"#FFFFFF !important",
                              border:'1px solid #D0D5DD'
                            },
                            "& .CheckboxField-checkedIcon" : {
                              backgroundColor:"#3699FF !important"
                            }
                          }}
                            control={
                              <CheckboxField
                                inputProps={{ "aria-label": "Remember me next time" }}
                              />
                            }
                            label={
                              <p className="text-gray-600 text-semibold -ml-3">
                                Remember for 30 days
                              </p>
                            }
                          />
                          {/* <CustomCheckbox /> */}
                        </div>
                        <div className='loginforg'>
                          <a href="/forgot-password" className="">
                            Forgot Password
                          </a>
                        </div>
                      </div>
                      

                      <div className="loginfull">
                        <PrimaryBlueButton
                          variant="contained"
                          type="submit"
                          form="login-form"
                          disableElevation
                          className="loginbtn"
                        >
                          Log in
                        </PrimaryBlueButton>
                      </div>
                      
                    </form>
                </div>
            </Grid>
        </Grid>

    </div>
    
  );
};

export default LoginPage;
