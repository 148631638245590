import React from "react";

import { CommonDialog, RichTextEditor} from "components";
import Button from '@mui/material/Button';
import { sellerListingStatus } from "utils";


//Submit and Cancel buttons
const DialogActions = (props) => {
    const { handleClose , handleConfrm} = props;
    return (      
        
          <div style={{width:'100%',textAlign:'center'}}>
            <Button onClick={handleClose} className="lightBtn medemBtnSize">Cancel</Button>
            <Button onClick={handleConfrm} className="mainBtn medemBtnSize" >Confrm</Button>
          </div>
        
      
    );
  };

const ChangeStatusModal = (props) => {
    const { open, handleClose,handleConfrm, handleStatusTextChangeValue, statusData } = props;
    
    
    

    return (
        <div className="ChaStatusModal">
        <CommonDialog
            open={open}
            onClose={handleClose}
            title={sellerListingStatus(statusData)}
            actions={DialogActions({handleClose, handleConfrm})}
            maxWidth="sm"
            
        >
          {statusData==='PRODUCT_REJECTED' && (
            <>            
            <div>
              <RichTextEditor
                label="Enter the reasons for the rejection."                
                onChange={(data) => {
                  handleStatusTextChangeValue("reasons_rejection", data);
                  //trigger("description");
                }}               
                placeholder="Type..."
              />
            </div>
            <div style={{ width: "100%", marginTop: "40px"}}>
              <RichTextEditor
                label="Enter the how To fix these issues."                
                onChange={(data) => {
                  handleStatusTextChangeValue("how_to_fix", data);
                  //trigger("description");
                }}               
                placeholder="Type..."
              />
            </div>
            
            
            </>
            
          )}
                    
        </CommonDialog>
        </div>
    );
};

export default ChangeStatusModal;