
//yimport TopNav from "components/TopNav";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useState, useEffect } from 'react';
import { getToken } from '../../firebaseInit';
import { SaveFCMTokenMutation } from 'components/TableBellIcon/Queries';

const Layout = ({ children }) => {
    const [showSidebar, setShowSidebar] = useState(true);

    const toggleSidebar = (value) => {
        setShowSidebar(value);
    };

    // console.log("ShowSideBar", showSidebar)
    const { mutate: saveFCMTokenMutation } = SaveFCMTokenMutation();

    useEffect(() => {
        let data;
        async function FCM_TOKEN() {
            data = await getToken();
            if (data) {
                console.log('FCM Token is - ', data);
                // Save the token on BE using devices API
                saveFCMTokenMutation(data);
            }
            return data;
        }
  
        FCM_TOKEN();  
  
    }, []);

    return (
        // <div className="grid g-container s">
        <div className={showSidebar ? "grid g-container s":"grid g-container inactive s"}>
            <div className="sidebar">
                <Sidebar toggleSidebar={toggleSidebar}/>
            </div>
            <div className="p-4 bg-primaryBackground grow content overflow-x-auto main-container">
                {children}
            </div>
        </div>
    );
}

export default Layout;