import React from "react";

import { CommonDialog} from "components";
import Button from '@mui/material/Button';
import Styles from "./MsgSuccessModel.module.scss";


const MsgSuccessModel = (props) => {
    const { open,headingText,lineText,handleOk } = props;
    
    
    

    return (
        <>
        <CommonDialog
            open={open}
            onClose={handleOk}
            title=""
            actions={[]}
            maxWidth="sm"
            
        >
          
            <>
            <div className={Styles.succDiv}>        
              <div className={Styles.imgCant}><img src="/assets/successfully-icon.png" className={Styles.succImg} alt="successfully" /></div>
              <div className={Styles.headingText}>{headingText}</div>
              <div className={Styles.lineText}>{lineText}</div>
              <div className={Styles.botambtn}><Button onClick={handleOk} className="mainBtn fullBtn" >Back</Button></div>
            </div>
            </>
            
         
                    
        </CommonDialog>
        </>
    );
};

export default MsgSuccessModel;