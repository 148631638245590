import React, { useEffect, useMemo, useState } from "react";
import ReactTableCustom from "components/ReactTableCustom";
import FullPageLoadingModel  from "components/FullPageLoadingModel";
import { FetchSellerListQuery,FetchCategoriesQuery,FetchStatusListsQuery } from "./Queries";
import { format } from "date-fns";
import { currencyFormat,mobileFormat, classForSellerListingStatus } from "utils";


const SellerListing = () => {   
    const [fetchSellerListPayload, setFetchSellerListPayload] = useState(null);   
    const { data: sellerList, isLoading } = FetchSellerListQuery(fetchSellerListPayload);
    const data = useMemo(() => sellerList?.results || [], [sellerList]);
    const [filterData, setFilterData] = useState({'category':[],'status':[]});
    
    const { data: sellerCategories, refetch} = FetchCategoriesQuery(fetchSellerListPayload);
    const { data: statusLists } = FetchStatusListsQuery(sellerCategories);   
    
    useEffect(()=>{
    refetch();
    },[])

    useEffect(() => {        
        const cat_array = []; 
        if (sellerCategories?.results) {           
            sellerCategories?.results?.forEach((cr)=>{                
                cat_array.push(cr.name);
            })  
            const extraFilters={
                category: cat_array,
                status: filterData['status'],               
            }
            setFilterData(extraFilters);
        }
      }, [sellerCategories]);

    useEffect(() => {
        const status_array = []; 
        if (statusLists?.data) { 
            statusLists?.data?.forEach((sr)=>{
                const newStr = sr.replace(/\_/g," ");
                const newStr1 =newStr.toLowerCase();
                const arr = newStr1.split(" ");
                for (var i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);                
                }
                const str2 = arr.join(" ");
                status_array.push(str2);
            })  
            const extraFilters={
                category: filterData['category'],
                status: status_array,               
            }
            setFilterData(extraFilters); 
        }
      }, [statusLists]);    

    const columns = useMemo(
        () => [
          {
            Header: "Stock",
            accessor: "unique_id",
            width: "100px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p onClick={() => {
                        handleViewItem(original?.id);
                    }} className="fw600 greycolor underline cursorpointer">{original?.unique_id}</p>
                )
              },
          },
          {
            Header: "Name",
            accessor: "user__name",          
            width: "120px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.user?.name}</p>
                )
              },
          },
          {
            Header: "Contact",
            accessor: "user__phone",            
            width: "200px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>                        
                        <p>{original && original?.user?.phone ? mobileFormat(original.user.phone) : null}</p>                   
                        <p className="font10">{original?.user?.email}</p>
                    </div>
                    
                )
              },
          },
          {
            Header: "Date",
            accessor: "created_at",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>
                        <p>{original && original.created_at ? format(new Date(original.created_at), "MMM dd, yyyy") : null}</p>
                        <p className="font10 fw400 grey60color">{original && original.created_at ? format(new Date(original.created_at), "hh:mm b") : null}</p>
                    </div>
                )
                  
                },
           
          },
          {
            Header: "Category",
            accessor: "category__name",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.category?.name}</p>
                )
            },
            
          },    
          
          {
            Header: "Price",
            accessor: "price",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return (
                    <p className="fw700 maincolor">{original && original.selling_price ? currencyFormat(original.selling_price) : null}</p>
                )
                
              },           
          },
          {
            Header: "Status",
            accessor: "status",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    classForSellerListingStatus(original?.status)
                )
            },
          },
    
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">
                  <button
                    onClick={() => {
                      handleViewItem(original?.id);
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/actions-icon.png" alt="Actions" />
                    </span>
                  </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
      );

    const [search, setSearch] = useState(null);    
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownPageSize, setDropdownPageSize] = useState(30);
    const [sorts, setSorts] = useState([ {
                                id: "id",
                                desc: false,
                                },
                            ]);
    const setDropdownPageSizeHandler = (count) => {
        setCurrentPage(1);
        setDropdownPageSize(count);
    };
    const pageChangeHandler = (pageNo) => {
        setCurrentPage(pageNo);
    };
    const setSearchHandler = (searchKeyword) => {
        setSearch(searchKeyword);
    };
    const setDateHandler = (value) => {        
        if (typeof value == 'object' && value != null) { 
            const from_date = format(new Date(value[0]), "yyyy-MM-dd");
            const to_date = format(new Date(value[1]), "yyyy-MM-dd");
            setFromDate(from_date);
            setToDate(to_date);
          }
    };
    const setSortsHandler = (col) => {
        setSorts(col);
    };
    /**********  Filter  ***********/
    const [filterValues,setFilterValues]= useState([]);
    const [filterFilter,setFilterFilter]= useState([]);
    const [filterBtnValue,setFilterBtnValue]= useState([]);

    const setFilterBtnHandler = (value) => {        
        if (typeof value == 'object' && value != null) {             
            setFilterBtnValue(value);            
        }
    };
    const handleViewItem = (value) => {        
        window.open(`seller-listing/details?id=${value}`, "_blank")
    };
    
    useEffect(() => {
        setFetchSellerListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
    }, [dropdownPageSize]);

    useEffect(() => {
        setFetchSellerListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
    }, [currentPage]);

    useEffect(() => {
        if (search || search === "") {
        setCurrentPage(1);
        setFetchSellerListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
        }
    }, [search]);

    useEffect(() => {
        if (sorts) {
        setFetchSellerListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
        }
    }, [sorts]);

    useEffect(() => {
        setFetchSellerListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue     
        });
      }, [fromDate,toDate]);    

    useEffect(() => {
        setFetchSellerListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
      }, [filterBtnValue]);

    
    return (        
        <div>            
            <div className='flex'>
                <div className="mx-auto my-8 bg-white px-8 py-4 rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                    <FullPageLoadingModel
                        open={Boolean(isLoading)}
                    />
                    <ReactTableCustom
                        dropdownPageSize={dropdownPageSize}
                        setDropdownPageSize={setDropdownPageSizeHandler}
                        currentPage={currentPage}
                        pageChangeHandler={pageChangeHandler}
                        data={data}
                        columns={columns}
                        filterData={filterData}
                        paginationProps={sellerList?.pagination_option}
                        setSearchHandler={setSearchHandler}
                        setDateHandler={setDateHandler}
                        setFiltersHandler={setSortsHandler}
                        appliedFilters={filterValues}
                        setAppliedFilters={setFilterValues}
                        filterFilter={filterFilter}
                        setFilterFilter={setFilterFilter}
                        setFilterBtnHandler={setFilterBtnHandler}
                        placeholder={"Sellar Listing"}
                        initialStateSortBy={sorts}
                        showPaginationBottom={true}
                        columnSearchable='false'
                        disableCheckboxSelection= 'true'
                        addNewSelection='true'
                    />
                </div>
            </div>
        </div>

    );
}

export default SellerListing;