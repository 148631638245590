const sellerListingStatus = (status, withcolor) => {
//   console.log('status', status);
  switch (status) {
    case 'REVIEW_PENDING':
      return <p className={status}>Review Pending</p>;
    case 'INSPECTION_FEE_PENDING':
      return <p className={status}>Inspection Fee Pending</p>;
    case 'INSPECTION_PENDING':
      return <p className={status}>Inspection Pending</p>;
    case 'PRODUCT_REJECTED':
      return <p className={status}>Product Rejected</p>;
    case 'PRODUCT_LISTED':
      return <p className={status}>Product Listed</p>;
    case 'PRODUCT_SOLD':
      return <p className={status}>Product Sold</p>;
    case 'PRODUCT_ON_HOLD':
      return <p className={status}>Product On Hold</p>;
    case 'REMOVAL_REQUESTED':
      return <p className={status}>Removal Requested</p>;
    case 'PRODUCT_REMOVED':
      return <p className={status}>Product Removed</p>;

    case 'UNDER_PROCESS':
      return <p className="REVIEW_PENDING">Application under process</p>;
    case 'COMPLETE':
      return <p className="PRODUCT_SOLD">Financing completed</p>;
    case 'DENIED':
        return <p className="PRODUCT_REJECTED">Application denied</p>;
    default:
      return status;
  }
};

export default sellerListingStatus;
