import React, { useMemo, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import UserProductsTable  from "./UserProductsTable";
import UserRequestedQuoteTable  from "./UserRequestedQuoteTable";
import UserSMREquipmentTable  from "./UserSMREquipmentTable";
import UserNonSMREquipmentTable  from "./UserNonSMREquipmentTable";
import UserTransactionsTable  from "./UserTransactionsTable";

import "./Users.css";
import { FetchUserDetails } from "./Queries";
import { mobileFormat } from "utils";
    
const UsersDetails = () => { 
    
    const [searchParams]= useSearchParams()
    const userId=searchParams.get('id');
    let ptype=searchParams.get('type');
    if(userId==='' || userId===null ){        
        window.location = "/users";
    }
    if(ptype!=='Transactions') {        
        ptype="Products";
    }

    const [pageTypeValue, setPageTypeValue] = useState(ptype);    
    const handlePageType = (event) => {       
        setPageTypeValue(event);
    };

    const {data: userDetail}= FetchUserDetails(userId);
    const userData = useMemo(() => userDetail?.data || [], [userDetail]);
    
    return (  
            <div className='flex'>
                <div className="mx-auto my-8 bg-white rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                    <div className="gray-container font14">
                        <div className="cont25">
                            <div className="imgDiv"><img src="/assets/user-icon.png" className="userImg" alt="Logo" /></div>
                            <div className="NameDiv">
                                <div className="UserLable">Name</div>
                                <div className="UserText">{userData?.name} &nbsp;</div>
                            </div>                            
                        </div>
                        <div className="cont25">
                            <div className="UserLable">Phone</div>
                            <div className="UserText">{mobileFormat(userData?.phone)}</div>                            
                        </div>
                        <div className="cont25">
                            <div className="UserLable">Email</div>
                            <div className="UserText">{userData?.email}&nbsp;</div>
                        </div>                        
                    </div>
                    
                    {(() => {
                        if (pageTypeValue==='AAAAA SMREquipment' || pageTypeValue==='AAAA NonSMREquipment') {
                        return (
                            <div className="white-container">
                            <div className="cont50 mb20 docheading">Documents</div>
                            <div className="cont50 mb20 textRight">
                                {pageTypeValue==='NonSMREquipment' && (
                                    <Button variant="contained"  className="mainBtn"> Send Documents to Slate Financial</Button>   
                                )}                                
                            </div>
                            <div className="clearFix"></div>
                            <div className="cont25 plr25 mb20">
                                <div className="docDiv">
                                    <div className="docName">Credit application</div>
                                    <div className="docCont">
                                        <div className="docIcon">
                                            <img src="/assets/file-ring-icon.png" className="docFileImg" alt="file" />
                                        </div>
                                        <div className="docText">
                                            <div className="docTextN">Credit application.pdf.pdf</div>
                                            <div className="docTextSi">200 KB - 100% uploaded</div>
                                            <div className="docTextSt"><div className="Approved">&#x2022; Approved</div></div>
                                        </div>
                                        <div className="docActionIcon">
                                            <img src="/assets/dot-icon.png" className="docDotImg" alt="Action" title="Action" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cont25 plr25 mb20">
                                <div className="docDiv">
                                    <div className="docName">Credit application</div>
                                    <div className="docCont">
                                        <div className="docIcon">
                                            <img src="/assets/file-ring-icon.png" className="docFileImg" alt="file" />
                                        </div>
                                        <div className="docText">
                                            <div className="docTextN">Credit application.pdf.pdf</div>
                                            <div className="docTextSi">200 KB - 100% uploaded</div>
                                            <div className="docTextSt"><div className="Reject">&#x2022; Reject</div></div>
                                        </div>
                                        <div className="docActionIcon">
                                            <img src="/assets/dot-icon.png" className="docDotImg" alt="Action" title="Action" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cont25 plr25 mb20">
                                <div className="docDiv">
                                    <div className="docName">Credit application</div>
                                    <div className="docCont">
                                        <div className="docIcon">
                                            <img src="/assets/file-ring-icon.png" className="docFileImg" alt="file" />
                                        </div>
                                        <div className="docText">
                                            <div className="docTextN">Credit application.pdf.pdf</div>
                                            <div className="docTextSi">200 KB - 100% uploaded</div>
                                            <div className="docTextSt"><div className="Approved">&#x2022; Approved</div></div>
                                        </div>
                                        <div className="docActionIcon">
                                            <img src="/assets/dot-icon.png" className="docDotImg" alt="Action" title="Action" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cont25 plr25 mb20">
                                <div className="docDiv">
                                    <div className="docName">Credit application</div>
                                    <div className="docCont">
                                        <div className="docIcon">
                                            <img src="/assets/file-ring-icon.png" className="docFileImg" alt="file" />
                                        </div>
                                        <div className="docText">
                                            <div className="docTextN">Credit application.pdf.pdf</div>
                                            <div className="docTextSi">200 KB - 100% uploaded</div>
                                            <div className="docTextSt"><div className="Approved">&#x2022; Approved</div></div>
                                        </div>
                                        <div className="docActionIcon">
                                            <img src="/assets/dot-icon.png" className="docDotImg" alt="Action" title="Action" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cont25 plr25 mb20">
                                <div className="docDiv">
                                    <div className="docName">Credit application</div>
                                    <div className="docCont">
                                        <div className="docIcon">
                                            <img src="/assets/file-ring-icon.png" className="docFileImg" alt="file" />
                                        </div>
                                        <div className="docText">
                                            <div className="docTextN">Credit application.pdf.pdf</div>
                                            <div className="docTextSi">200 KB - 100% uploaded</div>
                                            <div className="docTextSt"><div className="Approved">&#x2022; Approved</div></div>
                                        </div>
                                        <div className="docActionIcon">
                                            <img src="/assets/dot-icon.png" className="docDotImg" alt="Action" title="Action" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        )
                        }
                    })()}

                    <div className="white-container">
                        <div style={{float: "left"}}>
                            <div onClick={() => handlePageType('Products')} className={pageTypeValue === 'Products' ? 'uTab uTabF uTabActive' : 'uTab uTabF'}>
                                <div className="uTabText">Products</div>
                                <div className="uTabCount ">{userData?.listing_count}</div>
                            </div>
                            <div onClick={() => handlePageType('RequestedQuote')} className={pageTypeValue === 'RequestedQuote' ? 'uTab uTabActive' : 'uTab'}>
                                <div className="uTabText">Requested Quote</div>
                                <div className="uTabCount">{userData?.quote_count}</div>
                            </div>
                            <div onClick={() => handlePageType('SMREquipment')} className={pageTypeValue === 'SMREquipment' ? 'uTab uTabActive' : 'uTab'}>
                                <div className="uTabText">SMR Equipment</div>
                                <div className="uTabCount">{userData?.smr_finance}</div>
                            </div>
                            <div onClick={() => handlePageType('NonSMREquipment')} className={pageTypeValue === 'NonSMREquipment' ? 'uTab uTabActive' : 'uTab'}>
                                <div className="uTabText">Non SMR Equipment</div>
                                <div className="uTabCount ">{userData?.non_smr_finance}</div>
                            </div>
                            <div onClick={() => handlePageType('Transactions')} className={pageTypeValue === 'Transactions' ? 'uTab uTabL uTabActive' : 'uTab uTabL'}>
                                <div className="uTabText">Transactions</div>                            
                            </div>
                        </div>

                        <div className="full" style={{display: "contents"}}>
                            {pageTypeValue === 'Products' && (
                                <UserProductsTable
                                    userId={userId}
                                />
                            )}
                            {pageTypeValue === 'RequestedQuote' && (
                                <UserRequestedQuoteTable
                                    userId={userId}
                                />
                            )}
                            {pageTypeValue === 'SMREquipment' && (
                                <UserSMREquipmentTable
                                    userId={userId}
                                />
                            )}
                            {pageTypeValue === 'NonSMREquipment' && (
                                <UserNonSMREquipmentTable
                                    userId={userId}
                                />
                            )}
                            {pageTypeValue === 'Transactions' && (
                                <UserTransactionsTable
                                    userId={userId}
                                />
                            )}

                        </div>

                    </div>
                    
                </div>
            </div>       
    );
}

export default UsersDetails;