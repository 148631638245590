import React from "react";
import { format } from "date-fns";
import { currencyFormat, classForTransactionType, classForTransactionListingStatus } from "utils";
import { CommonDialog} from "components";
import Button from '@mui/material/Button';
import "./TransactionModel.css";


const TransactionModel = (props) => {
    const { open,data,handleOk,handleCancel } = props;
    
    const transactionType = (type) => {
        switch (type) {    
          case "Inspection":
              return ("Inspection Fee")
          case "Quotes":
              return ("Deposit Amount")          
        default:
          return type;
      }      
    };
    const transactionStatus = (status) => {
        switch (status) {    
            case "succeeded":
                return ("Successful")
            case "failed":
                return ("Failed")
            case "pending":
                return ("Pending")
            case "canceled":
            return ("Canceled")
            case "refund":
                return ("Refund")            
          default:
            return status;
        }
    };

    
    const handleDownload = () => {
        handleOk(data?.charge?.receipt_url);
    };
    
    

    return (
        <>
        <CommonDialog
            open={open}
            onClose={handleCancel}            
            actions={false}
            maxWidth="sm"
            modelId="TransactionModel"  
            
        >
          
            <>
            <div className="thadder">        
              <div className="hdrIcon"><img src="/assets/transaction-successful-icon.png" className="thdtImg" alt="successfully" /></div>
              <div className="hdrtitle">{data?.transaction_for ? transactionType(data?.transaction_for) : null } {transactionStatus(data?.status)}</div>
              <div className="hdrdate">Date: {data && data.charge?.created ? format(new Date(data.charge.created), "MMM dd, yyyy") : null}</div>
              
            </div>
            <div className="tmiddel">
                <div className="textline">
                    <div className="textlinel">Ammount</div>
                    <div className="textlinev"><p className="fw700 greenColour">{data && data.amount ? currencyFormat(data.amount) : null}</p></div>
                </div>
                <div className="textline">
                    <div className="textlinel">SMR.No</div>
                    <div className="textlinev">{data?.listing_unique_id}</div>
                </div>
                <div className="textline">
                    <div className="textlinel">Transection ID</div>
                    <div className="textlinev">{data?.transaction_number}</div>
                </div>
                <div className="textline">
                    <div className="textlinel">A/C</div>
                    <div className="textlinev">**** **** **** {data?.charge?.payment_method_details?.card?.last4}</div>
                </div>
                <div className="textline">
                    <div className="textlinel">Date</div>
                    <div className="textlinev">
                        <span>{data && data.charge?.created ? format(new Date(data.charge.created), "MMM dd, yyyy") : null}</span>
                        <br/><span className="font10 fw400 grey60color">{data && data.charge?.created ? format(new Date(data.charge?.created), "hh:mm b") : null}</span>
                    </div>
                </div>                
               
            </div>
            <div className="tfooter">
                <div className="tfooter50"><Button onClick={handleCancel} className="lightBtn bigbtn" >Cancel</Button></div>
                <div className="tfooter50"><Button onClick={handleDownload} className="mainBtn bigbtn" ><img src="/assets/download-Icon.png" className="dImg" alt="icon" /> &nbsp;&nbsp; Download Invoice</Button></div>
            </div>
            </>
            
         
                    
        </CommonDialog>
        </>
    );
};

export default TransactionModel;