import React, { useEffect,useState } from "react";
import Styles from "./ListingPhoto.module.scss";
import { Checkbox } from '@mui/material';
import { Upload } from "antd";
import { useToast } from "services";

const { Dragger } = Upload;

const Imagediv = ({data, imgType, handleViewImgFile, handleChangeCheckBox, handleDeleteFile,handleCustomRequest}) => {  
  
  const responseToast = useToast(); 
  const [allImgFiles, setAllImgFiles] = useState([]);

  useEffect(() => {
    const newArray = data?.filter((file) => file.description === imgType);
    setAllImgFiles(newArray);
  }, [data]);
  
  const handleCustRequest = ({ file }) => { 
    console.log(file.type); 
    if(file.type.match('image/*')){    
      if(allImgFiles.length===0)  {    
        handleCustomRequest(imgType, true, file);
      }  else {     
        handleCustomRequest(imgType, false, file);
      }
    } else {
      responseToast.toastError("Only image files are accepted");
    }
  };
  
  
  return (
    <div className="">      
      {Object.entries(allImgFiles)?.map(([key, val]) => {
              const imgUrl = val?.attachment;             
              return (
                <>                  
                    <div className="cont20 plr25 mb20">
                      <div  className={Styles.imgCant}>                     
                        <div onClick={handleViewImgFile(val.id,imgType,imgUrl)} className={Styles.imgListinDiv}>
                          <img src={imgUrl} className={Styles.listImg} alt="img" />
                        </div>
                        <div className={Styles.imgAction}>
                          {val.is_primary ? (
                            <div className={Styles.imgCheckbox}>
                              <Checkbox
                                checked={val.is_primary === true}
                                onChange={handleChangeCheckBox(val.id)}
                                className={Styles.imgCheckboxpad}
                              />
                              {'Primary'}
                            </div>
                          ):(
                            <div className={Styles.imgCheckboxnotBG}>
                              <Checkbox
                                checked={val.is_primary === true}
                                onChange={handleChangeCheckBox(val.id)}
                                className={Styles.imgCheckboxpad}
                              />
                              {' '}
                            </div>
                          )}
                          

                          {!val.is_primary && (
                            <div className={Styles.imgDelete}>                       
                              <img  onClick={handleDeleteFile(val.id)} src="/assets/delete-icon.png" className={Styles.deleteImg} alt="delete" title="delete" />
                            </div> 
                          )}
                        </div>                      
                      </div>
                    </div>      
                 
                </>
              );
        })}

      { allImgFiles.length <12  && (
          <div className="cont20 plr25 mb20">
          <div className={Styles.imgCantBo}> 
            <Dragger
              fileList={[]}
              customRequest={handleCustRequest}
              maxCount={1}
              multiple={false}
              disabled={false}
              accept={'image/jpeg,image/png'}
            >
              <div className={Styles.fileIcon}>
                <img src="/assets/file-ring-icon.png" className={Styles.fileImg} alt="file" />
                <br/><span className={Styles.fileIconText}>Click to upload Photo</span>
                </div>
            </Dragger>
          </div>
          </div>
       
      )}
    </div>
  );
};

export default Imagediv;
