import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Checkbox } from '@mui/material';
import { nanoid } from 'nanoid';

export const FilterChildComponent = ({
  data,
  appliedFilters,
  setAppliedFilters,
  filterFilter, 
  setFilterFilter,
  parent,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpansion = (panel) => (event, isExpanded) => {
    event.stopPropagation();
    //console.log(event);
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeCheckBox = (data) => (event) => {
    event.stopPropagation();
    //console.log(event.target.checked);
    
    if (event.target.checked) {
      
      setAppliedFilters([
        ...appliedFilters,
         { key: nanoid(), label: data, filter: parent },
      ]);
      if(parent=='category'||parent=='brand'){

        setFilterFilter([...filterFilter,{ key: nanoid(), label: data, filter: parent }])
      }


    } else {
      //console.log(data);
      setAppliedFilters(
        appliedFilters.filter((el) => {
          if (el.label !== data) {
            return el;
          }
        })
      );
      if(parent=='category'||parent=='brand'){
        setFilterFilter(filterFilter.filter((el) => {
          if (el.label !== data) {
            return el;
          }
        }))
      }
    }
  };

  return (
    <Box
    justifyContent='left'
      
       
    >
      <Typography
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2bh-content"
        id="panel2bh-header"
        sx={{ alignItems: 'middle', justifyContent: 'left' }}
      >
        <Typography
          id="text"
          sx={{ textAlign: 'left',fontSize:'12px', color: 'text.secondary',}}
        >
          <Checkbox
            checked={appliedFilters.some((item) => item.label === data)}
            onChange={handleChangeCheckBox(data)}
          />{' '}
          {data}
        </Typography>
      </Typography>
    </Box>
  );
};
