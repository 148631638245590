import React, { useEffect, useMemo, useState } from 'react';
import ReactTableCustom from 'components/ReactTableCustom';
import { format } from 'date-fns';
import { currencyFormat, classForInquiryListingStatus } from 'utils';
import './Users.css';
import { FetchUserQuoteList } from './Queries';

const UserRequestedQuoteTable = ({ userId }) => {
  const [fetchUserQuoteListPayload, setFetchUserQuoteListPayload] =
    useState(null);
  const { data: userQuoteList } = FetchUserQuoteList(fetchUserQuoteListPayload);
  const listData = useMemo(() => userQuoteList?.results || [], [userQuoteList]);

  const columns = useMemo(
    () => [
      {
        Header: 'Stock',
        accessor: 'listing__unique_id',
        width: '100px',
        Cell: ({ row: { original } }) => {
          return (
            <p className="fw600 greycolor underline">
              {original?.listing?.unique_id}
            </p>
          );
        },
      },

      {
        Header: 'Category',
        accessor: 'listing__category__name',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return <p>{original?.listing?.category__name}</p>;
        },
      },
      {
        Header: 'Make',
        accessor: 'listing__vehicle__make',
        width: '120px',
        Cell: ({ row: { original } }) => {
          return <p>{original?.listing?.vehicle__make}</p>;
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <p>
                {original && original.created_at
                  ? format(new Date(original.created_at), 'dd MMM yyyy')
                  : null}
              </p>
              <p className="font10 fw400 grey60color">
                {original && original.created_at
                  ? format(new Date(original.created_at), 'hh:mm b')
                  : null}
              </p>
            </div>
          );
        },
      },
      {
        Header: 'Price',
        accessor: 'listing__price',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return (
            <p className="fw700 maincolor">
              {original && original.listing?.selling_price
                ? currencyFormat(original.listing.selling_price)
                : null}
            </p>
          );
        },
      },
      {
        Header: 'Quote Amount',
        accessor: 'quote_price',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return (
            <p className="fw700 maincolor">
              {original && original.quote_price
                ? currencyFormat(original.quote_price)
                : null}
            </p>
          );
        },
      },

      {
        Header: 'Status',
        accessor: 'status',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return classForInquiryListingStatus(original?.status);
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row: { original } }) => {
          return (
            <div className="text-center">
              <button
                onClick={() => {
                  handleViewItem(original?.listing?.id);
                }}
                className="tableActionsBtn"
              >
                <span>
                  <img src="/assets/actions-icon.png" alt="Actions" />
                </span>
              </button>
            </div>
          );
        },
        width: '90px',
        disableSortBy: true,
        disableExport: true,
      },
    ],
    []
  );

  const [search, setSearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownPageSize, setDropdownPageSize] = useState(30);
  const [sorts, setSorts] = useState([
    {
      id: 'id',
      desc: false,
    },
  ]);
  const pageChangeHandler = (pageNo) => {
    setCurrentPage(pageNo);
  };
  const setSearchHandler = (searchKeyword) => {
    setSearch(searchKeyword);
  };
  const setSortsHandler = (col) => {
    setSorts(col);
  };
  const handleViewItem = (value) => {
    window.open(`/inquiry/details?listingId=${value}`, '_blank');
  };

  useEffect(() => {
    setFetchUserQuoteListPayload({
      user_id: userId,
      page: currentPage,
      per_page: dropdownPageSize,
      search,
      sorts,
    });
  }, [currentPage]);

  useEffect(() => {
    if (search || search === '') {
      setCurrentPage(1);
      setFetchUserQuoteListPayload({
        user_id: userId,
        page: 1,
        per_page: dropdownPageSize,
        search,
        sorts,
      });
    }
  }, [search]);

  useEffect(() => {
    if (sorts) {
      setFetchUserQuoteListPayload({
        user_id: userId,
        page: currentPage,
        per_page: dropdownPageSize,
        search,
        sorts,
      });
    }
  }, [sorts]);

  return (
    <div className="mtm15">
      <ReactTableCustom
        dropdownPageSize={dropdownPageSize}
        setDropdownPageSize={''}
        currentPage={currentPage}
        pageChangeHandler={pageChangeHandler}
        data={listData}
        columns={columns}
        paginationProps={userQuoteList?.pagination_option}
        setSearchHandler={setSearchHandler}
        setFiltersHandler={setSortsHandler}
        placeholder={'User Inq Listing'}
        initialStateSortBy={sorts}
        showPaginationBottom={true}
        hideTopbar={true}
        showShowSearchTop={true}
      />
    </div>
  );
};

export default UserRequestedQuoteTable;
