import TopSidebar from "components/Sidebar/TopSidebar";
import React, { useState } from 'react';



const TopLayout = ({ children }) => {
    
    // console.log("ShowSideBar", showSidebar)

    return (        
        <div className="t-container">
            <div className="topbar">
                <TopSidebar />
            </div>
            <div className="grow content overflow-x-auto main-t-container">
                {children}
            </div>
        </div>
    );
}

export default TopLayout;