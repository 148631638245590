import React, { useMemo, useEffect,useState } from "react";
import "./DPDocument.css";

import { Modal, Upload } from "antd";
import {  UpdateDocQuery,  UploadDocQuery, DeleteDocQuery, UpdateFinanceDocQuery } from "./Queries";
import Imagediv  from "./imagediv";


const { Dragger } = Upload;

const DPDocument = ({data,docUserId,financeId,hideAction=false}) => {

  const [allFiles, setAllFiles] = useState([]);

  const { mutateAsync: UploadDoc } = UploadDocQuery();
  const { mutateAsync: DeleteDoc } = DeleteDocQuery();
  const { mutateAsync: UpdateDoc } = UpdateDocQuery();
  const { mutateAsync: UpdateFinanceDoc } = UpdateFinanceDocQuery();

  useEffect(() => {
    setAllFiles(data);
  }, [data]);


  const UpdateFinance = (docArray) => {    
    const doc_array = []; 
    if (docArray) {            
      docArray?.forEach((msl)=>{
        doc_array.push(msl.id);
      })
      const payload = {
        financeId: financeId,
        documents: doc_array,
      };
      UpdateFinanceDoc(payload);
    }   
  };

  const handleChangeStatus = (status, id) => {    
    if(status==='REMOVED'){
      const newArray = allFiles.filter((item) => item.id !== id*1);     
      setAllFiles(newArray);
      const payload = {
        id: id,
        docUserId: docUserId,        
      };
      DeleteDoc(payload);
      UpdateFinance(newArray);
    } else {
      const payload = {
        id: id,
        docUserId: docUserId,
        financeId: financeId,
        status: status,
      };
      UpdateDoc(payload);
      const newArray = allFiles;
      for (const element of newArray){
        if(element.id===id*1){          
          element.status=status;
        }
      }
      setAllFiles(newArray);
    }   
  };

  const handleCustomRequest = (docType, docSide, file) => {        
    const payload = {
      document_type: docType,
      document_side: docSide,
      document: file,
      docUserId:docUserId,      
    };
    UploadDoc(payload).then((data) => {
      if(data?.status_code===201){        
        if(data?.data){
          const allfileArray = allFiles.concat([data.data]);         
          setAllFiles(allfileArray);
          UpdateFinance(allfileArray);
        } 
      } else {
        alert("Some error");
      }      
    });
  };  
  
  return (
      <div className="full">
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Credit application</div>
          <Imagediv
            data={allFiles}
            docType='CREDIT_APPLICATION'
            docSide=''
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>
        
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Commercial Drivers Licence (Front)</div>
          <Imagediv
            data={allFiles}
            docType='DRIVERS_LICENSE'
            docSide='FRONT'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>

        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Commercial Drivers Licence (Back)</div>
          <Imagediv
            data={allFiles}
            docType='DRIVERS_LICENSE'
            docSide='BACK'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>

        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Social Security (Front)</div>
          <Imagediv
            data={allFiles}
            docType='SOCIAL_SECURITY'
            docSide='FRONT'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>

        <div className="clearFix"></div>

        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Social Security (Back)</div>
          <Imagediv
            data={allFiles}
            docType='SOCIAL_SECURITY'
            docSide='BACK'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>        

        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Work Permit (Front)</div>
          <Imagediv
            data={allFiles}
            docType='WORK_PERMIT'
            docSide='FRONT'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>

        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Work Permit (Back)</div>
          <Imagediv
            data={allFiles}
            docType='WORK_PERMIT'
            docSide='BACK'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Voided Check</div>
          <Imagediv
            data={allFiles}
            docType='VOIDED_CHECK'
            docSide=''
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>

        <div className="clearFix"></div>

        <div className="cont25 plr25 mb20">
          <div className="imgTabName">EIN Letter</div>
          <Imagediv
            data={allFiles}
            docType='EIN_LETTER'
            docSide=''
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>
        
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Articles of Incorporation</div>
          <Imagediv
            data={allFiles}
            docType='ARTICLES_OF_INCORPORATION'
            docSide=''
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>
        
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Tax Return</div>
          <Imagediv
            data={allFiles}
            docType='TAX_RETURN'
            docSide=''
            docNumber='0'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Tax Return</div>
          <Imagediv
            data={allFiles}
            docType='TAX_RETURN'
            docSide=''
            docNumber='1'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>
        <div className="clearFix"></div>
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Bank Statement</div>
          <Imagediv
            data={allFiles}
            docType='BANK_STATEMENT'
            docSide=''
            docNumber='0'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Bank Statement</div>
          <Imagediv
            data={allFiles}
            docType='BANK_STATEMENT'
            docSide=''
            docNumber='1'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>
        <div className="cont25 plr25 mb20">
          <div className="imgTabName">Bank Statement</div>
          <Imagediv
            data={allFiles}
            docType='BANK_STATEMENT'
            docSide=''
            docNumber='2'
            handleChangeStatus={handleChangeStatus}
            handleCustomRequest={handleCustomRequest}
            hideAction={hideAction}
          />
        </div>    
        <div className="clearFix"></div>
      </div>
  );
};

export default DPDocument;
