import { Box, Button, Typography } from '@mui/material';
import EditableColumn from 'components/EditableColumn';
import ReactTableCustom from 'components/ReactTableCustom';
import TableSearch from 'components/TableSearch';
import { InquiryStatusList } from 'consts';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { currencyFormat, mobileFormat } from 'utils';
import { useDebounce } from 'utils/CustomHooks/useDebounce';
import DepositTable from './DepositTable';
import { useGetQuotesByListingId, useUpdateQuoteStatusQuery } from './Queries';

const tabBtns = {
  INQUIRY: 'Sales Inquiry',
  DEPOSIT: 'Deposit',
};

const TableSection = ({ sellingPrice }) => {
  const [searchParams] = useSearchParams();
  const listingId = searchParams.get('listingId');

  const [tabActiveBtn, setTabActiveBtn] = useState(tabBtns.INQUIRY);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [quotePage, setQuotePage] = useState(1);
  const [sort, setSort] = useState([]);
  const [data, setData] = useState([]);

  const [skipPageReset, setSkipPageReset] = useState(false);

  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);
  const { mutate: updateQuoteStatus } = useUpdateQuoteStatusQuery();

  const handleAskToDeposit = useCallback((original) => {
    updateQuoteStatus(
      {
        id: original?.id,
        data: {
          quote_price: original?.quote_price?.replace(/,/g,''),
          status: InquiryStatusList.DEPOSIT,
        },
      },
      {
        onSuccess: () => {
          setTabActiveBtn(tabBtns.DEPOSIT);
        },
      }
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'user__name',
        Cell: ({ row: { original } }) => {
          return <p className="capitalize">{original?.user?.name}</p>;
        },
      },
      {
        Header: 'Contact',
        accessor: 'user__phone',
        Cell: ({ row: { original } }) => {
          return (
            <>
              <p className="text-[#545454]">
                {original && original?.user?.phone
                  ? mobileFormat(original.user.phone)
                  : null}
              </p>
              <p className="m-0">{original?.user?.email}</p>
            </>
          );
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at', // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <p className="text-[#545454]">
                {original && original.created_at
                  ? format(new Date(original.created_at), 'MMM dd, yyyy')
                  : null}
              </p>
              <p className="font10 fw400 grey60color">
                {original && original.created_at
                  ? format(new Date(original.created_at), 'hh:mm b')
                  : null}
              </p>
            </div>
          );
        },
      },

      {
        Header: 'Mode',
        accessor: 'mode', // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return (
            <p
              className={`${
                original?.mode === 'Request Quote'
                  ? 'text-primaryGreen'
                  : 'text-primaryGold'
              } `}
            >
              {original?.mode}
            </p>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Documents',
        accessor: 'documents', // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return original?.finance_id > 0 ? (
            <Typography variant="caption" color="primary">
              Document Uploaded
            </Typography>
          ) : (
            <Typography color="primaryGreen" variant="caption">
              N/A
            </Typography>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Quote Amount',
        accessor: 'quote_price',
        Cell: ({ value: initialValue, row, column, updateMyData }) => {
          return (
            <div className="flex items-center border-[1px] rounded-lg border-solid border-[#F5F5F5]">
              <Typography
                className="p-2 px-3"
                variant="caption"
                sx={{ borderRight: '1px solid #F5F5F5' }}
              >
                $
              </Typography>
              <EditableColumn
                value={initialValue}
                row={row}
                column={column}
                updateMyData={updateMyData}
                className="outline-none border-0 py-2 pl-3"
                type="text"
                min={0}
                maxlength={10}               
              />
            </div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: '',
        Cell: ({ row: { original }, ...rest }) => {
          return (
            <Button
              variant="contained"
              className="w-full"
              onClick={() => handleAskToDeposit(original)}
              disabled={Number(original?.quote_price) <= 0}
            >
              Ask to Deposit
            </Button>
          );
        },
        disableSortBy: true,
      },
    ],
    []
  );

  const { data: quotesList } = useGetQuotesByListingId({
    id: listingId,
    params: {
      page: quotePage,
      search: debouncedSearchKeyword,
      ordering: sort,
      per_page: 15,
    },
  });

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  // console.log(' data : ', data);
  // console.log('query list : ', quotesList?.results);

  useEffect(() => {
    if (quotesList?.results) {
      setData(
        quotesList?.results?.filter(
          (el) => el.status === InquiryStatusList.QUOTE_SENT
        )
      );
    }
  }, [quotesList?.results]);

  useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  return (
    <>
      <section>
        <div className="flex justify-between">
          <div className="flex  w-fit items-center">
            <p
              className={`${
                tabActiveBtn === tabBtns.INQUIRY
                  ? 'bg-primaryBlue text-white'
                  : 'bg-none'
              } py-2.5 px-14 text-xs border-[1px] border-solid border-gray-200 rounded-l-lg hover:cursor-pointer transition-colors`}
              onClick={() => setTabActiveBtn(tabBtns.INQUIRY)}
            >
              {tabBtns.INQUIRY}
            </p>
            <p
              className={`${
                tabActiveBtn === tabBtns.DEPOSIT
                  ? 'bg-primaryBlue text-white'
                  : 'bg-none'
              } py-2.5 px-14 text-xs !mt-0 border-[1px] border-solid border-gray-200 rounded-r-lg hover:cursor-pointer transition-colors`}
              onClick={() => setTabActiveBtn(tabBtns.DEPOSIT)}
            >
              {tabBtns.DEPOSIT}
            </p>
          </div>

          {tabActiveBtn === tabBtns.INQUIRY && (
            <TableSearch onChange={setSearchKeyword} />
          )}
        </div>
        <hr />

        <Box className="bg-[#F5F5F5] p-4 w-fit text-lg rounded-lg">
          <span>Product Price : </span>
          <span className="font-bold text-primaryBlue">{currencyFormat(sellingPrice)}</span>
        </Box>
        {tabActiveBtn === tabBtns.INQUIRY ? (
          <Box sx={{ mt: 3 }}>
            <ReactTableCustom
              data={data}
              columns={columns}
              showPaginationBottom={true}
              // columnSearchable="true"
              disableCheckboxSelection="true"
              hideTopbar="false"
              paginationProps={quotesList?.pagination_option}
              currentPage={quotePage}
              pageChangeHandler={setQuotePage}
              setFiltersHandler={setSort}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
            />
          </Box>
        ) : (
          <DepositTable />
        )}
      </section>
    </>
  );
};

export default TableSection;
