import React, { useEffect, useMemo, useState } from "react";
import ReactTableCustom from "components/ReactTableCustom";
import FullPageLoadingModel  from "components/FullPageLoadingModel";
import { FetchTableListQuery,FetchCategoriesQuery} from "./Queries";
import { format } from "date-fns";
import { currencyFormat,mobileFormat,classForFinanceListingStatus } from "utils";

const FinanceListing = () => {   
    const [fetchFinanceListPayload, setFetchFinanceListPayload] = useState(null);   
    const { data: financeList, isLoading } = FetchTableListQuery(fetchFinanceListPayload);
    const data = useMemo(() => financeList?.results || [], [financeList]);
    const [filterData, setFilterData] = useState({'category':[],'status':['Application Under Process','Financing Completed','Application Denied']});
    
    const { data: fincCategories, refetch} = FetchCategoriesQuery();   
    
    
    useEffect(()=>{
    refetch();
    },[])

    useEffect(() => {        
        const cat_array = []; 
        if (fincCategories?.results) {           
            fincCategories?.results?.forEach((cr)=>{                
                cat_array.push(cr.name);
            })  
            const extraFilters={
                category: cat_array,
                status: filterData['status'],               
            }
            setFilterData(extraFilters);
        }
      }, [fincCategories]);
    

    const columns = useMemo(
        () => [
          {
            Header: "Client ID",
            accessor: "user__id",
            width: "90px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p className="fw600 greycolor">{original?.user?.id}</p>
                )
              },
          },
          {
            Header: "Stock",
            accessor: "listing__unique_id",
            width: "90px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p onClick={() => {
                        handleViewItem(original?.listing?.id,original?.id);
                    }} className="fw600 greycolor underline cursorpointer">{original?.listing?.unique_id}</p>
                )
              },
          },    
          {
            Header: "Category",
            accessor: "category__name",            
            width: "100px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.category_name}</p>
                )
              },
          },          
          {
            Header: "Name",
            accessor: "user__name",          
            width: "120px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.user?.name}</p>
                )
              },
          },
          {
            Header: "Contact",
            accessor: "user__phone",            
            width: "200px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>                        
                        <p>{original && original?.user?.phone ? mobileFormat(original.user.phone) : null}</p>                   
                        <p className="font10">{original?.user?.email}</p>
                    </div>
                    
                )
              },
          },
          {
            Header: "Date",
            accessor: "created_at",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>
                        <p>{original && original.created_at ? format(new Date(original.created_at), "MMM dd, yyyy") : null}</p>
                        <p className="font10 fw400 grey60color">{original && original.created_at ? format(new Date(original.created_at), "hh:mm b") : null}</p>
                    </div>
                )
                  
                },
           
          },
          {
            Header: "Price",
            accessor: "listing__price",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return (
                    <p className="fw700 maincolor">{original && original.listing?.selling_price ? currencyFormat(original.listing.selling_price) : null}</p>
                )
                
              },           
          },       
          {
            Header: "Status",
            accessor: "status",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    classForFinanceListingStatus(original?.status)
                )
            },
          },    
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">
                  <button
                    onClick={() => {
                        handleViewItem(original?.listing?.id,original?.id);
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/actions-icon.png" alt="Actions" />
                    </span>
                  </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
    );

    
    const columns2 = useMemo(
        () => [             
          {
            Header: "Category",
            accessor: "category__name",            
            width: "100px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.category_name}</p>
                )
              },
          },          
          {
            Header: "Name",
            accessor: "user__name",          
            width: "120px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.user?.name}</p>
                )
              },
          },
          {
            Header: "Contact",
            accessor: "user__phone",            
            width: "200px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>                        
                        <p>{original && original?.user?.phone ? mobileFormat(original.user.phone) : null}</p>                   
                        <p className="font10">{original?.user?.email}</p>
                    </div>
                    
                )
              },
          },
          {
            Header: "Date",
            accessor: "created_at",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>
                        <p>{original && original.created_at ? format(new Date(original.created_at), "MMM dd, yyyy") : null}</p>
                        <p className="font10 fw400 grey60color">{original && original.created_at ? format(new Date(original.created_at), "hh:mm b") : null}</p>
                    </div>
                )
                  
                },
           
          },               
          {
            Header: "Status",
            accessor: "status",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    classForFinanceListingStatus(original?.status)
                )
            },
          },    
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">
                  <button
                    onClick={() => {
                        handleViewNonSMRItem(original?.id);
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/actions-icon.png" alt="Actions" />
                    </span>
                  </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
    );

    const [search, setSearch] = useState(null);    
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [equipmentType, setEquipmentType] = useState('SMR');

    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownPageSize, setDropdownPageSize] = useState(30);
    const [sorts, setSorts] = useState([ {
                                id: "id",
                                desc: false,
                                },
                            ]);
    const setDropdownPageSizeHandler = (count) => {
        setCurrentPage(1);
        setDropdownPageSize(count);
    };
    const pageChangeHandler = (pageNo) => {
        setCurrentPage(pageNo);
    };
    const setSearchHandler = (searchKeyword) => {
        setSearch(searchKeyword);
    };
    const setDateHandler = (value) => {        
        if (typeof value == 'object' && value != null) { 
            const from_date = format(new Date(value[0]), "yyyy-MM-dd");
            const to_date = format(new Date(value[1]), "yyyy-MM-dd");
            setFromDate(from_date);
            setToDate(to_date);
          }
    };
    const setSortsHandler = (col) => {
        setSorts(col);
    };
    /**********  Filter ***********/
    const [filterValues,setFilterValues]= useState([]);
    const [filterFilter,setFilterFilter]= useState([]);
    const [filterBtnValue,setFilterBtnValue]= useState([]);

    const setFilterBtnHandler = (value) => {        
        if (typeof value == 'object' && value != null) {             
            setFilterBtnValue(value);            
        }
    };

    
    const setSMRTypeHandler = (value) => {        
        if (value != null) {  
            console.log(value);      
            setEquipmentType(value);           
          }
    };

    useEffect(() => {
        setFetchFinanceListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue,
            equipment_type:equipmentType
        });
    }, [dropdownPageSize]);

    useEffect(() => {
        setFetchFinanceListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue,
            equipment_type:equipmentType
        });
    }, [currentPage]);

    useEffect(() => {
        if (search || search === "") {
        setCurrentPage(1);
        setFetchFinanceListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue,
            equipment_type:equipmentType
        });
        }
    }, [search]);

    useEffect(() => {
        if (sorts) {
        setFetchFinanceListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue,
            equipment_type: equipmentType
        });
        }
    }, [sorts]);

    useEffect(() => {
        setFetchFinanceListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue,
            equipment_type: equipmentType  
        });
      }, [fromDate,toDate]);    

    useEffect(() => {
        setFetchFinanceListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue,
            equipment_type: equipmentType
        });
      }, [filterBtnValue]);

      useEffect(() => {
        setFetchFinanceListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue,
            equipment_type: equipmentType
        });
      }, [equipmentType]);
      
    const handleViewItem = (value,fvalue) => {        
        window.open(`inquiry/details?listingId=${value}&financeId=${fvalue}`, "_blank")
    };
    
    const handleViewNonSMRItem = (value) => {        
        window.open(`finance-request/details?id=${value}`, "_blank")
    };

    return (        
        <div>
            <div className='flex'>
            <div className="mx-auto my-8 bg-white px-8 py-4 rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                <FullPageLoadingModel
                    open={Boolean(isLoading)}
                />
                <ReactTableCustom
                    dropdownPageSize={dropdownPageSize}
                    setDropdownPageSize={setDropdownPageSizeHandler}
                    currentPage={currentPage}
                    pageChangeHandler={pageChangeHandler}
                    data={data}
                    columns={equipmentType==='SMR' ? columns : columns2 }
                    filterData={filterData}
                    paginationProps={financeList?.pagination_option}
                    setSearchHandler={setSearchHandler}
                    setDateHandler={setDateHandler}
                    setSMRTypeHandler={setSMRTypeHandler}
                    setFiltersHandler={setSortsHandler}
                    appliedFilters={filterValues}
                    setAppliedFilters={setFilterValues}
                    filterFilter={filterFilter}
                    setFilterFilter={setFilterFilter}
                    setFilterBtnHandler={setFilterBtnHandler}
                    placeholder={"Sellar Listing"}
                    initialStateSortBy={sorts}
                    showPaginationBottom={true}
                    columnSearchable='false'
                    disableCheckboxSelection= 'true'
                    smrTypeSelection= 'true'
                    />
                </div>
            </div>
        </div>

    );
}

export default FinanceListing;