import {  useQuery } from "@tanstack/react-query";
import { useAPI, useToast } from "services";



const FetchRHList = (body) => {
  const method = "GET";  
  let url = `repair-history/?listing_id=${body?.listingid}`;   
  return useAPI({ method, url });
};



export const FetchRepairHistoryListQuery = (payload) => { 
  const responseToast = useToast();
  return useQuery(
    ["GET_RH_LIST", payload],
    () => FetchRHList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};