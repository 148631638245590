const classForSellerListingStatus = (status) => {
  switch (status) {
    case "REVIEW_PENDING":
        return ( 
            <p className={status}>
                &#x2022; Review Pending
            </p>
        )
    case "INSPECTION_FEE_PENDING":
        return ( 
            <p className={status}>
                &#x2022; Inspection Fee Pending
            </p>
        )
    case "INSPECTION_PENDING":
        return ( 
            <p className={status}>
                &#x2022; Inspection Pending
            </p>
        )
    case "PRODUCT_REJECTED":
        return ( 
            <p className={status}>
                &#x2022; Product Rejected
            </p>
        )
    case "PRODUCT_LISTED":
        return ( 
            <p className={status}>
                &#x2022; Live
            </p>
        )
    case "PRODUCT_SOLD":
        return ( 
            <p className="INSPECTION_PENDING">
                &#x2022; Sold
            </p>
        )
    case "PRODUCT_ON_HOLD":
        return ( 
            <p className="REVIEW_PENDING">
                &#x2022; Hold
            </p>
        )
    case "REMOVAL_REQUESTED":
        return ( 
            <p className={status}>
                &#x2022; Removal Requested
            </p>
        )
    case "PRODUCT_REMOVED":
        return ( 
            <p className={status}>
                &#x2022; Product Removed
            </p>
        )
  default:
    return '';
}

};

export default classForSellerListingStatus;
