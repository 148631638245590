import React, { useEffect, useState } from "react";
import {
        Chart as ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
      } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FetchInProgressGraphQuery} from "./Queries";
import  "./dashboard.css";

const InProgressGraph = () => {    
    const { data: ipgraph } = FetchInProgressGraphQuery('GET');  
    
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'top',
          align: 'end',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',            
            useBorderRadius: false,
            font: {
              size: 12
            }
          }
        },
        title: {
          display: false,
          text: '',
        },
        tooltip: {
          usePointStyle: true,
          callbacks: {
              labelPointStyle: function(context) {
                  return {
                      pointStyle: 'circle',
                      rotation: 0
                  };
              }
          }
        }
      },
      interaction: {
        mode: 'index'
      }
    };
    const labels = ipgraph?.data?.label;
    const data = {
      labels,
      datasets: [
        {
          label: 'Inventory',
          data: ipgraph?.data?.inventory,
          borderColor: '#05945B',
          backgroundColor: '#05945B',
        },
        {
          label: 'Deals in Progress',
          data: ipgraph?.data?.deals_in_progress,
          borderColor: '#FFC200',
          backgroundColor: '#FFC200',
        },
        {
          label: 'Sold & Funded',
          data: ipgraph?.data?.sold_and_funded,
          borderColor: '#0F5E91',
          backgroundColor: '#0F5E91',
        },
      ],
    };

    const [graphData, setGraphData] = useState(data);

    useEffect(() => {        
      setGraphData(data);      
    }, [ipgraph]);

    return (        
        <div>
            <div className="wgtabletitle" style={{padding:"0"}}>
                <div className="wgtabletitleTxt">In Progress<br/><span className="wgSubTitle">Keep track of In Progress </span></div>               
            </div>
            <div className="fulltable" >
              <Line options={options} data={graphData} />              
            </div>
        </div>            
    );
}
export default InProgressGraph;