const usNumberFormat = (num) => {  
  if (num) {
    if (isNaN(num)) {
      return num;
    } else {
      return Number.parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') 
    }   
  } else {
    return '';
  }
};
export default usNumberFormat;