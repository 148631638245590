import { useEffect, useState } from 'react';
import { useDebounce } from 'utils/CustomHooks/useDebounce';
import { usNumberFormat } from 'utils';
const EditableColumn = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
  maxlength,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(usNumberFormat(e.target.value.trimStart().replace(/\D/, '')));    
  };

  const debounceValue = useDebounce(value, 300);

  useEffect(() => {
    updateMyData(index, id, value);
  }, [debounceValue]);

  // We'll only update the external data when the input is blurred
  // const onBlur = () => {
  //   updateMyData(index, id, value);
  // };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      // defaultValue={initialValue}
      value={value ?? ''}
      onChange={onChange}
      maxlength={maxlength}
      style = {{width: '100%'}}
      {...rest}
    />
  );
};

export default EditableColumn;
