import React, { useEffect, useMemo, useState } from "react";
import ReactTableCustom from "components/ReactTableCustom";
import FullPageLoadingModel  from "components/FullPageLoadingModel";
import { FetchTableListQuery,FetchCategoriesQuery,FetchMakeListsQuery } from "./Queries";
import { format } from "date-fns";
import { currencyFormat, classForInventoryListingStatus } from "utils";

const InventoryListing = () => {   
    const [fetchInventoryListPayload, setFetchInventoryListPayload] = useState(null);   
    const { data: inventoryList, isLoading } = FetchTableListQuery(fetchInventoryListPayload);
    const data = useMemo(() => inventoryList?.results || [], [inventoryList]);
    const [filterData, setFilterData] = useState({'category':[],'make':[]});
    
    const { data: invCategories, refetch} = FetchCategoriesQuery(fetchInventoryListPayload);
    const { data: makeLists } = FetchMakeListsQuery(invCategories);
      
    const handleViewItem = (value) => {        
        window.open(`seller-listing/details?id=${value}`, "_blank")
    };
    
    useEffect(()=>{
    refetch();
    },[])
   
    useEffect(() => {
        const cat_array = []; 
        if (invCategories?.results) {           
            invCategories?.results?.forEach((cr)=>{                
                cat_array.push(cr.name);
            })  
            const extraFilters={
                category: cat_array,               
                make: filterData['make'],
            }
            setFilterData(extraFilters);
        }
      }, [invCategories]);
   
    
    useEffect(() => {        
        const make_array = []; 
        if (makeLists?.results) {           
            makeLists?.results?.forEach((br)=>{                
                make_array.push(br.name);
            })  
            const extraFilters={
                category: filterData['category'],               
                make: make_array,
            }
            setFilterData(extraFilters);           
        }
      }, [makeLists]);

    const columns = useMemo(
        () => [
          {
            Header: "Stock",
            accessor: "unique_id",
            width: "90px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p onClick={() => {
                        handleViewItem(original?.id);
                    }} className="fw600 greycolor underline cursorpointer">{original?.unique_id}</p>
                )
              },
          },
          {
            Header: "Year",
            accessor: "vehicle__year",          
            width: "80px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.vehicle?.year}</p>
                )
              },
          },
          {
            Header: "category",
            accessor: "category__name",
            width: "120px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.category?.name}</p>
                )
              },
          },
          {
            Header: "Make",
            accessor: "vehicle__make",            
            width: "90px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.vehicle?.make}</p>
                )
              },
          },          
          {
            Header: "VIN Full",
            accessor: "vehicle__vin_number",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.vehicle?.vin_number}</p>
                )
            },
            
          },
          
          {
            Header: "Location",
            accessor: "city",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.city} {original?.zip_code}</p>
                )
            },            
          },
          {
            Header: "Color",
            accessor: "vehicle__color",
            width: "90px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.vehicle?.color}</p>
                )
            },            
          },      
          {
            Header: "Price",
            accessor: "price",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return (
                    <p className="fw700 maincolor">{original && original.selling_price ? currencyFormat(original.selling_price) : null}</p>
                )
                
              },           
          },
          {
            Header: "Status",
            accessor: "status",            
            width: "100px",
            Cell: ({ row: { original } }) => {
                return ( 
                    classForInventoryListingStatus(original?.status)
                )
            },
          },
    
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">                  
                    <button
                        onClick={() => {
                            handleViewItem(original?.id);
                        }}
                        className="tableActionsBtn"
                    >
                        <span>
                        <img src="/assets/actions-icon.png" alt="Actions" />
                        </span>
                    </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
      );

    const [search, setSearch] = useState(null);    
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownPageSize, setDropdownPageSize] = useState(30);
    const [sorts, setSorts] = useState([ {
                                id: "unique_id",
                                desc: false,
                                },
                            ]);
    const setDropdownPageSizeHandler = (count) => {
        setCurrentPage(1);
        setDropdownPageSize(count);
    };
    const pageChangeHandler = (pageNo) => {
        setCurrentPage(pageNo);
    };
    const setSearchHandler = (searchKeyword) => {
        setSearch(searchKeyword);
    };
    const setDateHandler = (value) => {        
        if (typeof value == 'object' && value != null) { 
            const from_date = format(new Date(value[0]), "yyyy-MM-dd");
            const to_date = format(new Date(value[1]), "yyyy-MM-dd");
            //setFromDate(from_date);
            //setToDate(to_date);
          }
    };
    const setSortsHandler = (col) => {
        setSorts(col);
    };
    /**********  Filter ***********/
    const [filterValues,setFilterValues]= useState([]);
    const [filterFilter,setFilterFilter]= useState([]);
    const [filterBtnValue,setFilterBtnValue]= useState([]);

    const setFilterBtnHandler = (value) => {        
        if (typeof value == 'object' && value != null) {             
            setFilterBtnValue(value);            
        }
    };

    const setYearFilterBtnHandler = (value) => {        
        if (typeof value == 'object' && value != null) {
            setFromDate(value['from_year']);
            setToDate(value['to_year']);
          }
    };

    useEffect(() => {
        setFetchInventoryListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
    }, [dropdownPageSize]);

    useEffect(() => {
        setFetchInventoryListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
    }, [currentPage]);

    useEffect(() => {
        if (search || search === "") {
        setCurrentPage(1);
        setFetchInventoryListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
        }
    }, [search]);

    useEffect(() => {
        if (sorts) {
        setFetchInventoryListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
        }
    }, [sorts]);

    useEffect(() => {
        setFetchInventoryListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue     
        });
      }, [fromDate,toDate]);    

    useEffect(() => {
        setFetchInventoryListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
      }, [filterBtnValue]); 
    return (        
        <div>
            <div className='flex'>
            <div className="mx-auto my-8 bg-white px-8 py-4 rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                <FullPageLoadingModel
                    open={Boolean(isLoading)}
                />
                <ReactTableCustom
                    dropdownPageSize={dropdownPageSize}
                    setDropdownPageSize={setDropdownPageSizeHandler}
                    currentPage={currentPage}
                    pageChangeHandler={pageChangeHandler}
                    data={data}
                    columns={columns}
                    filterData={filterData}
                    paginationProps={inventoryList?.pagination_option}
                    setSearchHandler={setSearchHandler}
                    setDateHandler={setDateHandler}
                    setFiltersHandler={setSortsHandler}
                    appliedFilters={filterValues}
                    setAppliedFilters={setFilterValues}
                    filterFilter={filterFilter}
                    setFilterFilter={setFilterFilter}
                    setFilterBtnHandler={setFilterBtnHandler}
                    placeholder={"Sellar Listing"}
                    initialStateSortBy={sorts}
                    showPaginationBottom={true}
                    columnSearchable='false'
                    disableCheckboxSelection= 'true'
                    yearRangeSelection= 'true'
                    setYearFilterBtnHandler={setYearFilterBtnHandler}
                    />
                </div>
            </div>
        </div>

    );
}

export default InventoryListing;