import React, { useMemo, useEffect,useState } from "react";
import Styles from "./ListingPhoto.module.scss";
import { Upload } from "antd";
import {  UpdateImageAPI,  UploadImageQuery } from "./Queries";
import Imagediv  from "./imagediv";
import ViewPhotoModel  from "./viewPhotoModel";
import FullPageLoadingModel  from "components/FullPageLoadingModel";
import { useToast } from "services";

const { Dragger } = Upload;

const ListingPhoto = ({listingid,listingAttachmentsData, handleChangeImgValue}) => {  
  
  const responseToast = useToast();
const [allFiles, setAllFiles] = useState([]);
const [videoData, setVideoData] = useState([]);
const [inspectionReportData, setInspectionReportData] = useState([]);


 const { mutateAsync: UploadImage, isLoading } = UploadImageQuery();
 const { mutateAsync: UpdateImage } = UpdateImageAPI();

  useEffect(() => {        
    setAllFiles(listingAttachmentsData);
  }, [listingAttachmentsData]);

  useEffect(() => { 
    let newArray = allFiles;
    const newVArray = newArray.filter((file) => file.description === 'VIDEO');
    setVideoData(newVArray);
    const newIRArray = newArray.filter((file) => file.description === 'INSPECTION_REPORT');
    setInspectionReportData(newIRArray);
  }, [allFiles]);




const handleDeleteFile = (id) => (event) => {
  
  let newArray = allFiles;
  newArray = newArray.filter((file) => file.id !== id);
  setAllFiles(newArray);
  handleChangeImgValue(newArray);
  const newVArray = newArray.filter((file) => file.description === 'VIDEO');
  setVideoData(newVArray);
  const newIRArray = newArray.filter((file) => file.description === 'INSPECTION_REPORT');
  setInspectionReportData(newIRArray);
};
  const handleChangeCheckBox = (data) => (event) => {
    const cbox = event.target.checked;
    if(cbox){
      const allFiles2 = allFiles.map((image) => {
        if (image.id === data) {       
          image.is_primary = cbox;
          UpdateImage({listingid,image});
        } else if (image.is_primary) {
          image.is_primary = false;
        }
        return image;
      });
      setAllFiles(allFiles2); 
    }
       
  };
  const handleCustomRequest = (imgType, isPrimary, file ) => {
        
    const payload = {
      source: 'Seller img',
      multiple: false,
      file: file,
      is_primary: isPrimary,
      description: imgType
    };
    UploadImage(payload).then((data) => {      
      if(data?.status_code===201){
        if(data?.data[0]){          
          const allfileArray = allFiles.concat([data.data[0]]);         
          setAllFiles(allfileArray);                  
          handleChangeImgValue(allfileArray);
        } 
      } else {
        alert("Some error");
      }      
    });
  };

  const handleVideoRequest = ({ file }) => {    
    if(file.type.match('video/*')){      
      const payload = {
        source: 'Seller img',
        multiple: false,
        file: file,
        is_primary: false,
        description:'VIDEO'
      };
      UploadImage(payload).then((data) => {      
        if(data?.status_code===201){
          if(data?.data[0]){
            allFiles.push(data.data[0]);
            setAllFiles(allFiles);          
            handleChangeImgValue(allFiles);
            const newArray = allFiles.filter((file) => file.description === 'VIDEO');
            setVideoData(newArray);
          } 
        } else {
          alert("Some error");
        }      
      });
    } else {
      responseToast.toastError("Only video files are accepted");
    }
  };
  
  const handleInspectionReportRequest = ({ file }) => {        
    if(file.type.match('application/pdf')){
      const payload = {
        source: 'Seller img',
        multiple: false,
        file: file,
        is_primary: false,
        description:'INSPECTION_REPORT'
      };
      UploadImage(payload).then((data) => {      
        if(data?.status_code===201){
          if(data?.data[0]){
            allFiles.push(data.data[0]);
            setAllFiles(allFiles);          
            handleChangeImgValue(allFiles);
            const newArray = allFiles.filter((file) => file.description === 'INSPECTION_REPORT');
            setInspectionReportData(newArray);
          } 
        } else {
          alert("Some error");
        }      
      });
    } else {
      responseToast.toastError("Only PDF files are accepted");
    }
  };

  const handleRepairHistoryRequest = ({ file }) => {        
    const payload = {
      source: 'Seller img',
      multiple: false,
      file: file,
      is_primary: false,
      description:'REPAIR_HISTORY'
    };
    UploadImage(payload).then((data) => {      
      if(data?.status_code===201){
        if(data?.data[0]){
          allFiles.push(data.data[0]);
          setAllFiles(allFiles);                  
          handleChangeImgValue(allFiles);
        } 
      } else {
        alert("Some error");
      }      
    });
  };
  const handleSpecificationSheetRequest = ({ file }) => {        
    if(file.type.match('application/pdf')){
      const payload = {
        source: 'Seller img',
        multiple: false,
        file: file,
        is_primary: false,
        description:'SPECIFICATION_SHEET'
      };
      UploadImage(payload).then((data) => {      
        if(data?.status_code===201){
          if(data?.data[0]){
            allFiles.push(data.data[0]);
            setAllFiles(allFiles);                  
            handleChangeImgValue(allFiles);
          } 
        } else {
          alert("Some error");
        }      
      });
    } else {
      responseToast.toastError("Only PDF files are accepted");
    }
  };
  
  const [openPhotoModel, setOpenPhotoModel] = useState(false);
  const [photoModelData, setPhotoModelData] = useState(null);
  
  const handleViewImgFile = (id,imgType,imgUrl) => (event) => {      
      if(imgType==='IMAGE'){
        setOpenPhotoModel(true);
        setPhotoModelData({"name":'Photo', "url":imgUrl});
      }      
  };  
  const handleCancel = () => {
    setOpenPhotoModel(false);
  }; 

  const handleOpenDocClick = (event) => {  
    const docurl = event.currentTarget.getAttribute('doc-url');
    window.open(docurl, '_blank');
  };
  
  return (
    <div className={Styles.listingPhoto}>
      <FullPageLoadingModel
                    open={Boolean(isLoading)}
                />        
          <Imagediv
            data={allFiles}
            imgType='IMAGE'
            handleViewImgFile={handleViewImgFile}
            handleChangeCheckBox={handleChangeCheckBox}
            handleDeleteFile={handleDeleteFile}
            handleCustomRequest={handleCustomRequest}
          />       
      
     
          <ViewPhotoModel
            open={Boolean(openPhotoModel)}
            data={photoModelData}
            handleCancel={handleCancel}
          />
      
      <div className="clearFix"></div>

      
      { Object.entries(videoData)?.map(([key, val]) => {             
              return (
                <>                  
                  <div className="cont25 plr25 mb20"> 
                    <div className={Styles.VideoCant}>
                      <div className={Styles.VideoIcon}>
                        <img src="/assets/video-icon.png" className={Styles.fileVideo} alt="file" />
                      </div>
                      <div onClick={handleOpenDocClick} doc-url={val?.attachment} className={Styles.VideoName}>
                        {val.name}
                        <br/><span className={Styles.lodingBarV}></span>
                      </div>
                      <div className={Styles.VideoDelete}>
                        <img  onClick={handleDeleteFile(val.id)} src="/assets/delete-icon.png" className={Styles.deleteVideoImg} alt="delete" title="delete" />
                        <br/><span className={Styles.fs12}>100%</span>
                      </div>
                      
                    </div>
                  </div>                                 
                </>
              );
        })}

      { videoData.length<2 && (
        <div className="cont20 plr25 mb20">        
          <div className={Styles.imgCantBo}> 
            <Dragger
              fileList={[]}
              customRequest={handleVideoRequest}
              maxCount={1}
              multiple={false}
              disabled={false}
              accept={'video/*'}
            >
              <div className={Styles.fileIcon}>
                <img src="/assets/file-ring-icon.png" className={Styles.fileImg} alt="file" />
                <br/><span className={Styles.fileIconText}>Click to upload Video</span>
                </div>
            </Dragger>
          </div>
        </div>
      )}

      
      <div className="clearFix"></div>
      <div className="container-heading">Inspection Report</div>
      { inspectionReportData.length===0 && (
        <div className="cont20 plr25 mb20">        
          <div className={Styles.imgCantBo}> 
            <Dragger
              fileList={[]}
              customRequest={handleInspectionReportRequest}
              maxCount={1}
              multiple={false}
              disabled={false}
              accept={'application/pdf'}
            >
              <div className={Styles.fileIcon}>
                <img src="/assets/file-ring-icon.png" className={Styles.fileImg} alt="file" />
                <br/><span className={Styles.fileIconText}>Click to upload Inspection Report</span>
                </div>
            </Dragger>
          </div>
        </div>
      )}
      { Object.entries(inspectionReportData)?.map(([key, val]) => {             
              return (
                <>                  
                  <div className="cont25 plr25 mb20"> 
                    <div className={Styles.PdfCant}>
                      <div className={Styles.VideoIcon}>
                      <img src="/assets/download-file-icon.png" className={Styles.fileVideo} alt="file" />
                      </div>
                      <div onClick={handleOpenDocClick} doc-url={val?.attachment} className={Styles.VideoName}>
                        {val.name}
                        <br/><span className={Styles.lodingBarF}></span>
                      </div>
                      <div className={Styles.VideoDelete}>
                        <img  onClick={handleDeleteFile(val.id)} src="/assets/delete-icon.png" className={Styles.deleteVideoImg} alt="delete" title="delete" />
                        <br/><span className={Styles.fs12}>100%</span>
                      </div>
                    </div>
                  </div>                                 
                </>
              );
          })}

      <div className="clearFix"></div>
      <div className="container-heading">Repair History</div>        
        {Object.entries(allFiles)?.map(([key, val]) => {              
              const description = val.description;
              return (
                <>
                  { description ==='REPAIR_HISTORY' && (
                    <div className="cont25 plr25 mb20"> 
                      <div className={Styles.PdfCant}>
                        <div className={Styles.VideoIcon}>
                          <img src="/assets/download-file-icon.png" className={Styles.fileVideo} alt="file" />
                        </div>
                        <div onClick={handleOpenDocClick} doc-url={val?.attachment} className={Styles.VideoName}>
                          {val.name}
                          <br/><span className={Styles.lodingBarF}></span>
                        </div>
                        <div className={Styles.VideoDelete}>
                          <img  onClick={handleDeleteFile(val.id)} src="/assets/delete-icon.png" className={Styles.deleteVideoImg} alt="delete" title="delete" />
                          <br/><span className={Styles.fs12}>100%</span>
                        </div>                        
                      </div>
                    </div> 
                  )}                  
                </>
              );
        })}
        <div className="cont20 plr25 mb20">        
          <div className={Styles.imgCantBo}> 
            <Dragger
              fileList={[]}
              customRequest={handleRepairHistoryRequest}
              maxCount={1}
              multiple={false}
              disabled={false}
              accept={'application/pdf'}
            >
              <div className={Styles.fileIcon}>
                <img src="/assets/file-ring-icon.png" className={Styles.fileImg} alt="file" />
                <br/><span className={Styles.fileIconText}>Click to upload Repair History</span>
                </div>
            </Dragger>
          </div>
        </div>

      <div className="clearFix"></div>
      <div className="container-heading">Specification Sheet</div>        
        {Object.entries(allFiles)?.map(([key, val]) => {              
              const description = val.description;
              return (
                <>
                  { description ==='SPECIFICATION_SHEET' && (
                    <div className="cont25 plr25 mb20"> 
                      <div className={Styles.PdfCant}>
                        <div className={Styles.VideoIcon}>
                        <img src="/assets/download-file-icon.png" className={Styles.fileVideo} alt="file" />
                        </div>
                        <div onClick={handleOpenDocClick} doc-url={val?.attachment} className={Styles.VideoName}>
                          {val.name}
                          <br/><span className={Styles.lodingBarF}></span>
                        </div>
                        <div className={Styles.VideoDelete}>
                          <img  onClick={handleDeleteFile(val.id)} src="/assets/delete-icon.png" className={Styles.deleteVideoImg} alt="delete" title="delete" />
                          <br/><span className={Styles.fs12}>100%</span>
                        </div>
                      </div>
                    </div> 
                  )}                  
                </>
              );
        })}
        <div className="cont20 plr25 mb20">        
          <div className={Styles.imgCantBo}> 
            <Dragger
              fileList={[]}
              customRequest={handleSpecificationSheetRequest}
              maxCount={1}
              multiple={false}
              disabled={false}
              accept={'application/pdf'}
            >
              <div className={Styles.fileIcon}>
                <img src="/assets/file-ring-icon.png" className={Styles.fileImg} alt="file" />
                <br/><span className={Styles.fileIconText}>Click to upload Specification Sheet</span>
                </div>
            </Dragger>
          </div>
        </div>

    </div>
  );
};

export default ListingPhoto;
