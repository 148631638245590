import React, { useMemo } from "react";
import Styles from "./RepairHistoryTable.module.scss";
import { FetchRepairHistoryListQuery} from "./Queries";

const RepairHistoryTable = (listingid) => { 
  
  const { data: RhList } = FetchRepairHistoryListQuery(listingid);
  const RhData = useMemo(() => RhList?.data || [], [RhList]);
  
  const downloadRepairHistory = (val) => {    
    fetch(val.attachment)
      .then((response) => response.blob())
      .then((blob) => {        
        // Create a new object URL for the blob
        const fileURL = URL.createObjectURL(blob);

        // Create an anchor element
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = val.document_key;
  
        // Append the link to the document body
        document.body.appendChild(link);
  
        // Simulate a click on the link to start the download
        link.click();
  
        // Clean up resources
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };
 
  
  return (
    <div className={Styles.tableRepairHistory}>
      { RhData.length===0 && (
        <div className="cont100 plr25 mb20">        
          <i>No Repair History Found</i>
        </div>
      )}
      { Object.entries(RhData)?.map(([key, val]) => {             
              return (
                <>                  
                  <div className={Styles.RHtab}>
                    <div className={Styles.rhIcon}>
                      <img src="/assets/blue-file-icon.png" className={Styles.rhfile} alt="file" />
                    </div>
                    <div className={Styles.rhName}>
                      {val.name}
                    </div>
                    <div className={Styles.rhDownload}>
                    <img onClick={()=>downloadRepairHistory(val)} src="/assets/rh-download-icon.png" className={Styles.rhdowImg} alt="download" title="download" />
                    </div>
                  </div>                                
                </>
              );
      })}   
    </div>
  );
};

export default RepairHistoryTable;
