import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LabelledTextField ,LabelledTextFieldWithAdornments} from "components";
import Button from '@mui/material/Button';
import { timeSince } from 'utils';
import { HandleProfileFormAPI, HandleProfileImgDeleteAPI, HandleProfileGetAPI,HandleUploadImageQuery, HandlePasswordFormAPI, FetchNotificationsQuery, DeleteNotificationsQuery,UpdateNotificationsQuery,FetchFinanceQuery} from "./Queries";
import "./UserProfile.css";
import { Typography, Upload } from "antd";
import { useToast } from "services";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import {LabelledTextFieldWithAdornments} from "components";


const { Dragger } = Upload;

const user_details = JSON.parse(localStorage.getItem('user_details'));

const userValidSchema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Please enter a valid email"),  
});

const passwordValidSchema = yup.object().shape({
  current_password: yup
    .string()
    .required("Current password is a required field"),
  new_password: yup
    .string()
    .required("New password is a required field")
    .min(8, "New Password must be atleast 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
  ),
  confirm_password: yup
    .string()
    .required("Confirm password is a required field")
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});

const UserProfile = () => { 
  const navigate = useNavigate();
  const responseToast = useToast(); 
  const [tabValue, setTabValue] = useState('Profile');

  const handleChangeTab = (event) => {
    setTabValue(event.currentTarget.id);
  };

  const {
    register,
    handleSubmit,
    watch: profileFormCredentials,
    setValue,
    formState: { errors },
    setFocus,
  } = useForm({ resolver: yupResolver(userValidSchema) });

  const handleChangeValue = (field, value) => {
    setValue(field, value, { shouldValidate: true });   
  };

  //useEffect
  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  useEffect(() => {
    setValue('name', user_details?.name);
    setValue('email', user_details?.email);
  }, [user_details]);

  const { mutateAsync: ValidationProfileCredentials } = HandleProfileFormAPI(profileFormCredentials);
  const submitProfileForm = (data) => {
    ValidationProfileCredentials(data).then((respData) => {      
      if(respData?.status_code===200){          
          setTimeout(() => {
            window.location.reload();
          }, 1000)
      }
    });
  };
  const { mutateAsync: DeleteProfileImgCredentials } = HandleProfileImgDeleteAPI();
  const { mutateAsync: getProfileData } = HandleProfileGetAPI();
  const { mutateAsync: UploadImage } = HandleUploadImageQuery();
  const handleDeleteImage = (event) => {
    //alert(event.currentTarget.id);  3505
    DeleteProfileImgCredentials(event.currentTarget.id).then((respData) => {
    //DeleteProfileImgCredentials(3506).then((respData) => {
      if(respData ===''){
        getProfileData().then((respData) => {
          if(respData?.status_code===200){          
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          }
        });
      }
    });
  };
  
  const handleImgRequest = ({file }) => {
    if(file.type.match('image.*')){
      const payload = {
        user_id: user_details?.profile_image?.id,    
        multiple: false,
        file: file,
        is_primary: false,
        description: 'PROFILE_IMAGE'
      };
      UploadImage(payload).then((data) => {      
        if(data?.status_code===201){
          getProfileData().then((respData) => {
            if(respData?.status_code===200){          
              setTimeout(() => {
                window.location.reload();
              }, 1000)
            }
          });
        } else {
          alert("Some error");
        }      
      });
    } else {         
      responseToast.toastError("Only image files are accepted")
    }   
    
  };

  /**********password form ****************/
  const [cPassValue, setCPassValue] = useState('');
  const [nPassValue, setNPassValue] = useState('');
  const [cnPassValue, setCnPassValue] = useState('');
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: passwordFormCredentials,
    setValue: setValue2,
    formState: { errors: errors2  },
   
  } = useForm({ resolver: yupResolver(passwordValidSchema) });  

  const handleChangePValue = (field, value) => {    
    setValue2(field, value, { shouldValidate: true }); 
    if(field==='current_password'){
      setCPassValue(value);
    }
    if(field==='new_password'){
      setNPassValue(value);
    } 
    if(field==='confirm_password'){
      setCnPassValue(value);
    } 
  };

  const { mutateAsync: ValidationPasswordCredentials } = HandlePasswordFormAPI(passwordFormCredentials);
  const submitPasswordForm = (data) => {
    ValidationPasswordCredentials(data).then((respData) => {      
      if(respData?.status_code===200){          
        setValue2('current_password', '');
        setValue2('new_password', '');
        setValue2('confirm_password', '');
        setCPassValue('');
        setNPassValue('');
        setCnPassValue('');
      }
    });
  };

  const handleCancel = () => {    
    if(cPassValue !=='' || nPassValue !=='' || cnPassValue !==''){      
      setValue2('current_password', '');
      setValue2('new_password', '');
      setValue2('confirm_password', '');
      setCPassValue('');
      setNPassValue('');
      setCnPassValue('');
    } else {      
      setTabValue('Profile');
    }    
  }

  /*********Notifications***********/
  const { data: notifList, refetch } = FetchNotificationsQuery();
  const notifData = useMemo(() => notifList?.results || [], [notifList]);
  const [notifId, setNotifId] = useState(null);
  const { data: notifIsDelete } = DeleteNotificationsQuery(notifId); 

  
  const [financeId, setFinanceId] = useState(null);
  const { data: financeData } = FetchFinanceQuery(financeId);
  const { mutateAsync: ValidationNotifUpdate } = UpdateNotificationsQuery();
  
  const updateNotiStatus = (data) => {
    const nData = {'id':data.id, 'read_receipt': true}
    ValidationNotifUpdate(nData).then((respData) => {      
      if(respData?.status_code===200){          
        refetch();
      }
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (notifIsDelete === '') {
      refetch();
    }
  }, [notifIsDelete]);
  

  useEffect(() => {
    if(financeData?.data?.listing?.id){
      window.open(`/inquiry/details?listingId=${financeData?.data?.listing?.id}&financeId=${financeId}`, "_blank")
    }
  }, [financeData]);

  const handleNotifAcationClick = (event) => {
    setNotifId(event.currentTarget.id);
  };

  const handleNotifViewClick = (val) => {
    console.log(val);
    updateNotiStatus(val);
    if(val?.target==='listing'){
      if(val?.body==='A new quote has been recieved on one of the rigs.')
      {
        window.open(`/inquiry/details?listingId=${val?.target_id}`, "_blank");
      } else if(val?.body==='A deposit has been made on a rig.')
      {
        window.open(`/deals-inprogress/details?id=${val?.target_id}`, "_blank");
      }
      else {
        window.open(`/seller-listing/details?id=${val?.target_id}`, "_blank");
      }
     
    }
    if(val?.target==='finance'){
      setFinanceId(val?.target_id);     
    }    
  };
   
    return (        
      <div className='flex'>
        <div className="mx-auto my-8 bg-white rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
          <div className="white-container font14">
            <div className="mb20 pageMenu">
                <div className={tabValue==='Profile' ? 'pageMenuName tabactive': 'pageMenuName' } onClick={handleChangeTab} id="Profile">Profile</div>
                <div className={tabValue==='Password' ? 'pageMenuName tabactive': 'pageMenuName' } onClick={handleChangeTab} id="Password">Password</div>
                <div className={tabValue==='Notifications' ? 'pageMenuName tabactive': 'pageMenuName' } onClick={handleChangeTab} id="Notifications">Notifications <span className="grayLabel" style={{fontSize:'11px',padding:'3px 10px'}}>{notifData?.length}</span></div>
            </div>
              
              
              {tabValue==='Profile' && (
                <>
                  <div className="profileDIV">
                    <div className="pimgCant">
                      <div className="pimgDIv">
                        {user_details?.profile_image?.attachment ? (
                          <img src={user_details?.profile_image?.attachment} className="puserImg" alt="User" />
                        ):(
                          <img src="/assets/user-icon.png" className="puserImg" alt="User" />
                        )}                        
                      </div>
                      <div className="cimgDIv">
                      <Dragger
                        fileList={[]}
                        customRequest={handleImgRequest}
                        maxCount={1}
                        multiple={false}
                        disabled={false}
                        accept={'image/jpeg,image/png'}
                      >
                        <img src="/assets/camera-icon.png" className="cameraImg" alt="camera" />
                      </Dragger>
                          
                      </div>                      
                    </div>                    
                      
                    <div className="pNameDiv">
                        <div className="pUserName">{user_details?.name}</div>
                        <div className="pUserEmail">{user_details?.email}</div>
                        {user_details?.profile_image?.id && (
                          <div id = {user_details?.profile_image?.id} onClick={handleDeleteImage} className="pUserBtn">Delete Photo</div>
                        )}
                    </div>                            
                  </div>
                  <div className="cont25 plr25 mb20">
                    <b>Personal info</b><br/>
                    Update your photo and personal details.
                  </div>
                  <div className="cont75 plr25 mb20">
                    <div className="white-container font14">
                    <form
                          onSubmit={handleSubmit(submitProfileForm)}
                          id="profile-form"
                          className=''
                        >
                            <div className="cont50 plr25 mb20">
                                <LabelledTextField
                                    label="Name"
                                    type="text"
                                    placeholder="Enter name"
                                    onChange={(e) =>
                                        handleChangeValue("name", e?.target?.value)
                                      }
                                    inputRef={register("name").ref}                                
                                    error={Boolean(errors.name)}
                                    helperText={errors && errors.name && errors.name.message}
                                />
                            </div>
                            <div className="cont50 plr25 mb20">                            
                                <LabelledTextField
                                    label="Email"
                                    type="text"
                                    placeholder="Enter email"
                                    onChange={(e) =>
                                        handleChangeValue("email", e?.target?.value)
                                      }
                                    inputRef={register("email").ref}                                
                                    error={Boolean(errors.email)}
                                    disabled={true}
                                    helperText={errors && errors.email && errors.email.message}
                                />
                            </div>
                            <div className="clearFix"></div>
                            <div className="cont50 plr25 mb20"></div>
                            <div className="cont50 plr25 mb20 textRight">
                              <Button type="submit" className="mainBtn">Save changes</Button>
                            </div>
                        </form>
                    </div>
                  </div>
                </>
              )}

              {tabValue==='Password' && (
                <>
                  
                  <div className="cont100 plr25 mb5">
                    <Typography style={{fontSize:'18px',fontWeight:'500',color:'#333333',paddingBottom:'5px',fontStyle:'Medium'}}>Password</Typography>
                    <Typography style={{fontSize:'14px',fontWeight:'400',color:'#757575'}}>Please enter your current password to change your password.</Typography>
                  </div>
                  <div className="cont50" style={{width:'100%'}}>
                 
                    <div className="field-container " >
                    
                      <form
                          onSubmit={handleSubmit2(submitPasswordForm)}
                          id="profile-password-form"
                          className=''
                        >
                          <div className="divider"></div>
                            <div className="cont10 plr25 mb20" style={{display:'Grid',gridTemplateColumns:'20% 40% 20%'}}>
                            <div style={{marginTop:'45px'}}>
                              <Typography className="heading-typography">Current password</Typography>
                              </div>
                             <div style={{marginTop:'30px'}}>
                                <LabelledTextFieldWithAdornments
                                    // label="Current password"
                                    type="password"
                                    width='50%'
                                    placeholder="Enter current password"
                                    onChange={(e) =>
                                      handleChangePValue("current_password", e?.target?.value)
                                      }
                                    inputRef={register2("current_password").ref}                                
                                    error={Boolean(errors2.current_password)}
                                    helperText={errors2 && errors2.current_password && errors2.current_password.message}
                                />
                                </div>
                            </div>
                            <div className="clearFix"></div>
                            <div className="divider"></div>
                            <div className="cont10 plr25 mb20" style={{display:'Grid',gridTemplateColumns:'20% 40% 20%'}}> 
                            < div style={{marginTop:'45px'}}> 
                                <Typography className="heading-typography">New password</Typography>  
                                </div>
                                <div style={{marginTop:'30px'}}>                          
                                <LabelledTextFieldWithAdornments
                                    // label="New password"
                                    type="password"
                                    ful
                                    placeholder="Enter new password"
                                    onChange={(e) =>
                                      handleChangePValue("new_password", e?.target?.value)
                                      }
                                    inputRef={register2("new_password").ref}                                
                                    error={Boolean(errors2.new_password)}
                                    helperText={errors2 && errors2.new_password && errors2.new_password.message}
                                />
                                </div>
                            </div>
                            <div className="clearFix"></div>
                            <div className="divider"></div>
                            <div className="cont10 plr25 mb20" style={{display:'Grid',gridTemplateColumns:'20% 40% 20%'}}>
                                <div style={{marginTop:'45px'}}>
                                 <Typography className="heading-typography">Confirm new password</Typography>     
                                 </div> 
                                 <div style={{marginTop:'30px'}}>                      
                                <LabelledTextFieldWithAdornments
                                    // label="Confirm new password"
                                    type="password"
                                    placeholder="Enter confirm new password"
                                    onChange={(e) =>
                                      handleChangePValue("confirm_password", e?.target?.value)
                                      }
                                    inputRef={register2("confirm_password").ref}                                
                                    error={Boolean(errors2.confirm_password)}
                                    helperText={errors2 && errors2.confirm_password && errors2.confirm_password.message}
                                />
                                </div>
                            </div>
                            <div className="clearFix"></div>
                            <div className="divider"></div>
                            <div className="cont50 plr25 mb20"></div>
                            {/* <div>
                              
                            </div> */}
                            <div className="cont50 plr25 mb20 textRight" style={{marginTop:'15px'}}>
                              <Button onClick={handleCancel} style={{backgroundColor:'#FFFFFF',borderRadius:'8px',color:'#333333',fontWeight:'600',height:'44px',marginTop:'3px'}}>Cancel</Button>
                              <Button type="submit" className="mainBtn">Update password</Button>
                            </div>
                        </form>
                    </div>
                  </div>
                </>
              )}

              {tabValue==='Notifications' && (
                <>
                  <div className="cont100">
                  {Object.entries(notifData)?.map(([key, val]) => {
                    const isUserImg = val.image;
                    return (
                      <>
                      <div className="notiCant">
                        <div onClick={() => handleNotifViewClick(val)} className="notiImg">
                          {isUserImg ? (
                            <img src={isUserImg} className="notiUserImg" alt=""/>
                          ) : (
                            <img src="/assets/user-icon.png" className="notiUserImg" alt="" />
                          )}
                        </div>
                        <div onClick={() => handleNotifViewClick(val)} className="notiText">
                          <div className="notitit">{val.title}</div>
                          <div className="notibody">{val.body}</div>
                          <div className="notitime">
                            {timeSince(new Date(Date.parse(val.created_at)))}
                          </div>
                        </div>
                        <div className="notiActi">
                          <DeleteForeverIcon 
                            fontSize={'small'}
                            id={val.id}
                            onClick={handleNotifAcationClick}
                            style={{cursor:'pointer',color: '#DC2804'}}
                          />
                        </div>
                      </div>
                      </>
                    );
                  })}
                    <div className="cont100">
                    
                    </div> 
                  </div>                 
                </>
              )}

          </div>
        </div>
      </div>
    );
}

export default UserProfile;