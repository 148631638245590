import { useMutation, useQuery } from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const sortHelperFun = (key) => {
  switch (key) {
    case "Section":
      return "spot_section_name";
    case "Target screen":
      return "spot_target_screen";
    default:      
      if(key.desc){
        return key.id;
      } else {
        return '-'+key.id;
      }
     
  }
};


const filterHelperFun = (filter_value) => {
  let filter_url = '';
  let category_url = '';  
  let status_url = '';
  filter_value?.forEach((fv)=>{    
    if(fv.filter==='category'){
      category_url += `${fv.label},`;
    }
    if(fv.filter==='status'){
      const str = fv.label;
      const strUC = str.toUpperCase();
      const newStr = strUC.replace(/ /g, '_');
      status_url += `${newStr},`;
    }
  })
  if(category_url !==''){
    filter_url += `&category__name__in=${category_url}`;    
  }
  if(status_url !==''){
    filter_url += `&status__in=${status_url}`;
  }
  return filter_url;
};

const FetchSellerList = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `listing/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }
  if (body?.from_date && body?.to_date) {
    url += `&created_at_after=${body?.from_date}&created_at_before=${body?.to_date}`;
  }
  if (body?.filter_value && body?.filter_value.length > 0) {
    url += filterHelperFun(body?.filter_value);
  }
  
  return useAPI({ method, url });
};



export const FetchSellerListQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_SELLER_LIST", payload],
    () => FetchSellerList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchCategoriesList = (body) => {
  const method = "GET";
 
  let url = `category/`;

  
  
  return useAPI({ method, url });
};

export const FetchCategoriesQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_SELLER_CAT_LIST", payload],
    () => FetchCategoriesList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchSubCategoriesList = (body) => {
  const method = "GET"; 
  let url = `sub-category/?category__name__in=${body.label}`;  
  return useAPI({ method, url });
};

export const FetchSubCategoriesQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_SELLER_SUb_CAT_LIST", payload],
    () => FetchSubCategoriesList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};


const FetchStatusList = (body) => {
  const method = "GET"; 
  let url = `listing-status/`;  
  
  return useAPI({ method, url });
};

export const FetchStatusListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_STATUS_LIST", payload],
    () => FetchStatusList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchMakeList = (body) => {
  const method = "GET"; 
  let url = `vehicle-brands/?sub_category__id__in=${body.id}`;
  
  return useAPI({ method, url });
};

export const FetchMakeListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_MAKE_0F_SD_LIST", payload],
    () => FetchMakeList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchYearList = (body) => {
  const method = "GET"; 
  let url = `year/`;  
  
  return useAPI({ method, url });
};

export const FetchYearListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_YEAR_0F_SD_LIST", payload],
    () => FetchYearList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchModelList = (body) => {
  const method = "GET";
  let url = `brand-models/?search=${body.label}`;  
  
  return useAPI({ method, url });
};

export const FetchModelListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_MODDEL_0F_SD_LIST", payload],
    () => FetchModelList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchEngManFactList = (body) => {
  const method = "GET";
  let url = `engine-manufacturer/`;  
  
  return useAPI({ method, url });
};

export const FetchEngManFactListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_EngManFact_0F_SD_LIST", payload],
    () => FetchEngManFactList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchTransManFactList = (body) => {
  const method = "GET";
  let url = `transmission-manufacturers/`;  
  
  return useAPI({ method, url });
};

export const FetchTransManFactListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_TransManFact_0F_SD_LIST", payload],
    () => FetchTransManFactList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchMileageList = (body) => {
  const method = "GET";
  let url = `mileage/`;  
  
  return useAPI({ method, url });
};

export const FetchMileageListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Mileage_0F_SD_LIST", payload],
    () => FetchMileageList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchHorsepowerList = (body) => {
  const method = "GET";
  let url = `horsepower/`;  
  
  return useAPI({ method, url });
};

export const FetchHorsepowerListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Horsepower_0F_SD_LIST", payload],
    () => FetchHorsepowerList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchColorList = (body) => {
  const method = "GET";
  let url = `vehicle-color/`;  
  
  return useAPI({ method, url });
};

export const FetchColorListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_COLOR_0F_SD_LIST", payload],
    () => FetchColorList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchBedsizeList = (body) => {
  const method = "GET";
  let url = `bed-size/`;  
  
  return useAPI({ method, url });
};

export const FetchBedsizeListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Bedsize_0F_SD_LIST", payload],
    () => FetchBedsizeList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};


const FetchAxleratioList = (body) => {
  const method = "GET";
  let url = `axle-ratio/`;  
  
  return useAPI({ method, url });
};

export const FetchAxleratioListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Axleratio_0F_SD_LIST", payload],
    () => FetchAxleratioList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchWheelbaseList = (body) => {
  const method = "GET";
  let url = `wheel-base/`;  
  
  return useAPI({ method, url });
};

export const FetchWheelbaseListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Wheelbase_0F_SD_LIST", payload],
    () => FetchWheelbaseList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchTrailerLengthList = (body) => {
  const method = "GET";
  let url = `trailer-length/?page=1&per_page=100`;  
  
  return useAPI({ method, url });
};

export const FetchTrailerlengthListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Trailer_length_LIST", payload],
    () => FetchTrailerLengthList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchGvwrList = (body) => {
  const method = "GET";
  let url = `gross-vehicle-weight-rating/`;  
  
  return useAPI({ method, url });
};

export const FetchGvwrListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Gvwr_0F_SD_LIST", payload],
    () => FetchGvwrList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchTiretreadsList = (body) => {
  const method = "GET";
  let url = `tire-treads/`;  
  
  return useAPI({ method, url });
};

export const FetchTiretreadsListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Tiretreads_0F_SD_LIST", payload],
    () => FetchTiretreadsList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchBraketypeList = (body) => {
  const method = "GET";
  let url = `brake-type/`;  
  
  return useAPI({ method, url });
};

export const FetchBraketypeListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Traketype_0F_SD_LIST", payload],
    () => FetchBraketypeList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchCollisionList = (body) => {
  const method = "GET";
  let url = `collision/`;  
  
  return useAPI({ method, url });
};

export const FetchCollisionListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Collision_0F_SD_LIST", payload],
    () => FetchCollisionList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchMitigationsystemList = (body) => {
  const method = "GET";
  let url = `mitigation-system/`;  
  
  return useAPI({ method, url });
};

export const FetchMitigationsystemListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_Mitigationsystem_0F_SD_LIST", payload],
    () => FetchMitigationsystemList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};















const FetchSellerDetails = (body) => {
  const method = "GET"; 
  let url = `listing/${body}/`;  
  
  return useAPI({ method, url });
};

export const GetSellerListing = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_SELLER_LIST_DETAILS", payload],
    () => FetchSellerDetails(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};
const isstringExist = (val) => {
  let myArray = val.split("vehicle_");  
  if (myArray[1])
  {    
    return true;
  } else {   
    return false;
  }
 
}
const isstringText = (val) => {
  const myArray = val.split("vehicle_");  
  if (myArray[1]) {   
    return myArray[1];
  }
}

const FormatPostBody = (body) => {
  let bodyData = {vehicle: {}};
  bodyData['id'] = body.id;
  bodyData['unique_id'] = body.unique_id;
  bodyData['category'] = body['category'].id;
  bodyData['sub_category'] = body['sub_category'].id;
  bodyData["vehicle"]['make'] = body['vehicle_make'].id;
  bodyData["vehicle"]['model'] = body['vehicle_model'].id;
  bodyData["vehicle"]['year'] = body['vehicle_year'].id;
  
  if(body['category'].label==='Commercial Truck'){
    bodyData["vehicle"]['engine_manufacturer'] = body['vehicle_engine_manufacturer'].id;
    bodyData["vehicle"]['transmission_manufacturer'] = body['vehicle_transmission_manufacturer'].id;
    bodyData["vehicle"]['gear_type'] = body['vehicle_gear_type'].id;
    bodyData["vehicle"]['mileage'] = body.vehicle_mileage;
    bodyData["vehicle"]['apu'] = body['vehicle_apu'].id;
    bodyData["vehicle"]['inverter'] = body['vehicle_inverter'].id;
    bodyData["vehicle"]['horsepower'] = body['vehicle_horsepower'].id;
    bodyData["vehicle"]['bed_size'] = body['vehicle_bed_size'].id;
    bodyData["vehicle"]['bed_count'] = body['vehicle_bed_count'].id;
    bodyData["vehicle"]['wheel_base'] = body['vehicle_wheel_base']? body['vehicle_wheel_base'].id : '';
    bodyData["vehicle"]['brake_type'] = body['vehicle_brake_type']? body['vehicle_brake_type'].id : '';
    bodyData["vehicle"]['collision'] = body['vehicle_collision']? body['vehicle_collision'].id : '';
    
  } else{
    bodyData["vehicle"]['rim_type'] = body['vehicle_rim_type'].id;
    bodyData["vehicle"]['hour'] = body.hour;
    bodyData["vehicle"]['e_tracks'] = body['vehicle_e_tracks'].id;
    bodyData["vehicle"]['rub_rails'] = body['vehicle_rub_rails'].id;
    bodyData["vehicle"]['air_ride'] = body['vehicle_air_ride'].id;
    bodyData["vehicle"]['scale'] = body['vehicle_scale'].id;
    bodyData["vehicle"]['side_skirt'] = body['vehicle_side_skirt'].id;
    bodyData["vehicle"]['tire_rack'] = body['vehicle_tire_rack'].id;
    bodyData["vehicle"]['trailer_length'] = body['trailer_length'].id;
    bodyData["vehicle"]['outstanding_loan'] = body['vehicle_outstanding_loan']? body['vehicle_outstanding_loan'].id : '';
    bodyData["vehicle"]['trailer_usage'] = body['vehicle_trailer_usage']? body['vehicle_trailer_usage'].id : '';
    bodyData["vehicle"]['major_repairs'] = body['vehicle_major_repairs']? body['vehicle_major_repairs'].id : '';
    bodyData["vehicle"]['purchase_source'] = body['vehicle_purchase_source']? body['vehicle_purchase_source'].id : '';
   
  }

  bodyData["vehicle"]['color'] = body['vehicle_color'].id;
  bodyData["vehicle"]['gross_vehicle_weight_rating'] = body['vehicle_gross_vehicle_weight_rating']? body['vehicle_gross_vehicle_weight_rating'].id : '';
  bodyData["vehicle"]['axle_ratio'] = body['vehicle_axle_ratio']? body['vehicle_axle_ratio'].id : '';
  bodyData["vehicle"]['tire_treads'] = body['vehicle_tire_treads']? body['vehicle_tire_treads'].id : '';

  bodyData['street_address'] = body.street_address;
  bodyData['city'] = body.city;
  bodyData['state'] = body.state;
  bodyData['zip_code'] = body.zip_code;
  bodyData['description'] = body.description;

  bodyData['selling_price'] = body.selling_price?.replace(/,/g,'');
  bodyData["vehicle"]['vehicle_price'] = body.selling_price?.replace(/,/g,'');

  bodyData['attachment'] = body.attachment; 

  return bodyData;
};

const PostSellerDetails = (body) => {
  const method = "PATCH";
  const bodyData = FormatPostBody(body);
  const url = `listing/${bodyData.id}/`;
  const payload = {
    ...bodyData,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleUpdateFormData = () => {  
  const responseToast = useToast();
  return useMutation(PostSellerDetails, {
    onSuccess: (response) => responseToast.toastSuccess(response?.message || "Listing updated successfully"),
    onError: (error) => responseToast.toastError(error.errors[0]?.message || "Listing  not update"),
  });
};


const PostSellerAddNew = (body) => {
  const method = "POST";
  const bodyData = FormatPostBody(body);
  const url = `listing/`;
  const payload = {
    ...bodyData,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleListingAddFormData = () => {  
  const responseToast = useToast();

  return useMutation(PostSellerAddNew, {
    onSuccess: (response) => responseToast.toastSuccess(response?.message || "Listing updated successfully"),
    onError: (error) => responseToast.toastError(error && error.message),
  });
};

const PostStatusDetails = (body) => {
  const method = "PATCH";  
  const url = `listing/${body.id}/status/`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleUpdateStatusData = () => {  
  const responseToast = useToast();
  
  return useMutation(PostStatusDetails, {
    onSuccess: (response) => responseToast.toastSuccess(response?.message || "Status updated successfully"),
    onError: (error) => responseToast.toastError(error && error.message),
  });
};

const PostIsApprovedDetails = (body) => {
  const method = "PATCH";  
  const url = `listing/${body.id}/`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleIsApprovedData = () => {  
  const responseToast = useToast();
  
  return useMutation(PostIsApprovedDetails, {
    onSuccess: (response) => responseToast.toastSuccess(response?.message || "Listing Approved successfully"),
    onError: (error) => responseToast.toastError(error && error.message),
  });
};

const PostInspectionFeeDetails = (body) => {
  const method = "POST";  
  const url = `pay-inspection-fee/`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandlePostInspectionFeeData = () => {  
  const responseToast = useToast();
  
  return useMutation(PostInspectionFeeDetails, {   
    onSuccess: (response) => responseToast.toastSuccess(response && response.message),
    onError: (error) => responseToast.toastError(error && error.message),
  });
};