import React, { useMemo, useEffect, useState } from 'react';
import { useToast } from "services";
import Styles from './TableBellIcon.module.scss';
import { Menu } from '@mui/material';
import { timeSince } from 'utils';
import { FetchNotificationsQuery, DeleteNotificationsQuery,UpdateNotificationsQuery,FetchFinanceQuery } from './Queries';
import { onMessageListener } from '../../firebaseInit';


const TableBellIcon = ({isTable=false}) => {
  const responseToast = useToast();
  const { data: notifList, refetch } = FetchNotificationsQuery();
  const notifData = useMemo(() => notifList?.results || [], [notifList]);

  const [notifId, setNotifId] = useState(null);
  const { data: notifIsDelete } = DeleteNotificationsQuery(notifId);
  const [financeId, setFinanceId] = useState(null);
  const { data: financeData } = FetchFinanceQuery(financeId);
  const { mutateAsync: ValidationNotifUpdate } = UpdateNotificationsQuery();


  useEffect(() => {
      onMessageListener((message) => {           
          console.log({'FCM-Messageeeeeeeeeeeeeeeeeeeeeeeeeeeee': message});
          // Invalidate the useGetNotifications() GetNotifications API here
          //queryClient.invalidateQueries('GET_NOTIFICATIONS');
          refetch();
      })

      navigator.serviceWorker.onmessage = (event) => {
        console.log('message from sw', event.data);
        // Invalidate the useGetNotifications() GetNotifications API here
        refetch();
      };

  }, []);


  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (notifIsDelete === '') {
      responseToast.toastSuccess("Notifications deleted successfully");
      refetch();
    }
  }, [notifIsDelete]);

  useEffect(() => {
    if(financeData?.data?.listing?.id){
      window.open(`/inquiry/details?listingId=${financeData?.data?.listing?.id}&financeId=${financeId}`, "_blank")
    }
  }, [financeData]);


  const updateNotiStatus = (data) => {
    const nData = {'id':data.id, 'read_receipt': true}
    ValidationNotifUpdate(nData).then((respData) => {      
      if(respData?.status_code===200){          
        refetch();
      }
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  //Export dropdown handlers
  const openbell = Boolean(anchorEl);
  const handleBellClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifAcationClick = (event) => {
    setNotifId(event.currentTarget.id);
  };

  const handleNotifViewClick = (val) => {
    console.log(val);
    updateNotiStatus(val);
    if(val?.target==='listing'){
      if(val?.body==='A new quote has been recieved on one of the rigs.')
      {
        window.open(`/inquiry/details?listingId=${val?.target_id}`, "_blank");
      } else if(val?.body==='A deposit has been made on a rig.')
      {
        window.open(`/deals-inprogress/details?id=${val?.target_id}`, "_blank");
      }
      else {
        window.open(`/seller-listing/details?id=${val?.target_id}`, "_blank");
      }
     
    }
    if(val?.target==='finance'){
      setFinanceId(val?.target_id);     
    }    
  };

  return (
    <div className={Styles.tableBell}>
      <Menu
        id="table-filter"
        anchorEl={anchorEl}
        open={openbell}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="tableFilter tableFilterMaxH">
          <div className="tableFilterTop">
            <div className="tableFilterTopText">Notifications</div>
            <div className="tableFilterIcon" onClick={handleClose}>
              <img src="/assets/cross-icon.png" alt=''/>
            </div>
          </div>
          {Object.entries(notifData)?.map(([key, val]) => {
            const isUserImg = val.image;
            return (
              <div className={Styles.notifCant} key={key}>
                <div onClick={() => handleNotifViewClick(val)} className={Styles.notifImg}>
                  {isUserImg ? (
                    <img src={isUserImg} alt=''/>
                  ) : (
                    <img src="/assets/user-icon.png" alt='' />
                  )}
                </div>
                <div onClick={() => handleNotifViewClick(val)} className={Styles.notifTxt}>
                  <div className={Styles.notifTxtEmil}>{val.title}</div>
                  <div className={Styles.notifTxtSub}>{val.body}</div>
                  <div className={Styles.notifTxtTime}>
                    {timeSince(new Date(Date.parse(val.created_at)))}
                  </div>
                </div>
                <div className={Styles.notifCnl}>
                  <img id={val.id} src="/assets/cross-black-icon.png" onClick={handleNotifAcationClick} alt="Delete" title="Delete" />
                </div>
              </div>
            );
          })}
          {notifData?.length===0 &&
          <span style={{textAlign: 'center', width: '100%',float: 'left', padding: '125px 0px'}}>No Notifications</span>
          }  
        </div>
      </Menu>
      <div onClick={handleBellClick} className={Styles.notiCant}>
        <img src="/assets/bell-icon.png" className={Styles.bellIconImg}  alt='' />
        {notifData?.length>0 && (
          <>
          {isTable ?(
             <span className={Styles.notiCountTable}>{notifData?.length}</span>
          ):(
            <span className={Styles.notiCount}>{notifData?.length}</span>
          )}         
          </>
          
        )
          
        }      
      </div>
    </div>
  );
};

export default TableBellIcon;
