import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Routes from 'routes';
import PrimaryTheme from 'styles/PrimaryTheme';
import './App.css';
import { ToastContainer } from "react-toastify";



function App() {
    const queryClient = new QueryClient({
      defaultOptions: {        
        queries: {
          refetchOnWindowFocus: false,
          cacheTime: 1000 * 60 * 7,
          enabled: false,
          staleTime: Infinity,
        },
      },
    });
    return (
        <div>
          <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={PrimaryTheme}>
                <ToastContainer closeButton={false} />
                <Routes />
              </ThemeProvider>
            </StyledEngineProvider>
          </QueryClientProvider>
        </div>
      );
    }

    export default App;