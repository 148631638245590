import React, { forwardRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import clsx from "clsx";

const PREFIX = "CheckboxField";

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  checkedIcon: `${PREFIX}-checkedIcon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.root}`]: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    border: "none",
  },
  [`& .${classes.icon}`]: {
    borderRadius: 6,
    /* width: 16,
    height: 16, */
    /* boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)", */
    backgroundColor: "#ECF0F3",
    /* backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))", */
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      /* backgroundColor: "#ebf1f5", */
    },
    /* "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    }, */
    width: "1.35rem",
    height: "1.35rem",
    /* margin: "0 1rem 0 .75rem", */
  },
  [`& .${classes.checkedIcon}`]: {
    borderRadius: 6,
    backgroundColor: "#3699FF",
    /* backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))", */
    "&:before": {
      display: "block",
      width: "1.35rem",
      height: "1.35rem",
      margin: "auto",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      /* backgroundColor: "#106ba3", */
    },
    width: "1.35rem",
    height: "1.35rem",
  },
});

const CheckboxField = forwardRef((props, ref) => {
  const {
    label,
    variant,
    error,
    helperText,
    className,
    inputref,
    name,
    defaultValue,
    checked,
    controlled,
    caption,
    labelPlacement,
    ...restOfCheckboxProps
  } = props;

  const [checkedValue, setCheckedValue] = React.useState(Boolean(checked));

  useEffect(() => {
    setCheckedValue(checked);
  }, [checked]);

  return (
    <Root>
      <FormControl>
        <FormGroup>
          <FormControlLabel
            control={
              controlled ? (
                <Checkbox
                  checked={checked}
                  /* color="primary" */
                  name={name || ""}
                  inputref={inputref}
                  {...restOfCheckboxProps}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  classes={{ root: classes.root }}
                />
              ) : (
                <Checkbox
                  /* color="primary" */
                  name={name || ""}
                  inputref={inputref}
                  {...restOfCheckboxProps}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  classes={{ root: classes.root }}
                />
              )
            }
            label={
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#464E5F",
                }}
              >
                {label}
              </p>
            }
            labelPlacement={labelPlacement}
            className={`${labelPlacement === "start" ? "ml-0" : ""} ${
              !label ? "w-full ml-0" : ""
            }`}
          />
          {caption && (
            <p
              className="text-xs"
              style={{
                color: "#DADADA",
                fontSize: "10px",
                fontWeight: 400,
                margin: ".5rem 0 .5rem .2rem",
              }}
            >
              {caption}
            </p>
          )}
        </FormGroup>
        <FormHelperText error={Boolean(error)} margin="dense" variant={variant}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Root>
  );
});

CheckboxField.defaultProps = {
  label: "",
  variant: "outlined",
  error: false,
  helperText: "",
  className: null,
  checked: false,
  controlled: false,
  labelPlacement: "end",
};

export default CheckboxField;
