import React, {useState} from "react";
import { CommonDialog, LabelledPriceTextField} from "components";
import Button from '@mui/material/Button';
import { usNumberFormat } from 'utils';

//Submit and Cancel buttons
const DialogActions = (props) => {
    const { handleClose , handleFeeConfrm} = props;
    return (
          <div style={{width:'100%',textAlign:'center'}}>
            <Button onClick={handleClose} className="lightBtn medemBtnSize">Cancel</Button>
            <Button onClick={handleFeeConfrm} className="mainBtn medemBtnSize">Confrm</Button>
          </div>
    );
  };

const InpectionFeeModel = (props) => {
  const { open, handleClose,handleConfrm, handleModelChangeValue } = props;
  const [inspectionFeeValue, setIspectionFeeValue] = useState(null);
  const [inspectionFeeReq, setIspectionFeeReq] = useState(false);
    
  const handleModelChangeIFeeValue = (value) => {
    setIspectionFeeReq(false);
    setIspectionFeeValue(usNumberFormat(value));
    handleModelChangeValue("inpection_fee", value?.replace(/,/g,''));
  };

  const handleFeeConfrm = () => {
    if(inspectionFeeValue===''|| inspectionFeeValue ===null){
      setIspectionFeeReq(true);
    } else {
      handleConfrm();
    }    
  };

  return (
      <>
      <CommonDialog
          open={open}
          onClose={handleClose}
          title="Inspection fee"
          actions={DialogActions({handleClose, handleFeeConfrm})}
          maxWidth="sm"
          
      >
        
          <>           
          <div>          
            <LabelledPriceTextField
                label="Please enter a inspection fee for this product."
                type="text"
                placeholder="Enter Inspection fee"       
                value={inspectionFeeValue}
                onChange={(e) =>
                  handleModelChangeIFeeValue(e?.target?.value?.trimStart().replace(/\D/, ''))
                }                                               
                maxLength={10}               
            />            
          </div>
          {inspectionFeeReq && (
            <div className="validationError">Inspection fee is required</div>
          )}
          </>
          
        
                  
      </CommonDialog>
      </>
  );
};

export default InpectionFeeModel;