import {  useMutation,useQuery } from "@tanstack/react-query";
import { useAPI, useToast } from "services";


const PostProfileDetails = (body) => {
  const method = "PATCH";  
  const url = `profile/`;
  
  return useAPI({ method, url, body: body });
};

export const HandleProfileFormAPI = () => {  
  const responseToast = useToast();

  return useMutation(PostProfileDetails, {
    onSuccess: (response) => {
      localStorage.setItem("user_details", JSON.stringify(response.data));
      responseToast.toastSuccess(response.description || "Profile updated successfully");      
    },
    onError: (error) => responseToast.toastError(error && error.message),
  });
};

const DeleteProfileImg = (body) => {
  const method = "DELETE";  
  const url = `upload-attachment/${body}/`;
  
  return useAPI({ method, url});
};

export const HandleProfileImgDeleteAPI = () => {  
  const responseToast = useToast();

  return useMutation(DeleteProfileImg, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.description || "Profile image deleted successfully");      
    },
    onError: (error) => responseToast.toastError(error && error.message),
  });
};

const GetProfileDetails = (body) => {
  const method = "GET";  
  const url = `auth/me/`;  
  return useAPI({ method, url, body: body });
};

export const HandleProfileGetAPI = () => {  
  const responseToast = useToast();

  return useMutation(GetProfileDetails, {
    onSuccess: (response) => {
      localStorage.setItem("user_details", JSON.stringify(response.data));
    },
    onError: (error) => responseToast.toastError(error && error.message),
  });
};

const UploadImage = (body) => {
  if(body?.user_id){
    DeleteProfileImg(body?.user_id);
  }  
  
  const method = "POST";
  const url = `upload-attachment/`;
  let formData = new FormData();  
  formData.append("description", body?.description);
  formData.append("attachment", body?.file);
  formData.append("is_primary", body?.is_primary);

  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};

export const HandleUploadImageQuery = () => {
  const responseToast = useToast();
  return useMutation(UploadImage, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message || "Profile Image updated successfully");
    },
    onError: (error) => responseToast.toastError(error && error.description),
  });
};

const PostPasswordDetails = (body) => {
  const method = "PATCH";  
  const url = `change-password/`;
  
  return useAPI({ method, url, body: body });
};

export const HandlePasswordFormAPI = () => {  
  const responseToast = useToast();

  return useMutation(PostPasswordDetails, {
    onSuccess: (response) => {      
      responseToast.toastSuccess(response.description || "Password updated successfully");      
    },
    onError: (error) => responseToast.toastError(error && error.errors[0]?.message),
  });
};

const FetchNotificationsList = (body) => {
  const method = "GET"; 
  let url = `notification/?per_page=1000`;  
  
  return useAPI({ method, url });
};

export const FetchNotificationsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_NOTIF_User_LIST", payload],
    () => FetchNotificationsList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const DeleteNotificationsItem = (id) => {  
  const method = "DELETE";
  const url = `notification/${id}/`;
  return useAPI({ method, url });
};

export const DeleteNotificationsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_NOTIF_USER_LIST", payload],
    () => DeleteNotificationsItem(payload),
    {
      enabled: Boolean(payload),
      onSuccess: (response) => {      
        responseToast.toastSuccess(response.description || "Notifications deleted successfully");      
      },
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};


const UpdateNotifications = (body) => {
  const method = "PATCH";  
  const url = `notification/${body.id}/`;  
  return useAPI({ method, url, body: body });
};

export const UpdateNotificationsQuery = () => {
  const responseToast = useToast();

  return useMutation(UpdateNotifications, {
    onSuccess: (response) => null,
    onError: (error) => responseToast.toastError(error && error.errors[0]?.message),
  });
};

const FetchFinance = (body) => {
  const method = "GET"; 
  let url = `finance/${body}/`;  
  
  return useAPI({ method, url });
};

export const FetchFinanceQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_UP_finance", payload],
    () => FetchFinance(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};