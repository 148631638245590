import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAPI, useToast } from 'services';

const queryKeys = {
  GET_LISTING_OBJECT: 'GET_LISTING_OBJECT',
  GET_QUOTES_LIST_BY_ID: 'GET_QUOTES_LIST_BY_ID',
  GET_USER_DOCUMENT_BY_ID: 'GET_USER_DOCUMENT_BY_ID',
};

const sortHelperFun = (key) => {
  switch (key) {
    case 'Section':
      return 'spot_section_name';
    case 'Target screen':
      return 'spot_target_screen';
    default:
      if (key.desc) {
        return key.id;
      } else {
        return '-' + key.id;
      }
  }
};

const filterHelperFun = (filter_value) => {
  let filter_url = '';
  let category_url = '';

  filter_value?.forEach((fv) => {
    if (fv.filter === 'category') {
      category_url += `${fv.label},`;
    }
  });
  if (category_url !== '') {
    filter_url += `&listing__category__name__in=${category_url}`;
  }
  return filter_url;
};

const FetchTableList = (body) => {
  const method = 'GET';
  const sorts = body?.sorts;
  let url = `quotes/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ''}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }
  if (body?.from_date && body?.to_date) {
    url += `&created_at_after=${body?.from_date}&created_at_before=${body?.to_date}`;
  }
  if (body?.filter_value && body?.filter_value.length > 0) {
    url += filterHelperFun(body?.filter_value);
  }

  return useAPI({ method, url });
};

export const FetchTableListQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ['GET_Inquiry_LIST', payload],
    () => FetchTableList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchCategoriesList = (body) => {
  const method = 'GET';
  let url = `category/`;

  return useAPI({ method, url });
};

export const FetchCategoriesQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ['GET_INQ_Categories_LIST', payload],
    () => FetchCategoriesList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const UpdateListingStatus = ({ id }) => {
  return useAPI({
    method: 'PATCH',
    url: `listing/${id}/status/`,
  });
};

export const useUpdateListingStatusQuery = (payload) => {
  const toast = useToast();
  return useMutation(UpdateListingStatus, {
    onError: (error) => toast.toastError(error && error.message),
  });
};
/*
const GetListingObjectAAA = (payload) => {
  return useAPI({
    method: 'GET',
    url: `listing/${payload.id}/`,
  });
};

export const useGetListingObjectQueryAAA = (payload) => {
  const toast = useToast();
  console.log("AAAAAAAAAAAAAAAAAAA");
  console.log(payload);

  return useQuery(
    [queryKeys.GET_LISTING_OBJECT, payload],
    () => GetListingObject(payload),
    {
      onError: (error) => toast.toastError(error && error.message),
    }
  );
};
*/
const GetListingObject = (body) => {
  const method = "GET"; 
  let url = `listing/${body.id}/`;
  return useAPI({ method, url });
};

export const useGetListingObjectQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_LISTING_OBJECT_FOR_DETAILS", payload],
    () => GetListingObject(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const GetQuotesListById = ({ id, params }) => {
  const { ordering, ...restParams } = params;
  let colId;
  if (ordering[0]?.id)
    colId = ordering[0]?.desc ? ordering[0]?.id : `-${ordering[0]?.id}`;

  const newParams = { ...restParams };
  colId && (newParams.ordering = colId);

  return useAPI({
    method: 'GET',
    url: `listing/${id}/quotes/`,
    params: newParams,
  });
};

export const useGetQuotesByListingId = (payload) => {
  const toast = useToast();
  return useQuery(
    [queryKeys.GET_QUOTES_LIST_BY_ID, payload],
    () => GetQuotesListById(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => toast.toastError(error && error.message),
    }
  );
};
/* 
const GetUserDocumentById = ({ user_id, document_id }) => {
  return useAPI({
    method: 'GET',
    url: `/api/v1/users/${user_id}/documents/${document_id}/`,
  });
};

export const useGetUserDocumentById = (payload) => {
  const toast = useToast();
  return useQuery(
    [queryKeys.GET_USER_DOCUMENT_BY_ID, payload.id],
    () => GetUserDocumentById(payload),
    {
      onError: (error) => toast.toastError(error && error.message),
    }
  );
};
 */

const UpdateQuoteStatus = ({ id, data }) => {
  console.log('data ; ', data);
  return useAPI({
    method: 'PATCH',
    url: `quotes/${id}/`,
    body: data,
  });
};

export const useUpdateQuoteStatusQuery = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(UpdateQuoteStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.GET_QUOTES_LIST_BY_ID]);
    },
    onError: (error) => toast.toastError(error && error.message),
  });
};

const FetchFinance = (body) => {
  const method = "GET"; 
  let url = `finance/${body}/`;  
  
  return useAPI({ method, url });
};

export const FetchFinanceQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_InQ_finance", payload],
    () => FetchFinance(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};