import { ExpandLess, ExpandMore,Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { NavigationLinks } from 'routes/RouteConstants';


export default function Sidebar({ toggleSidebar }) {
  const classes = {
    root: {
      width: '100%',
      backgroundColor: 'white',
      // "& .MuiListItem-gutters ": {

      //   "&:focus":{
      //     backgroundColor:"red"
      //   }
      // }
      // marginTop: '80px',
    },
    nested: {
      paddingLeft: '40px',
    },
    nestedSecondLevel: {
      paddingLeft: '80px',
    },
  };
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location);
  const [open, setOpen] = useState(false);
  const [openSecondLevel, setOpenSecondLevel] = useState(false);
  const [navItemSelected, setNavItemSelected] = useState('');
  const [sidebar, setSidebar] = useState(true);

  // const showSidebar = ({ toggleSidebar }) => setSidebar(!sidebar);
  const showSidebar = () => {
    setSidebar(!sidebar);
    toggleSidebar(!sidebar);
  };

  const handleClick = (NavigationItem) => {
    setOpen(!open);
    setNavItemSelected(NavigationItem.name);
  };
  const handleNavigate = (NavigationItem) => {
    navigate(NavigationItem.redirectLink);
    setNavItemSelected(NavigationItem.name);
  };

  const handleClickSecondLevel = (NavigationItem) => {
    setOpenSecondLevel(!openSecondLevel);
    setNavItemSelected(NavigationItem.name);
  };
  
  const displayDrawerLinks = (NavigationItem, NavigationIndex) => {
    const isItemSelected =
      navItemSelected === NavigationItem.name ||
      location.pathname
        .split('-')
        .join(' ')
        .includes(NavigationItem.name.toLowerCase());

    
    return (
      <>
        {sidebar ? (
          <div className="no-scrollbar">
            <List
              component="nav"
              sx={{ padding: '8px 8px', boxSizing: 'border-box' }}
              aria-labelledby="nested-list-subheader"
              //   sx={classes.root}
            >
              {NavigationItem && (
                <>
                  <ListItem
                    button
                    selcted={isItemSelected}
                    sx={{
                      backgroundColor: isItemSelected ? 'white' : '#0F5E91',
                      color: isItemSelected ? '#0C4B74' : 'white',
                      borderRadius: 1.5,
                      '&:hover': {
                        backgroundColor: isItemSelected
                          ? 'rgba(255, 255, 255, 0.9)'
                          : 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                    onClick={
                      'children' in NavigationItem
                        ? () => handleClick(NavigationItem)
                        : null || 'redirectLink' in NavigationItem
                        ? () => handleNavigate(NavigationItem)
                        : null
                    }
                    className="flex gap-1 items-center"
                  >
                    {/* <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon> */}
                    {NavigationItem.icon}

                    <ListItemText
                      sx={{
                        fontWeight: '500',
                        // color: isItemSelected ? '#0C4B74' : 'white',
                      }}
                      primary={NavigationItem.name}
                    />

                    {}
                    {'children' in NavigationItem ? (
                      open ? (
                        <>
                          <ExpandLess />
                          {/* <div
                          style={{
                            padding: '5px',
                            backgroundColor: 'red',
                            marginRight: '-13px',
                            paddingLeft: '10px',
                          }}
                        ></div> */}
                        </>
                      ) : (
                        <>
                          <ExpandMore />
                          {/* <div
                          style={{
                            padding: '5px',
                            backgroundColor: 'red',
                            marginRight: '-13px',
                            paddingLeft: '10px',
                          }}
                        ></div> */}
                        </>
                      )
                    ) : null}
                  </ListItem>

                  {'children' in NavigationItem &&
                    NavigationItem.children?.map(
                      (NavigationItem1, Navigation1Index) => {
                        return (
                          <>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                <ListItem
                                  button
                                  sx={classes.nested}
                                  onClick={
                                    'subChildren' in NavigationItem1
                                      ? () =>
                                          handleClickSecondLevel(
                                            NavigationItem1
                                          )
                                      : null ||
                                        'redirectLink' in NavigationItem1
                                      ? () => handleNavigate(NavigationItem1)
                                      : null
                                  }
                                >
                                  {/* <ListItemIcon>
                                  <SearchIcon />
                                </ListItemIcon> */}
                                  <ListItemText
                                    primary={NavigationItem1.name}
                                    // className={`${
                                    //   isItemSelected ? 'text-primaryGreen' : null
                                    // }`}
                                  />
                                  {'subChildren' in NavigationItem1 ? (
                                    openSecondLevel ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )
                                  ) : null}
                                </ListItem>
                                {'subChildren' in NavigationItem1 &&
                                  NavigationItem1.subChildren?.map(
                                    (NavigationItem2, Navigation2Index) => {
                                      return (
                                        <>
                                          <Collapse
                                            in={openSecondLevel}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <List
                                              component="div"
                                              disablePadding
                                            >
                                              <ListItem
                                                button
                                                sx={classes.nestedSecondLevel}
                                                onClick={() =>
                                                  handleNavigate(
                                                    NavigationItem2
                                                  )
                                                }
                                              >
                                                {/* <ListItemIcon>
                                                <SearchIcon />
                                              </ListItemIcon> */}
                                                <ListItemText
                                                  primary={NavigationItem2.name}
                                                />
                                              </ListItem>
                                            </List>
                                          </Collapse>
                                        </>
                                      );
                                    }
                                  )}
                              </List>
                            </Collapse>
                          </>
                        );
                      }
                    )}
                </>
              )}
            </List>
          </div>
        ) : (
          <div>
            <List
              component="nav"
              sx={{ padding: '8px 8px', boxSizing: 'border-box' }}
              aria-labelledby="nested-list-subheader"
            >
              {NavigationItem && (
                <>
                  <ListItem
                    // selected={isItemSelected}
                    sx={{
                      backgroundColor: isItemSelected ? 'white' : '#0F5E91',
                      color: isItemSelected ? '#0C4B74' : 'white',
                      borderRadius: 1.5,
                      '&:hover': {
                        backgroundColor: isItemSelected
                          ? 'rgba(255, 255, 255, 0.9)'
                          : 'rgba(0, 0, 0, 0.04)',
                        cursor: 'pointer',
                      },
                      width: 'fit-content',
                    }}
                    onClick={
                      'children' in NavigationItem
                        ? () => handleClick(NavigationItem)
                        : null || 'redirectLink' in NavigationItem
                        ? () => handleNavigate(NavigationItem)
                        : null
                    }
                  >
                    {/* <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon> */}
                    {NavigationItem.icon}

                    {/* <ListItemText
                      sx={{ fontWeight: '500', color: 'white' }}
                      primary={NavigationItem.name}
                    /> */}

                    {}
                    {'children' in NavigationItem ? (
                      open ? (
                        <>
                          <ExpandLess />
                          {/* <div
                          style={{
                            padding: '5px',
                            backgroundColor: 'red',
                            marginRight: '-13px',
                            paddingLeft: '10px',
                          }}
                        ></div> */}
                        </>
                      ) : (
                        <>
                          <ExpandMore />
                          {/* <div
                          style={{
                            padding: '5px',
                            backgroundColor: 'red',
                            marginRight: '-13px',
                            paddingLeft: '10px',
                          }}
                        ></div> */}
                        </>
                      )
                    ) : null}
                  </ListItem>
                </>
              )}
            </List>
          </div>
        )}
      </>
    );
  };

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const logoutUser = () => {    
    localStorage.clear();
    window.location.reload(); 
  };
  const UserProfileLink =() => {

    return (
      <>
        {sidebar ? (
          <div className='upMenu'>
            <div  className="sidebarUser">
              <div className="sidebarUserImg">
                {user_details?.profile_image?.attachment ? (
                  <img src={user_details?.profile_image?.attachment}  alt="User" />
                ):(
                  <img src="/assets/user-icon.png"  alt="User" />
                )}
                
              </div>
              
              <div className="sidebarUserName">
              <a href='/user-profile' target="_blank">
                <span className='sidebarUserLinkN'>{user_details?.name}</span><br/>
                <span className='sidebarUserLinkE'>{user_details?.email}</span>
                </a>
              </div>
              <div onClick={logoutUser} className='sidebarUserlogout'><Logout sx={{ fontSize: 18 }}/></div>
            </div>
          </div>
        ) :(
        <div className='upMenu'>
            <div onClick={logoutUser} className='sidebarUserlogoutMenu' style={{paddingRight:'15px'}}><Logout sx={{ fontSize: 22 }}/></div>
            <div  className="sidebarUser">
              <div className="sidebarUserImg" style={{paddingLeft: '20px'}}>
                {user_details?.profile_image?.attachment ? (
                  <a href='/user-profile' target="_blank"><img src={user_details?.profile_image?.attachment}  alt="User" /></a>
                ):(
                  <a href='/user-profile' target="_blank"><img src="/assets/user-icon.png"  alt="User" /></a>
                )}
                
              </div>
            </div>
        </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="sidebar-layout">
        <div class="grid grid-cols-2 gap-2">
          {sidebar ? (
            <div>
              <img src="/assets/SMR-04-logo.svg" alt='logo' />
            </div>
          ) : null}

          <div
            className={
              sidebar ? 'text-white mr-2 navbar' : 'text-white navbar inactive'
            }
            onClick={showSidebar}
          >
            <MenuIcon />
          </div>
        </div>
        <List>{NavigationLinks?.map(displayDrawerLinks)}</List>
        <UserProfileLink />
      </div>
    </>
  );
}
