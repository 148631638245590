import React, { useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FilterChildComponent } from './FilterChildComponent';
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { FiSearch } from 'react-icons/fi';
import { nanoid } from 'nanoid';

//import { SortComponent } from './SortComponent';
//import { CustomSlider } from 'components/Sliders/CustomSlider';
//import { debounce } from 'utils/debounce';

export const FilterParentComponent = ({
  title,
  data,
  appliedFilters,
  setAppliedFilters,
  ShowSearch,
  isSlider,
  filterFilter,
  setFilterFilter,
}) => {
  //console.log(title);
  const [expanded, setExpanded] = useState(false);
  // console.log('i am data',data)
  const [search, setSearch] = useState('');
  const [renderOptions, setRenderOptions] = useState(data);
  const [sliderValue, setSliderValue] = useState(
    title == 'Price' ? [0, 1000000] : 200000
  );
  useEffect(() => {
    let filtered = data?.filter((el) => {
      return el.toString()?.toLowerCase()?.indexOf(search.toLowerCase()) !== -1
        ? true
        : false;
    });
    setRenderOptions(filtered);
  }, [search, data]);

  const handleChangeExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      disableGutters
      sx={{
        minHeight: '60px',
        maxHeight: '320px',
        boxShadow: 'none',

        '&:before': {
          display: 'none',
        },
        '&.MuiPaper-elevation1': {
          boxShadow: 'none !important',
        },
      }}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded === 'parent'}
      onChange={handleChangeExpansion('parent')}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{ boxShadow: 'none !important' }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            minHeight: '60px',
            position: 'absolute',
            display: 'flex',
            textTransform: 'capitalize',
            fontWeight: '500 !important',
          }}
          className="capitalize px-2 tableFilterText"
        >
          {title
            .split('_')
            .map((el) => {
              return el;
            })
            .join(' ')}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ justifyContent: 'left', alignItems: 'center' }}>
        {ShowSearch == true && (
          <FormControl sx={{ padding: '10px' }} variant="outlined">
            <OutlinedInput
              color="primary"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              sx={{
                '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid gray',
                },
                height: '36px',
              }}
              type={'text'}
              id="outlined-adornment-Search"
              startAdornment={
                <InputAdornment position="start">
                  {' '}
                  <span className="svg-icon-blue">
                    <img src="/assets/search-icon.png" />
                  </span>{' '}
                </InputAdornment>
              }
              aria-describedby="outlined-search-helper-text"
              placeholder={`Search ${title
                .split('_')
                .map((el) => {
                  return el;
                })
                .join(' ')}`}
              inputProps={{
                'aria-label': 'Search',
              }}
            />
          </FormControl>
        )}
        <Box
          sx={{
            overflow: 'scroll',
            maxHeight: '250px',
            minHeight: '100px',
           
            paddingX: '5px',
          }}
        >
          {
            renderOptions?.map((el, index) => {
              return (
                <FilterChildComponent
                  filterFilter={filterFilter}
                  setFilterFilter={setFilterFilter}
                  parent={title}
                  key={index}
                  setAppliedFilters={setAppliedFilters}
                  appliedFilters={appliedFilters}
                  data={el}
                />
              );
            })
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
