import React from "react";
import { Dialog,Box, CircularProgress } from '@mui/material';


const FullPageLoadingModel = (props) => {
    const { open } = props;    

    return (
        <Dialog
            aria-labelledby={'aria-labelledby'}
            aria-describedby={'aria-describedby'}           
            open={open}            
            id={'fullpageloading'}            
            disableEscapeKeyDown={true}
            PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}
        >
            
            <Box sx={{ display: 'flex', width: '80px', height: '80px'}}>
                <CircularProgress size={'50px'}  />
            </Box>
        </Dialog>
    );
};

export default FullPageLoadingModel;