import { useQuery, useMutation, QueryClient } from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const UploadImage = (body) => {
  const method = "POST";
  const url = `upload-attachment/`;
  let formData = new FormData();
  
  formData.append("description", body?.description);
  formData.append("attachment", body?.file);
  formData.append("is_primary", body?.is_primary);
  
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};

export const UploadImageQuery = () => {
  const responseToast = useToast();
  return useMutation(UploadImage, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error && error.description),
  });
};

// PATCH image :

const UpdateImage = (body) => {
  const method = "PATCH";
  const url = `upload-attachment/${body?.image.id}/`;
  let formData = new FormData();  
  formData.append("is_primary", body?.image.is_primary);
  formData.append("listing_id", body?.listingid);

  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};

export const UpdateImageAPI = () => {
  const responseToast = useToast();
  return useMutation(UpdateImage, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error.description),
  });
};
