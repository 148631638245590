import { lazy } from 'react';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';

// import LandingPage from 'pages/LandingPage';
import LandingPage from 'pages/LandingPage/Index';
import LoginPage from 'pages/LoginPage/Index';
import ForgotPassword from 'pages/ForgotPassword/Index';
import Dashboard from 'pages/Dashboard/index';
import SellerListing from 'pages/SellerListing/index';
import SellerListingDetails from 'pages/SellerListing/details';
import SellerListingAddnew from 'pages/SellerListing/addnew';
import Inventory from 'pages/Inventory/index';
import Inquiry from 'pages/Inquiry/index';
import DealsInprogress from 'pages/DealsInprogress/index';
import DealsInprogressDetails from 'pages/DealsInprogress/details';
import FinanceRequest from 'pages/FinanceRequest/index';
import FinanceRequestDetails from 'pages/FinanceRequest/details';
import Users from 'pages/Users/index';
import UsersDetails from 'pages/Users/details';
import Transaction from 'pages/Transaction/index';
import InquiryDetails from 'pages/Inquiry/InquiryDetails';
import UserProfile from 'pages/UserProfile/index';


// const ShipperLogin = lazy(() => import('pages/Login/index'));

const RouteConstants = {
  REDIRECTOR: '/',
  LOGIN: '/login',
  DEALS_IN_PROGRESS: '/deals-in-progress',
  INQUIRY: '/inquiry',
  INQUIRY_DETAIL: '/inquiry/details',
};

export const NavigationLinks = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    selectedIcon: <DashboardIcon />,
    redirectLink: '/dashboard',
  },
  {
    name: 'Seller listing',
    icon: <FormatListBulletedIcon />,
    selectedIcon: <FormatListBulletedIcon />,
    redirectLink: '/seller-listing',
  },
  {
    name: 'Inventory',
    icon: <FeedOutlinedIcon />,
    selectedIcon: <FeedOutlinedIcon />,
    redirectLink: '/inventory',
  },
  {
    name: 'Sales Inquiry',
    icon: <MarkUnreadChatAltIcon />,
    selectedIcon: <MarkUnreadChatAltIcon />,
    redirectLink: '/inquiry',
  },
  {
    name: 'Deals in progress',
    icon: <UpdateOutlinedIcon />,
    selectedIcon: <UpdateOutlinedIcon />,
    redirectLink: RouteConstants.DEALS_IN_PROGRESS,
  },
  {
    name: 'Finance request',
    icon: <AccountBalanceOutlinedIcon />,
    selectedIcon: <AccountBalanceOutlinedIcon />,
    redirectLink: '/finance-request',
  },
  {
    name: 'Users',
    icon: <PersonOutlineIcon />,
    selectedIcon: <PersonOutlineIcon />,
    redirectLink: '/users',
  },
  {
    name: 'Transaction',
    icon: <SyncAltOutlinedIcon />,
    selectedIcon: <SyncAltOutlinedIcon />,
    redirectLink: '/transaction',
  },
];

export const routes = {
  REDIRECTOR: {
    pathname: '/',
    title: 'Login',
    isPublic: true,
    element: <LoginPage />,
  },
  LOGIN: {
    pathname: '/login',
    title: 'Login',
    isPublic: true,
    element: <LoginPage />,
  },
  FORGOT_PASSWORD: {
    pathname: '/forgot-password',
    title: 'Forgot Passowrd',
    isPublic: true,
    element: <ForgotPassword/>,
  },
  DASHBOARD_HOME: {
    pathname: '/dashboard',
    title: 'Dashboard',
    isPublic: false,
    element: <Dashboard/>,
  },
  SELLER_LISTING: {
    pathname: '/seller-listing',
    title: 'Seller listing',
    isPublic: false,
    element: <SellerListing />,
  },
  SELLER_LISTING_DETAILS: {
    pathname: '/seller-listing/details',
    title: 'Seller listing details',
    isPublic: false,
    isTopPublic: true,
    element: <SellerListingDetails />,
  },
  SELLER_LISTING_ADDNEW: {
    pathname: '/seller-listing/add-new',
    title: 'Seller listing Add New',
    isPublic: false,
    isTopPublic: true,
    element: <SellerListingAddnew />,
  },

  INVENTORY: {
    pathname: '/inventory',
    title: 'Inventory',
    isPublic: false,
    element: <Inventory />,
  },
  INQUIRY: {
    pathname: '/inquiry',
    title: 'Sales Inquiry',
    isPublic: false,
    element: <Inquiry />,
  },
  INQUIRY_DETAIL: {
    pathname: RouteConstants.INQUIRY_DETAIL,
    title: 'Request Inquiry View',
    isPublic: false,
    isTopPublic: true,
    element: <InquiryDetails />,
  },
  DEALS_INPROGRESS: {
    pathname: RouteConstants.DEALS_IN_PROGRESS,
    title: 'Deals inprogress',
    isPublic: false,
    element: <DealsInprogress />,
  },
  DEALS_INPROGRESS_DETAILS: {
    pathname: '/deals-inprogress/details',
    title: 'Deals inprogress details',
    isPublic: false,
    isTopPublic: true,
    element: <DealsInprogressDetails />,
  },
  FINANCE_REQUEST: {
    pathname: '/finance-request',
    title: 'Finance request',
    isPublic: false,
    element: <FinanceRequest />,
  },
  FINANCE_REQUEST_DETAILS: {
    pathname: '/finance-request/details',
    title: 'Finance request details',
    isPublic: false,
    isTopPublic: true,
    element: <FinanceRequestDetails />,
  },
  
  USERS: {
    pathname: '/users',
    title: 'Users',
    isPublic: false,
    element: <Users />,
  },
  USERS_DETAILS: {
    pathname: '/users/details',
    title: 'Users details',
    isPublic: false,
    isTopPublic: true,
    element: <UsersDetails />,
  },
  TRANSACTION: {
    pathname: '/transaction',
    title: 'Transaction',
    isPublic: false,
    element: <Transaction />,
  },
  USER_PROFILE: {
    pathname: '/user-profile',
    title: 'User Profile',
    isPublic: false,
    isTopPublic: true,
    element: <UserProfile />,
  },
};

export const routeKey = Object.assign(
  {},
  ...Object.keys(routes).map((routeKey) => ({
    [routes[routeKey].pathname]: routeKey,
  }))
);

export const pageDetails = routes[routeKey[window.location.pathname]];

export default RouteConstants;
