import { useQuery,useMutation} from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const sortHelperFun = (key) => {
  switch (key) {
    case "Section":
      return "spot_section_name";
    case "Target screen":
      return "spot_target_screen";
    default:      
      if(key.desc){
        return key.id;
      } else {
        return '-'+key.id;
      }
     
  }
};

const statusHelperFun = (key) => {
  switch (key) {
    case "Application Under Process":
      return "UNDER_PROCESS";
    case "Financing Completed":
      return "COMPLETE";
    case  "Application Denied":
      return "DENIED";
    default:
        return key;      
  }
};


const filterHelperFun = (filter_value) => {
  let filter_url = '';
  let category_url = '';
  
  let status_url = '';
  filter_value?.forEach((fv)=>{    
    if(fv.filter==='category'){
      category_url += `${fv.label},`;
    }
    if(fv.filter==='status'){      
      const newStr = statusHelperFun(fv.label);
      status_url += `${newStr},`;
    }     
  })
  if(category_url !==''){
    filter_url += `&category__name__in=${category_url}`;    
  }
  if(status_url !==''){
    filter_url += `&status__in=${status_url}`;
  }
  
  return filter_url;
};

const FetchTableList = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `finance/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }
  if (body?.from_date && body?.to_date) {
    url += `&created_at_after=${body?.from_date}&created_at_before=${body?.to_date}`;
  }
  if (body?.filter_value && body?.filter_value.length > 0) {
    url += filterHelperFun(body?.filter_value);
  }
  if (body?.equipment_type) {
    url += `&equipment_type=${body?.equipment_type}`;
  }
  
  
  return useAPI({ method, url });
};



export const FetchTableListQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_FINANCE_LIST", payload],
    () => FetchTableList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchCategoriesList = (body) => {
  const method = "GET"; 
  let url = `category/`; 
  
  return useAPI({ method, url });
};

export const FetchCategoriesQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_FINANCE_CAT_LIST", payload],
    () => FetchCategoriesList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};


const FetchFinance = (body) => {
  const method = "GET"; 
  let url = `finance/${body}/`;  
  
  return useAPI({ method, url });
};

export const FetchFinanceQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DP_finance", payload],
    () => FetchFinance(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const SendDoctoSlateFin = (body) => {
  const method = "POST";
  const url = `send-documents/`;
  const formData = {
    finance_id: body  
  };
  
  return useAPI({
    method,
    url,
    body: formData,
  });
};

export const FetchSendDocToSlateFinancialQuery = () => {
  const responseToast = useToast();
  return useMutation(SendDoctoSlateFin, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error.description),
  });
};

const PostStatusDetails = (body) => {
  const method = "PATCH";  
  const url = `finance/${body.id}/`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleUpdateStatusData = () => {  
  const responseToast = useToast();
  
  return useMutation(PostStatusDetails, {
    onSuccess: (response) => responseToast.toastSuccess(response?.message || "Status updated successfully"),
    onError: (error) => responseToast.toastError(error && error.message),
  });
};
