import { lazy, Suspense, useMemo } from 'react';
import { Route, Routes, Outlet, Navigate, useLocation } from 'react-router-dom';
import RouteConstants from 'routes/RouteConstants';

import { Layout, TopLayout, SeoHelmet } from 'components';
import { routes } from 'routes/RouteConstants';

// import AppContainer from 'Pages/AppContainer';
//const NotFound404 = lazy(() => import('pages/404'));
const DefaultScreen = lazy(() => import('pages/DefaultScreen'));


const RoutesPage = () => {
  const PublicRoutes = useMemo(
    () => (
      <>
        {/* <Suspense fallback={null}> */}
        {Object.values(routes)
          .filter(
            (routeDetails) => routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => (
            <Route
              key={`public-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            />
          ))}
        {/* </Suspense> */}
      </>
    ),
    []
  );
  const PrivateRoutes = useMemo(
    () => (
      <>        
        {Object.values(routes)
          .filter(
            (routeDetails) => !routeDetails.isPublic && routeDetails.element
          )
          .map((routeDetails) => (
            <Route
              key={`private-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            />
          ))}
      </>
    ),
    []
  );
  const PrivateTopRoutes = useMemo(
    () => (
      <>        
        {Object.values(routes)
          .filter(
            (routeDetails) => !routeDetails.isPublic && routeDetails.isTopPublic
          )
          .map((routeDetails) => (
            <Route
              key={`private-top-route-${routeDetails.pathname}`}
              path={routeDetails.pathname}
              element={
                <Suspense fallback={null}>{routeDetails.element}</Suspense>
              }
            />
          ))}
      </>
    ),
    []
  );
  return (
    <>
      <div className="">
        <SeoHelmet />
        <Routes>
          {PublicRoutes}

          <Route element={<ProtectedTopRoutes />}>
            {PrivateTopRoutes}            
          </Route>
          
          <Route element={<ProtectedRoutes />}>
            {/* Dashboard */}
            {PrivateRoutes}
            {/* <Route path = {RouteConstants.P} */}
            <Route path="*" element={<DefaultScreen />} />
            {/* <Route path="*" element={<NotFound404 />} /> */}
            {/* Not Found */}
          </Route>
          
          
          <Route path="*" element={<DefaultScreen />} />

          {/* <Route path="*" element={<NotFound404 />} /> */}
        </Routes>
      </div>
    </>
  );
};

const ProtectedRoutes = () => {
  const location = useLocation();  
  const isLoggedIn = Boolean(localStorage.getItem("token"));
  
  return isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={RouteConstants.LOGIN} replace state={{ from: location }} />
  );
};

const ProtectedTopRoutes = () => {
  const location = useLocation();  
  const isLoggedIn = Boolean(localStorage.getItem("token"));
  
  return isLoggedIn ? (
    <TopLayout>
      <Outlet />
    </TopLayout>
  ) : (
    <Navigate to={RouteConstants.LOGIN} replace state={{ from: location }} />
  );
};

export default RoutesPage;
