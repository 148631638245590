const classForTransactionListingStatus = (status) => {
  switch (status) {    
    case "succeeded":
        return ( 
            <p className="greenColour">
                &#x2022; Successful
            </p>
        )
    case "failed":
        return ( 
            <p className="greenColour">
                &#x2022; Failed
            </p>
        )
    case "pending":
        return ( 
            <p className="greenColour">
                &#x2022; Pending
            </p>
        )
    case "canceled":
    return ( 
        <p className="greenColour">
            &#x2022; Canceled
        </p>
    )
    case "refund":
        return ( 
            <p className="redColour">
                &#x2022; Refund
            </p>
        )
    
  default:
    return status;
}

};

export default classForTransactionListingStatus;
