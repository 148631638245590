import ClearIcon from "@mui/icons-material/Clear";
import React, { useState } from "react";
import Styles from "./TableSearch.module.scss";


const TableSearch = ({ onChange, placeholder }) => {
  const clearSearch = () => {
    onChange("");
    setSearchText("");
  };
  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (search) => {
    setSearchText(search);
    onChange(search);
  };
  return (
    <div className={Styles.tableSearch}>
      <span className="svg-icon-blue">
       <img src="/assets/search-icon.png" />
      </span>
      <input
        type="text"
        placeholder={`Search`}
        className={Styles.tableSearchInput}
        onChange={(e) => handleSearchChange(e.target.value)}       
        value={searchText}
      />
      <span>
        <ClearIcon
          fontSize="small"
          className={`cursor-pointer saboo_grey-100 ${
            !searchText ? "hidden" : ""
          }`}
          onClick={clearSearch}
          color="fieldLabel"
        />
      </span>
    </div>
  );
};

export default TableSearch;
