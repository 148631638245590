
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {Button } from '@mui/material';
import { LabelledTextField, LabelledPriceTextField, LabelledSelectField } from "components";
import { usNumberFormat } from 'utils';
import ListingPhoto from "components/ListingPhoto";
import MsgSuccessModel  from "components/MsgSuccessModel";

import * as yup from "yup";
import { FetchCategoriesQuery,
        FetchSubCategoriesQuery,
        FetchMakeListsQuery,
        FetchYearListsQuery,
        FetchModelListsQuery,
        FetchEngManFactListsQuery,
        FetchTransManFactListsQuery,
        FetchHorsepowerListsQuery,
        FetchColorListsQuery,
        FetchBedsizeListsQuery,
        FetchAxleratioListsQuery,
        FetchWheelbaseListsQuery,
        FetchTrailerlengthListsQuery,
        FetchGvwrListsQuery,        
        FetchTiretreadsListsQuery,
        FetchBraketypeListsQuery,
        FetchCollisionListsQuery,
        FetchMitigationsystemListsQuery,
        HandleIsApprovedData,
        //HandleUpdateStatusData,      
        HandleListingAddFormData } from "./Queries";

const numberRegExp = /^\d+$/;  
const userValidSchema = yup.object().shape({
    category: yup.object().required("Make is a required field"),
    sub_category: yup.object().required("Make is a required field"),
    vehicle_make: yup.object().required("Make is a required field"),
    vehicle_model: yup.object().required("Make is a required field"),
    vehicle_year: yup.object().required("Year is a required field"),
    vehicle_engine_manufacturer: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
        }),
    vehicle_transmission_manufacturer: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
        }),
    vehicle_gear_type: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
        }),
    vehicle_mileage: yup
        .string()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.string().required("Mileage is a required field").matches(numberRegExp, 'Mileage is a required in number');
            } else {
                return yup.string().nullable();
            }
        }),
    vehicle_apu: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
        }),
    vehicle_inverter: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
        }),
    vehicle_horsepower: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
        }),
    vehicle_bed_count: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),
    vehicle_bed_size: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label==='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),

    //vehicle_transmission_manufacturer: yup.object().required("Is a required field"),    
    //vehicle_gear_type: yup.object().required("Is a required field"),
    //vehicle_mileage: yup.string().required("Mileage is a required field").matches(numberRegExp, 'Mileage is a required in number'),
    //vehicle_apu: yup.object().required("Is a required field"),
    //vehicle_inverter: yup.object().required("Is a required field"),
    //vehicle_horsepower: yup.object().required("Is a required field"),    
    //vehicle_bed_count: yup.string().required("Bed count is a required field"),
    //vehicle_bed_size: yup.object().required("Is a required field"),
    vehicle_rim_type: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
        }),
    hour: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.string().required("Hour is a required field");
            } else {
                return yup.string().nullable();
            }
    }),
    vehicle_e_tracks: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),
    vehicle_rub_rails: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),
    vehicle_air_ride: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),
    vehicle_scale: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),
    vehicle_side_skirt: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),
    vehicle_tire_rack: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),
    trailer_length: yup
        .array()
        .when("category", (st, schema) => {
            if (st?.label!=='Commercial Truck'){
                return yup.object().required("Required");
            } else {
                return yup.object().nullable();
            }
    }),
    

    vehicle_color: yup.object().required("Is a required field"),
    street_address: yup.string().nullable().required("Apartment, Suite is a required field"),
    city: yup.string().nullable().required("City is a required field"),
    state: yup.string().nullable().required("State is a required field"),
    zip_code: yup.string().nullable().required("Zip Code is a required field"),    
    selling_price: yup.string().nullable().required("Selling price is a required field"),
    description: yup.string().required("Description is a required field"),
    // .max(1000,'Maximum 1000 characters are allowed'),
    attachment: yup.array().min(1, "One Photo is a required").required("One Photo is a required"),
    });

const bedcountLists = [{id: 'Single', label:'Single'},{id:'Double', label:'Double'}];
const inverterLists = [{id: true, label:'YES'},{id:false, label:'NO'}];
const yesNoLists = [{id:'YES', label:'YES'},{id:'NO', label:'NO'}]; 
const rimLists = [{id:'Steel', label:'Steel'},{id:'Aluminium', label:'Aluminium'}]; 
const yesNoDontLists =  [{id:'YES', label:'YES'},{id:'NO', label:'NO'},{id:"I don't know", label:"I don't know"}];
const trailerusageLists = [{id:'Local', label:'Local'},{id:'Long Haul', label:'Long Haul'},{id:'Both of them', label:'Both of them'}];
const purchasesourceLists = [{id:'Dealership', label:'Dealership'},{id:'Private Party', label:'Private Party'},{id:"I don't know", label:"I don't know"}];
        

const SellerListingAddnew = () => { 
    
    const productId=1;
    
    const [categoryValue, setCategoryValue] = useState(null);
    const [subcategoryValue, setSubcategoryValue] = useState(null);
    const [makeValue, setMakeValue] = useState(null);
    const [yearValue, setYearValue] = useState(null);    
    const [modelValue, setModelValue] = useState(null);
    const [engManFactValue, setEngManFactValue] = useState(null);
    const [transManFactValue, setTransManFactValue] = useState(null);
    const [geatTypeValue, setGeatTypeValue] = useState(null);
    const [apuValue, setApuValue] = useState(null);
    const [inverterValue, setInverterValue] = useState(null);
    const [horsepowerValue, setHorsepowerValue] = useState(null);
    const [colorValue, setColorValue] = useState(null);
    const [bedsizeValue, setBedsizeValue] = useState(null);
    const [bedcountValue, setBedcountValue] = useState(null);
    const [axleratioValue, setAxleratioValue] = useState(null);
    const [wheelbaseValue, setWheelbaseValue] = useState(null);    
    const [trailerlengthValue, setTrailerlengthValue] = useState(null);
    const [gvwrValue, setGvwrValue] = useState(null);
    const [tiretreadsValue, setTiretreadsValue] = useState(null);
    const [braketypeValue, setBraketypeValue] = useState(null);
    const [collisionValue, setCollisionValue] = useState(null);
    const [mitigationsystemValue, setMitigationsystemValue] = useState(null);

    const [rimTypeValue, setRimTypeValue] = useState(null);
    const [eTracksValue, setETracksValue] = useState(null);
    const [rubRailsValue, setRubRailsValue] = useState(null);
    const [airRideValue, setAirRideValue] = useState(null);
    const [scaleValue, setScaleValue] = useState(null);
    const [sideSkirtValue, setSideSkirtValue] = useState(null);
    const [tireRackValue, setTireRackValue] = useState(null);
    const [outstandingloanValue, setOutstandingloanValue] = useState(null);
    const [trailerusageValue, setTrailerusageValue] = useState(null);
    const [majorrepairsValue, setMajorrepairsValue] = useState(null);
    const [purchasesourceValue, setPurchasesourceValue] = useState(null);
    const [sellingpriceValue, setSellingpriceValue] = useState(null);

    const [categoryLists, setCategoryLists] = useState([]);
    const [subcategoryLists, setSubcategoryLists] = useState([]);
    const [makeLists, setMakeLists] = useState([]);
    const [yearLists, setYearLists] = useState([]);
    const [modelLists, setModelLists] = useState([]);
    const [engManFactLists, setEngManFactLists] = useState([]);
    const [transManFactLists, setTransManFactLists] = useState([]);
    const [geatTypeLists, setGeatTypeLists] = useState([{id:'Automatic', label:'Automatic'},{id:'Manual', label:'Manual'}]);
    
    //const [yesNoLists, setYesNoLists] = useState([{id:'Yes', label:'Yes'},{id:'No', label:'No'}]);
    //const [inverterLists, setinverterLists] = useState([{id: true, label:'Yes'},{id:false, label:'No'}]);
    const [horsepowerLists, setHorsepowerLists] = useState([]);
    const [colorLists, setColorLists] = useState([]);
    const [bedsizeLists, setBedsizeLists] = useState([]);
    const [axleratioLists, setAxleratioLists] = useState([]);
    const [wheelbaseLists, setWheelbaseLists] = useState([]);
    const [trailerlengthLists, setTrailerlengthLists] = useState([]);
    const [gvwrLists, setGvwrLists] = useState([]);
    const [tiretreadsLists, setTiretreadsLists] = useState([]);
    const [braketypeLists, setBraketypeLists] = useState([]);
    const [collisionLists, setCollisionLists] = useState([]);
    const [mitigationsystemLists, setMitigationsystemLists] = useState([]);
    const [listingAttachments, setListingAttachments] = useState([]);
    
    

    const { data: categoryList } = FetchCategoriesQuery(productId);
    const { data: subcategoryList } = FetchSubCategoriesQuery(categoryValue);
    const { data: makeList } = FetchMakeListsQuery(subcategoryValue);    
    const { data: modelList } = FetchModelListsQuery(makeValue);
    const { data: yearList } = FetchYearListsQuery(productId);
    const { data: engManFactList } = FetchEngManFactListsQuery(productId);
    const { data: transManFactList } = FetchTransManFactListsQuery(productId);
   
    const { data: horsepowerList } = FetchHorsepowerListsQuery(productId);
    const { data: colorList } = FetchColorListsQuery(productId);
    const { data: bedsizeList } = FetchBedsizeListsQuery(productId);
    const { data: axleratioList } = FetchAxleratioListsQuery(productId);
    const { data: wheelbaseList } = FetchWheelbaseListsQuery(productId);    
    const { data: trailerlengthList } = FetchTrailerlengthListsQuery(productId);
    const { data: gvwrList } = FetchGvwrListsQuery(productId);
    const { data: tiretreadsList } = FetchTiretreadsListsQuery(productId);
    const { data: braketypeList } = FetchBraketypeListsQuery(productId);
    const { data: collisionList } = FetchCollisionListsQuery(productId);
    const { data: mitigationsystemList } = FetchMitigationsystemListsQuery(productId);

    console.log('bedsizeLists',bedsizeLists);

    useEffect(() => {        
        const cat_array = []; 
        if (categoryList?.results) {           
            categoryList?.results?.forEach((cl)=>{                
                cat_array.push({id:cl.id, label:cl.name});
            })            
            setCategoryLists(cat_array);
        }
      }, [categoryList]);
    useEffect(() => {        
    const subcat_array = []; 
    if (subcategoryList?.results) {           
        subcategoryList?.results?.forEach((scl)=>{                
            subcat_array.push({id:scl.id, label:scl.name});
        })            
        setSubcategoryLists(subcat_array);
    }
    }, [subcategoryList]);
    useEffect(() => {        
    const make_array = []; 
    if (makeList?.results) {           
        makeList?.results?.forEach((ml)=>{                
            make_array.push({id:ml.name, label:ml.name});
        })            
        setMakeLists(make_array);
    }
    }, [makeList]);

    useEffect(() => {        
        const y_array = []; 
        if (yearList?.data) {           
            yearList?.data?.forEach((yl)=>{                
                y_array.push({id:yl.year, label:yl.year});
            })            
            setYearLists(y_array);
        }
      }, [yearList]);

    useEffect(() => {        
        const mo_array = []; 
        if (modelList?.results) {           
            modelList?.results?.forEach((mol)=>{                
                mo_array.push({id:mol.name, label:mol.name});
            })            
            setModelLists(mo_array);
        }
      }, [modelList]);
    useEffect(() => {        
        const emf_array = []; 
        if (engManFactList?.results) {           
            engManFactList?.results?.forEach((mol)=>{                
                emf_array.push({id:mol.name, label:mol.name});
            })            
            setEngManFactLists(emf_array);
        }
      }, [engManFactList]);
    useEffect(() => {        
        const tmf_array = []; 
        if (transManFactList?.results) {           
            transManFactList?.results?.forEach((tmfl)=>{                
                tmf_array.push({id:tmfl.name, label:tmfl.name});
            })            
            setTransManFactLists(tmf_array);
        }
    }, [transManFactList]);
    useEffect(() => {        
        const hp_array = []; 
        if (horsepowerList?.results) {           
            horsepowerList?.results?.forEach((hpl)=>{                
                hp_array.push({id:hpl.value, label:hpl.value});
            })            
            setHorsepowerLists(hp_array);
        }
    }, [horsepowerList]);
    useEffect(() => {        
        const color_array = []; 
        if (colorList?.results) {           
            colorList?.results?.forEach((colorl)=>{                
                color_array.push({id:colorl.name, label:colorl.name});
            })            
            setColorLists(color_array);
        }
    }, [colorList]);
    useEffect(() => {        
        const bs_array = []; 
        if (bedsizeList?.results) {           
            bedsizeList?.results?.forEach((bsl)=>{                
                bs_array.push({id:bsl.display_name, label:bsl.display_name});
            })            
            setBedsizeLists(bs_array);
        }
    }, [bedsizeList]);
    useEffect(() => {        
        const ar_array = []; 
        if (axleratioList?.results) {           
            axleratioList?.results?.forEach((arl)=>{                
                ar_array.push({id:arl.ratio, label:arl.ratio});
            })            
            setAxleratioLists(ar_array);
        }
    }, [axleratioList]);
    useEffect(() => {        
        const wb_array = []; 
        if (wheelbaseList?.results) {           
            wheelbaseList?.results?.forEach((wbl)=>{                
                wb_array.push({id:wbl.name, label:wbl.name});
            })            
            setWheelbaseLists(wb_array);
        }
    }, [wheelbaseList]);
    useEffect(() => {        
        const trailer_length_array = []; 
        if (trailerlengthList?.results) {           
            trailerlengthList?.results?.forEach((tl)=>{                
                trailer_length_array.push({id:tl.trailer_length, label:tl.trailer_length});
            })            
            setTrailerlengthLists(trailer_length_array);
        }
    }, [trailerlengthList]);
    useEffect(() => {        
        const gvwr_array = []; 
        if (gvwrList?.results) {           
            gvwrList?.results?.forEach((gvl)=>{                
                gvwr_array.push({id:gvl.name, label:gvl.name});
            })            
            setGvwrLists(gvwr_array);
        }
    }, [gvwrList]);    
    useEffect(() => {        
        const tt_array = []; 
        if (tiretreadsList?.results) {           
            tiretreadsList?.results?.forEach((ttl)=>{                
                tt_array.push({id:ttl.name, label:ttl.name});
            })            
            setTiretreadsLists(tt_array);
        }
    }, [tiretreadsList]);
    useEffect(() => {        
        const bt_array = []; 
        if (braketypeList?.results) {
            braketypeList?.results?.forEach((ttl)=>{
                bt_array.push({id:ttl.name, label:ttl.name});
            })            
            setBraketypeLists(bt_array);
        }
    }, [braketypeList]);
    useEffect(() => {        
        const c_array = []; 
        if (collisionList?.results) {
            collisionList?.results?.forEach((ttl)=>{
                c_array.push({id:ttl.name, label:ttl.name});
            })            
            setCollisionLists(c_array);
        }
    }, [collisionList]);
    useEffect(() => {        
        const ms_array = []; 
        if (mitigationsystemList?.results) {            
            mitigationsystemList?.results?.forEach((msl)=>{
                ms_array.push({id:msl.name, label:msl.name});
            })            
            setMitigationsystemLists(ms_array);
        }
    }, [mitigationsystemList]);

    
    
    //Default values
    const defaultValues ={       
        is_approved: false,
        attachment:[]
    }

    //useForm Hook   
    const {
        register,
        handleSubmit,
        watch: sellerFormData,
        setValue,
        formState: { errors },
        //setFocus,
      } = useForm({ resolver: yupResolver(userValidSchema), defaultValues:defaultValues });

    
    const handleChangeValue = (field, value) => {
        setValue(field, value, { shouldValidate: true });
        if(field==='category'){            
            setCategoryValue(value);
            setSubcategoryValue(null);
            setValue("sub_category", null, { shouldValidate: true });
            setMakeValue(null);
            setValue("vehicle_make", null, { shouldValidate: true });
            setModelValue(null);
            setValue("vehicle_model", null, { shouldValidate: true });
        }
        if(field==='sub_category'){
            setSubcategoryValue(value);
            setMakeValue(null);
            setValue("vehicle_make", null, { shouldValidate: true });
            setModelValue(null);
            setValue("vehicle_model", null, { shouldValidate: true });
        }
        if(field==='vehicle_make'){
            setMakeValue(value);
            setModelValue(null);
            setValue("vehicle_model", null, { shouldValidate: true });
        }        
        if(field==='vehicle_year'){
            setYearValue(value);
        }
        if(field==='vehicle_model'){
            setModelValue(value);
        }
        if(field==='vehicle_engine_manufacturer'){
            setEngManFactValue(value);
        }
        if(field==='vehicle_transmission_manufacturer'){
            setTransManFactValue(value);
        }
        if(field==='vehicle_gear_type'){
            setGeatTypeValue(value);
        }       
        if(field==='vehicle_apu'){
            setApuValue(value);
        }
        if(field==='vehicle_inverter'){
            setInverterValue(value);
        }
        if(field==='vehicle_horsepower'){
            setHorsepowerValue(value);
        }
        if(field==='vehicle_color'){
            setColorValue(value);
        }
        if(field==='vehicle_bed_size'){
            setBedsizeValue(value);
        }
        if(field==='vehicle_bed_count'){
            setBedcountValue(value);
        }        
        if(field==='vehicle_axle_ratio'){
            setAxleratioValue(value);
        }
        if(field==='vehicle_wheel_base'){
            setWheelbaseValue(value);
        }        
        
        if(field==='vehicle_gross_vehicle_weight_rating'){
            setGvwrValue(value);
        }
        if(field==='vehicle_tire_treads'){
            setTiretreadsValue(value);
        }
        if(field==='vehicle_brake_type'){
            setBraketypeValue(value);
        }
        if(field==='vehicle_collision'){
            setCollisionValue(value);
        }
        

        if(field==='vehicle_rim_type'){
            setRimTypeValue(value);
        }
        if(field==='vehicle_e_tracks'){
            setETracksValue(value);
        }
        if(field==='vehicle_rub_rails'){
            setRubRailsValue(value);
        }
        if(field==='vehicle_air_ride'){
            setAirRideValue(value);
        }
        if(field==='vehicle_scale'){
            setScaleValue(value);
        }
        if(field==='vehicle_side_skirt'){
            setSideSkirtValue(value);
        }
        if(field==='vehicle_tire_rack'){
            setTireRackValue(value);
        }
        if(field==='trailer_length'){
            setTrailerlengthValue(value);
        }
        if(field==='vehicle_outstanding_loan'){
            setOutstandingloanValue(value);
        }
        if(field==='vehicle_trailer_usage'){
            setTrailerusageValue(value);
        }
        if(field==='vehicle_major_repairs'){
            setMajorrepairsValue(value);
        }
        if(field==='vehicle_purchase_source'){
            setPurchasesourceValue(value);
        }
        if(field==='selling_price'){
            setSellingpriceValue(usNumberFormat(value));
        }
        
        
    };
    
    const [openListingAddSuccessModel, setOpenListingAddSuccessModel] = useState(false);
    const { mutate: ValidationIsApprovedForm } = HandleIsApprovedData();
    //const { mutate: ValidationStatusForm } = HandleUpdateStatusData();
    const { mutateAsync: ValidationSellerAddForm } = HandleListingAddFormData(sellerFormData);
    const submitSellerForm = (data) => {        
        ValidationSellerAddForm(data).then((respData) => {      
            if(respData?.status_code===201){
                setOpenListingAddSuccessModel(true);
                //ValidationStatusForm({id:respData?.data?.id, status: 'PRODUCT_LISTED', reason: null, description: null})
                ValidationIsApprovedForm({id:respData?.data?.id, status:'PRODUCT_LISTED', is_approved:true});
            }
          });
    };    
    
    const handleChangeImgValue = (valueList) => {
        const atch_array = []; 
        if (valueList) {            
            valueList?.forEach((msl)=>{
                atch_array.push(msl.id);
            })
            setValue(`attachment`, atch_array, { shouldValidate: true });
        }
    };
    
    const handleCancelWindow = () => {
        window.close();
    };
    
    return (  
            <div className='flex'>
                <div className="mx-auto my-8 bg-white rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                    
                    <div className="">
                        <div class="flex justify-between my-4 mb-8">
                            <div class="">
                                &nbsp;
                            </div>
                            <div class="flex items-center">
                                <div className="">
                                    <Button variant="outlined" onClick={handleCancelWindow} className="lightBtn">Cancel</Button>
                                </div>
                                <div className="">
                                    <Button variant="contained" onClick={handleSubmit(submitSellerForm)} className="mainBtn">Save</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <form
                      onSubmit={handleSubmit(submitSellerForm)}
                      id="seller-Add-form"
                      className=''
                    >
                    <div className="white-container">
                        <div className="container-heading">Basic Information</div>
                        
                        <div className="cont25 plr25 mb20">                            
                            <LabelledSelectField
                                label="Category"                                
                                placeholder="Select category"
                                options={categoryLists}
                                value={categoryValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`category`, value);        
                                }}                               
                                inputRef={register("category").ref}
                                error={Boolean(errors.category)}
                                helperText={Boolean(errors.category) && "Category is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">                            
                            <LabelledSelectField
                                label="Sub Category"                                
                                placeholder="Select sub category"
                                options={subcategoryLists}
                                value={subcategoryValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`sub_category`, value);        
                                }}                               
                                inputRef={register("sub_category").ref}
                                error={Boolean(errors.sub_category)}
                                helperText={Boolean(errors.sub_category) && "Sub category is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">                            
                            <LabelledSelectField
                                label="Make"                                
                                placeholder="Select Make"
                                options={makeLists}
                                value={makeValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_make`, value);        
                                }}                               
                                inputRef={register("vehicle_make").ref}
                                error={Boolean(errors.vehicle_make)}
                                helperText={Boolean(errors.vehicle_make) && "Make is a required field" }
                            />
                        </div>
                        
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Model"
                                placeholder="Select Model"
                                options={modelLists}
                                value={modelValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_model`, value);
                                }}                               
                                inputRef={register("vehicle_model").ref}
                                error={Boolean(errors.vehicle_model)}
                                helperText={Boolean(errors.vehicle_model) && "Model is a required field" }
                            />
                        </div>
                        <div className="clearFix"></div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Year"                                
                                placeholder="Select Year"
                                options={yearLists}
                                value={yearValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_year`, value);
                                }}                               
                                inputRef={register("vehicle_year").ref}
                                error={Boolean(errors.vehicle_year)}
                                helperText={Boolean(errors.vehicle_year) && "Year is a required field" }
                            />
                        </div>
                        {categoryValue?.label==='Commercial Truck' && (
                            <>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Engine Manufacturer"
                                    placeholder="Select Engine Manufacturer"
                                    options={engManFactLists}
                                    value={engManFactValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_engine_manufacturer`, value);
                                    }}                               
                                    inputRef={register("vehicle_engine_manufacturer").ref}
                                    error={Boolean(errors.vehicle_engine_manufacturer)}
                                    helperText={Boolean(errors.vehicle_engine_manufacturer) && "Engine Manufacturer is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Transmission Manufacturer"
                                placeholder="Select Transmission Manufacturer"
                                options={transManFactLists}
                                value={transManFactValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_transmission_manufacturer`, value);
                                }}                               
                                inputRef={register("vehicle_transmission_manufacturer").ref}
                                error={Boolean(errors.vehicle_transmission_manufacturer)}
                                helperText={Boolean(errors.vehicle_transmission_manufacturer) && "Transmission Manufacturer is a required field" }
                            />
                        </div>
                        
                        <div className="cont25 plr25 mb20">                            
                            <LabelledSelectField
                                label="Gear Type"
                                placeholder="Select Gear Type"
                                options={geatTypeLists}
                                value={geatTypeValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_gear_type`, value);
                                }}                               
                                inputRef={register("vehicle_gear_type").ref}
                                error={Boolean(errors.vehicle_gear_type)}
                                helperText={Boolean(errors.vehicle_gear_type) && "Gear Type is a required field" }
                            />
                        </div>
                        <div className="clearFix"></div>
                        <div className="cont25 plr25 mb20">                            
                            <LabelledTextField
                                label="Mileage"
                                type="text"
                                placeholder="Enter Mileage"
                                onChange={(e) =>
                                    handleChangeValue("vehicle_mileage", e?.target?.value?.trimStart().replace(/\D/, ''))
                                }
                                maxLength={10}
                                inputRef={register("vehicle_mileage").ref}
                                error={Boolean(errors.vehicle_mileage)}
                                helperText={errors && errors.vehicle_mileage && errors.vehicle_mileage.message}
                                
                            />                            
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="APU"
                                placeholder="Select APU"
                                options={yesNoLists}
                                value={apuValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_apu`, value);
                                }}                               
                                inputRef={register("vehicle_apu").ref}
                                error={Boolean(errors.vehicle_apu)}
                                helperText={Boolean(errors.vehicle_apu) && "APU is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Inverter"
                                placeholder="Select Inverter"
                                options={inverterLists}
                                value={inverterValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_inverter`, value);
                                }}                               
                                inputRef={register("vehicle_inverter").ref}
                                error={Boolean(errors.vehicle_inverter)}
                                helperText={Boolean(errors.vehicle_inverter) && "Inverter is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Horse Power"
                                placeholder="Select Horse Power"
                                options={horsepowerLists}
                                value={horsepowerValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_horsepower`, value);
                                }}                               
                                inputRef={register("vehicle_horsepower").ref}
                                error={Boolean(errors.vehicle_horsepower)}
                                helperText={Boolean(errors.vehicle_horsepower) && "Horse Power is a required field" }
                            />
                        </div>
                        <div className="clearFix"></div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Color"
                                placeholder="Select Color"
                                options={colorLists}
                                value={colorValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_color`, value);
                                }}                               
                                inputRef={register("vehicle_color").ref}
                                error={Boolean(errors.vehicle_color)}
                                helperText={Boolean(errors.vehicle_color) && "Color is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Bed Size"
                                placeholder="Select Bed Size"
                                options={bedsizeLists}
                                value={bedsizeValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_bed_size`, value);
                                }}                               
                                inputRef={register("vehicle_bed_size").ref}
                                error={Boolean(errors.vehicle_bed_size)}
                                helperText={Boolean(errors.vehicle_bed_size) && "Bed Size is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Bed Count"
                                placeholder="Select Bed Count"
                                options={bedcountLists}
                                value={bedcountValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_bed_count`, value);
                                }}                               
                                inputRef={register("vehicle_bed_count").ref}
                                error={Boolean(errors.vehicle_bed_count)}
                                helperText={Boolean(errors.vehicle_bed_count) && "Bed count is a required field" }
                            />
                            
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Axle"
                                placeholder="Select Axle"
                                options={axleratioLists}
                                value={axleratioValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_axle_ratio`, value);
                                }}
                                inputRef={register("vehicle_axle_ratio").ref}
                                error={Boolean(errors.vehicle_axle_ratio)}
                                helperText={Boolean(errors.vehicle_axle_ratio) && "Axle is a required field" }
                            />
                        </div>
                        <div className="clearFix"></div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Wheel Base"
                                placeholder="Select Wheel Base"
                                options={wheelbaseLists}
                                value={wheelbaseValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_wheel_base`, value);
                                }}                               
                                inputRef={register("vehicle_wheel_base").ref}
                                error={Boolean(errors.vehicle_wheel_base)}
                                helperText={Boolean(errors.vehicle_wheel_base) && "Wheel Base is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="GVWR"
                                placeholder="Select GVWR"
                                options={gvwrLists}
                                value={gvwrValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_gross_vehicle_weight_rating`, value);
                                }}                               
                                inputRef={register("vehicle_gross_vehicle_weight_rating").ref}
                                error={Boolean(errors.vehicle_gross_vehicle_weight_rating)}
                                helperText={Boolean(errors.vehicle_gross_vehicle_weight_rating) && "GVWR is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Tire Tread "
                                placeholder="Select Tire Tread "
                                options={tiretreadsLists}
                                value={tiretreadsValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_tire_treads`, value);
                                }}
                                inputRef={register("vehicle_tire_treads").ref}
                                error={Boolean(errors.vehicle_tire_treads)}
                                helperText={Boolean(errors.vehicle_tire_treads) && "Tire Tread  is a required field" }
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Break Type "
                                placeholder="Select Break Type"
                                options={braketypeLists}
                                value={braketypeValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_brake_type`, value);
                                }}                               
                                inputRef={register("vehicle_brake_type").ref}
                                error={Boolean(errors.vehicle_brake_type)}
                                helperText={Boolean(errors.vehicle_brake_type) && "Break Type is a required field" }
                            />
                        </div>
                        <div className="clearFix"></div>
                        <div className="cont25 plr25 mb20">
                            <LabelledSelectField
                                label="Collision Mitigation System"
                                placeholder="Select Collision Mitigation System"
                                options={collisionLists}
                                value={collisionValue}
                                onChange={(event, value) => { 
                                    handleChangeValue(`vehicle_collision`, value);
                                }}                               
                                inputRef={register("vehicle_collision").ref}
                                error={Boolean(errors.vehicle_collision)}
                                helperText={Boolean(errors.vehicle_collision) && "Collision is a required field" }
                            />
                        </div>
                        </>
                        )}
                        {categoryValue?.label!=='Commercial Truck' && (
                            <>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Rim Type"
                                    placeholder="Select Rim Type"
                                    options={rimLists}
                                    value={rimTypeValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_rim_type`, value);
                                    }}                               
                                    inputRef={register("vehicle_rim_type").ref}
                                    error={Boolean(errors.vehicle_rim_type)}
                                    helperText={Boolean(errors.vehicle_rim_type) && "Rim type is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledTextField
                                    label="Hours"
                                    type="text"
                                    placeholder="Enter Hours"
                                    onChange={(e) =>
                                        handleChangeValue("hour", e?.target?.value?.trimStart().replace(/\D/, ''))
                                    }
                                    maxLength={10}
                                    inputRef={register("hour").ref}
                                    error={Boolean(errors.hour)}
                                    helperText={errors && errors.hour && errors.hour.message}                                    
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="E Tracks"
                                    placeholder="Select E Tracks"
                                    options={yesNoLists}
                                    value={eTracksValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_e_tracks`, value);
                                    }}                               
                                    inputRef={register("vehicle_e_tracks").ref}
                                    error={Boolean(errors.vehicle_e_tracks)}
                                    helperText={Boolean(errors.vehicle_e_tracks) && "E Tracks is a required field" }
                                />
                            </div>
                            <div className="clearFix"></div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Rub Rails"
                                    placeholder="Select Rub Rails"
                                    options={yesNoLists}
                                    value={rubRailsValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_rub_rails`, value);
                                    }}                               
                                    inputRef={register("vehicle_rub_rails").ref}
                                    error={Boolean(errors.vehicle_rub_rails)}
                                    helperText={Boolean(errors.vehicle_rub_rails) && "Rub Rails is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Air-Ride"
                                    placeholder="Select Air-Ride"
                                    options={yesNoLists}
                                    value={airRideValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_air_ride`, value);
                                    }}                               
                                    inputRef={register("vehicle_air_ride").ref}
                                    error={Boolean(errors.vehicle_air_ride)}
                                    helperText={Boolean(errors.vehicle_air_ride) && "Air-Ride is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Scale"
                                    placeholder="Select Scale"
                                    options={yesNoLists}
                                    value={scaleValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_scale`, value);
                                    }}                               
                                    inputRef={register("vehicle_scale").ref}
                                    error={Boolean(errors.vehicle_scale)}
                                    helperText={Boolean(errors.vehicle_scale) && "Scale is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Side Skirts"
                                    placeholder="Select Side Skirts"
                                    options={yesNoLists}
                                    value={sideSkirtValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_side_skirt`, value);
                                    }}                               
                                    inputRef={register("vehicle_side_skirt").ref}
                                    error={Boolean(errors.vehicle_side_skirt)}
                                    helperText={Boolean(errors.vehicle_side_skirt) && "Side Skirts is a required field" }
                                />
                            </div>
                            <div className="clearFix"></div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Color"
                                    placeholder="Select Color"
                                    options={colorLists}
                                    value={colorValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_color`, value);
                                    }}                               
                                    inputRef={register("vehicle_color").ref}
                                    error={Boolean(errors.vehicle_color)}
                                    helperText={Boolean(errors.vehicle_color) && "Color is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Tire Rack"
                                    placeholder="Select Tire Rack"
                                    options={yesNoLists}
                                    value={tireRackValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_tire_rack`, value);
                                    }}                               
                                    inputRef={register("vehicle_tire_rack").ref}
                                    error={Boolean(errors.vehicle_tire_rack)}
                                    helperText={Boolean(errors.vehicle_tire_rack) && "Tire Rack is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Trailer length"
                                    placeholder="Select Trailer length"
                                    options={trailerlengthLists}
                                    value={trailerlengthValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`trailer_length`, value);
                                    }}                               
                                    inputRef={register("trailer_length").ref}
                                    error={Boolean(errors.trailer_length)}
                                    helperText={Boolean(errors.trailer_length) && "Trailer length is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="GVWR"
                                    placeholder="Select GVWR"
                                    options={gvwrLists}
                                    value={gvwrValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_gross_vehicle_weight_rating`, value);
                                    }}                               
                                    inputRef={register("vehicle_gross_vehicle_weight_rating").ref}
                                    error={Boolean(errors.vehicle_gross_vehicle_weight_rating)}
                                    helperText={Boolean(errors.vehicle_gross_vehicle_weight_rating) && "GVWR is a required field" }
                                />
                            </div>
                            <div className="clearFix"></div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Axle"
                                    placeholder="Select Axle"
                                    options={axleratioLists}
                                    value={axleratioValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_axle_ratio`, value);
                                    }}
                                    inputRef={register("vehicle_axle_ratio").ref}
                                    error={Boolean(errors.vehicle_axle_ratio)}
                                    helperText={Boolean(errors.vehicle_axle_ratio) && "Axle is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Outstanding loan"
                                    placeholder="Select Outstanding loan"
                                    options={yesNoDontLists}
                                    value={outstandingloanValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_outstanding_loan`, value);
                                    }}
                                    inputRef={register("vehicle_outstanding_loan").ref}
                                    error={Boolean(errors.vehicle_outstanding_loan)}
                                    helperText={Boolean(errors.vehicle_outstanding_loan) && "Outstanding loan is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Tire Tread "
                                    placeholder="Select Tire Tread "
                                    options={tiretreadsLists}
                                    value={tiretreadsValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_tire_treads`, value);
                                    }}
                                    inputRef={register("vehicle_tire_treads").ref}
                                    error={Boolean(errors.vehicle_tire_treads)}
                                    helperText={Boolean(errors.vehicle_tire_treads) && "Tire Tread  is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Trailer usage"
                                    placeholder="Select Trailer usage"
                                    options={trailerusageLists}
                                    value={trailerusageValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_trailer_usage`, value);
                                    }}
                                    inputRef={register("vehicle_trailer_usage").ref}
                                    error={Boolean(errors.vehicle_trailer_usage)}
                                    helperText={Boolean(errors.vehicle_trailer_usage) && "Trailer usage  is a required field" }
                                />
                            </div>
                            <div className="clearFix"></div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Major repair "
                                    placeholder="Select Major repair "
                                    options={yesNoDontLists}
                                    value={majorrepairsValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_major_repairs`, value);
                                    }}
                                    inputRef={register("vehicle_major_repairs").ref}
                                    error={Boolean(errors.vehicle_major_repairs)}
                                    helperText={Boolean(errors.vehicle_major_repairs) && "Major repair   is a required field" }
                                />
                            </div>
                            <div className="cont25 plr25 mb20">
                                <LabelledSelectField
                                    label="Purchases"
                                    placeholder="SelectPurchases"
                                    options={purchasesourceLists}
                                    value={purchasesourceValue}
                                    onChange={(event, value) => { 
                                        handleChangeValue(`vehicle_purchase_source`, value);
                                    }}
                                    inputRef={register("vehicle_purchase_source").ref}
                                    error={Boolean(errors.vehicle_purchase_source)}
                                    helperText={Boolean(errors.vehicle_purchase_source) && "Purchases is a required field" }
                                />
                            </div>
                            
                            </>
                        )}
                        <div className="cont25 plr25 mb20">
                            <LabelledPriceTextField
                                label="Price"
                                type="text"
                                placeholder="Enter Price"
                                value={sellingpriceValue}
                                onChange={(e) =>
                                    handleChangeValue("selling_price", e?.target?.value?.trimStart().replace(/\D/, ''))
                                  }
                                maxLength={10}
                                inputRef={register("selling_price").ref}
                                error={Boolean(errors.selling_price)}
                                helperText={errors && errors.selling_price && errors.selling_price.message}
                                
                            />
                        </div>
                        <div className="clearFix"></div>
                        
                        
                    </div>
                    <div className="gray-container">
                        <div className="container-heading">Address</div>
                        
                        <div className="cont25 plr25 mb20">
                            <LabelledTextField
                                label="Apartment, Suite"
                                type="text"
                                placeholder="Enter Apartment, Suite"
                                onChange={(e) =>
                                    handleChangeValue("street_address", e?.target?.value)
                                  }
                                inputRef={register("street_address").ref}
                                error={Boolean(errors.street_address)}
                                helperText={errors && errors.street_address && errors.street_address.message}
                                
                            />
                        </div>                        
                        <div className="cont25 plr25 mb20">
                            <LabelledTextField
                                label="City"
                                type="text"
                                placeholder="Enter City"
                                onChange={(e) =>
                                    handleChangeValue("city", e?.target?.value)
                                  }
                                inputRef={register("city").ref}
                                error={Boolean(errors.city)}
                                helperText={errors && errors.city && errors.city.message}
                                
                                
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledTextField
                                label="State"
                                type="text"
                                placeholder="Enter State"
                                onChange={(e) =>
                                    handleChangeValue("state", e?.target?.value)
                                  }
                                inputRef={register("state").ref}
                                error={Boolean(errors.state)}
                                helperText={errors && errors.state && errors.state.message}                               
                               
                            />
                        </div>
                        <div className="cont25 plr25 mb20">
                            <LabelledTextField
                                label="ZIP/Postal Code"
                                type="text"
                                placeholder="Enter ZIP/Postal Code"
                                onChange={(e) =>
                                    handleChangeValue("zip_code", e?.target?.value?.trimStart().replace(/\D/, ''))
                                  }
                                maxLength={5}
                                inputRef={register("zip_code").ref}
                                error={Boolean(errors.zip_code)}
                                helperText={errors && errors.zip_code && errors.zip_code.message}                              
                                
                            />
                        </div>                    
                    </div>
                   
                    <div className="gray-container">                        
                        <div className="cont50 plr25 mb20">
                            <LabelledTextField
                                label="Description"
                                type="textarea"
                                placeholder="Enter Description"
                                multiline="true"
                                rows = "4"
                                // maxChar='1000'
                                onChange={(e) =>
                                    handleChangeValue("description", e?.target?.value)
                                  }
                                inputRef={register("description").ref}
                                error={Boolean(errors.description)}
                                helperText={errors && errors.description && errors.description.message}
                            />
                        </div>
                        <div className="cont50 plr25 mb20">                           
                            
                        </div>
                    </div>
                    <div className="gray-container">
                        <div className="container-heading">Photos & Video</div>
                        <div className="">
                            {Boolean(errors.attachment) && (
                                <div className="validationError">One Photo is a required</div>
                            )}
                            <ListingPhoto
                                listingid={productId}
                                listingAttachmentsData={listingAttachments}
                                handleChangeImgValue={handleChangeImgValue}                        
                            />
                            
                        </div>
                        
                    </div>
                    
                    <div className="blanck-container itemsCenter">
                        <Button variant="outlined" onClick={handleCancelWindow} className="lightBtn">Cancel</Button>
                        <Button type="submit" className="mainBtn">Save</Button>
                    </div>
                    
                    
                    </form>
                    <MsgSuccessModel
                        open={Boolean(openListingAddSuccessModel)}
                        headingText="Product has been successfully save"
                        lineText="The product has been successfully saved. if you want to view the listing please go back to the list."
                        handleOk={handleCancelWindow}
                    />
                    
                </div>
            </div>       
    );
}

export default SellerListingAddnew;