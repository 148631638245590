import React, { useMemo, useEffect,useState } from "react";
import "./FinancePhoto.css";
import Imagediv  from "./imagediv";
import ViewPhotoModel  from "./viewPhotoModel";




const FinancePhoto = ({data}) => {
  
  const [openPhotoModel, setOpenPhotoModel] = useState(false);
  const [photoModelData, setPhotoModelData] = useState(null);
  
  const handleViewImgFile = (id,name,url) => {       
      setOpenPhotoModel(true);
      setPhotoModelData({"name":name,"url":url})
  };  
  const handleCancel = () => {
    setOpenPhotoModel(false);
  };   
  
  
  return (
      <div className="fPhoto">        
        <Imagediv
          data={data}
          imgType='VIN_STICKER'
          imgName='VIN Sticker'
          handleViewImgFile={handleViewImgFile}
        />
      
        <Imagediv
          data={data}
          imgType='ENGINE_STICKER'
          imgName='Engine Sticker'          
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='ENGINE'
          imgName='Engine'          
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='FRONT'
          imgName='Front'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='BACK'
          imgName='Back'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='LEFT'
          imgName='Left'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='RIGHT'
          imgName='Right'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='TIRES'
          imgName='Tires'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='LEFT_DRIVE_TYRE'
          imgName='Left Drive Tire'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='RIGHT_DRIVE_TYRE'
          imgName='Right Drive Tire'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='LEFT_REAR_TYRE'
          imgName='Left Rear Tire'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='RIGHT_REAR_TYRE'
          imgName='Right Rear Tire'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='ODOMETER'
          imgName='ODO Meter'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='FULL_DASH'
          imgName='Full Dash'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='SLEEPER_AREA'
          imgName='Sleeper Area'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='SPECIFICATION_SHEET'
          imgName='Specification Sheet'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='ENGINE_FAMILY_NUMBER'
          imgName='Engine Family Number'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='REEFER_SERIAL_NUMBER'
          imgName='Reefer Serial Number'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='HOURS_METER'
          imgName='Hours Meter'
          handleViewImgFile={handleViewImgFile}
        />
        <Imagediv
          data={data}
          imgType='INSIDE_TRAILER'
          imgName='Inside Trailer'
          handleViewImgFile={handleViewImgFile}
        />   
      
        <div className="clearFix"></div>
        <ViewPhotoModel
            open={Boolean(openPhotoModel)}
            data={photoModelData}
            handleCancel={handleCancel}
        />

      </div>
  );
};

export default FinancePhoto;
