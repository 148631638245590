import React, { useEffect, useMemo, useState } from 'react';
import ReactTableCustom from 'components/ReactTableCustom';
import FullPageLoadingModel  from "components/FullPageLoadingModel";
import { FetchTableListQuery, FetchCategoriesQuery } from './Queries';
import { format } from 'date-fns';
import {
  currencyFormat,
  mobileFormat,
  classForInquiryListingStatus,
  classForDealsMode,
} from 'utils';
import RouteConstants from 'routes/RouteConstants';

const InquiryListing = () => {
  const [fetchInquiryListPayload, setFetchInquiryListPayload] = useState(null);
  const { data: inquiryList, isLoading } = FetchTableListQuery(fetchInquiryListPayload);
  const data = useMemo(() => inquiryList?.results || [], [inquiryList]);
  const [filterData, setFilterData] = useState({ category: [] });

  const { data: inqCategories, refetch } = FetchCategoriesQuery();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const cat_array = [];
    if (inqCategories?.results) {
      inqCategories?.results?.forEach((cr) => {
        cat_array.push(cr.name);
      });
      const extraFilters = {
        category: cat_array,
      };
      setFilterData(extraFilters);
    }
  }, [inqCategories]);

  const columns = useMemo(
    () => [
      {
        Header: 'Client ID',
        accessor: 'user__id',
        width: '100px',
        Cell: ({ row: { original } }) => {
          return <p className="fw600 greycolor">{original?.user?.id}</p>;
        },
      },
      {
        Header: 'Stock',
        accessor: 'listing__unique_id',
        width: '100px',
        Cell: ({ row: { original } }) => {
          return (
            <p className="fw600 greycolor underline cursorpointer" onClick={() => {
              const id = original.listing_obj?.id;
              window.open(
                `${RouteConstants.INQUIRY_DETAIL}?listingId=${id}`,
                '_blank'
              );
            }}>
              {original?.listing_obj.unique_id}
            </p>
          );
        },
      },
      {
        Header: 'Category',
        accessor: 'listing__category__name',
        width: '90px',
        Cell: ({ row: { original } }) => {
          return <p>{original?.listing_obj?.category__name}</p>;
        },
      },

      {
        Header: 'Name',
        accessor: 'user__name',
        width: '120px',
        Cell: ({ row: { original } }) => {
          return <p>{original?.user?.name}</p>;
        },
      },
      {
        Header: 'Contact',
        accessor: 'user__phone',
        width: '200px',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <p>
                {original && original?.user?.phone
                  ? mobileFormat(original.user.phone)
                  : null}
              </p>
              <p className="font10">{original?.user?.email}</p>
            </div>
          );
        },
      },
      {
        Header: 'Date',
        accessor: 'created_at',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <p>
                {original && original.created_at
                  ? format(new Date(original.created_at), 'MMM dd, yyyy')
                  : null}
              </p>
              <p className="font10 fw400 grey60color">
                {original && original.created_at
                  ? format(new Date(original.created_at), 'hh:mm b')
                  : null}
              </p>
            </div>
          );
        },
      },
      {
        Header: 'mode',
        accessor: 'mode',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return classForDealsMode(original?.mode);
        },
      },
      {
        Header: 'Price',
        accessor: 'listing__price',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return (
            <p className="fw700 maincolor">
              {original && original.listing_obj.price
                ? currencyFormat(original.listing_obj.price)
                : null}
            </p>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '150px',
        Cell: ({ row: { original } }) => {
          return classForInquiryListingStatus(original?.status);
        },
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div className="text-center">
            <button
              className="tableActionsBtn"
              onClick={() => {
                const id = row.original.listing_obj?.id;
                window.open(
                  `${RouteConstants.INQUIRY_DETAIL}?listingId=${id}`,
                  '_blank'
                );
              }}
            >
              <span>
                <img src="/assets/actions-icon.png" alt="Actions" />
              </span>
            </button>
          </div>
        ),
        width: '90px',
        disableSortBy: true,
        disableExport: true,
      },
    ],
    []
  );

  const [search, setSearch] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownPageSize, setDropdownPageSize] = useState(30);
  const [sorts, setSorts] = useState([
    {
      id: 'id',
      desc: false,
    },
  ]);
  const setDropdownPageSizeHandler = (count) => {
    setCurrentPage(1);
    setDropdownPageSize(count);
  };
  const pageChangeHandler = (pageNo) => {
    setCurrentPage(pageNo);
  };
  const setSearchHandler = (searchKeyword) => {
    setSearch(searchKeyword);
  };
  const setDateHandler = (value) => {
    if (typeof value == 'object' && value != null) {
      const from_date = format(new Date(value[0]), 'yyyy-MM-dd');
      const to_date = format(new Date(value[1]), 'yyyy-MM-dd');
      setFromDate(from_date);
      setToDate(to_date);
    }
  };
  const setSortsHandler = (col) => {
    setSorts(col);
  };
  /**********  Filter ***********/
  const [filterValues, setFilterValues] = useState([]);
  const [filterFilter, setFilterFilter] = useState([]);
  const [filterBtnValue, setFilterBtnValue] = useState([]);

  const setFilterBtnHandler = (value) => {
    if (typeof value == 'object' && value != null) {
      setFilterBtnValue(value);
    }
  };

  useEffect(() => {
    setFetchInquiryListPayload({
      page: 1,
      per_page: dropdownPageSize,
      search,
      sorts,
      from_date: fromDate,
      to_date: toDate,
      filter_value: filterBtnValue,
    });
  }, [dropdownPageSize]);

  useEffect(() => {
    setFetchInquiryListPayload({
      page: currentPage,
      per_page: dropdownPageSize,
      search,
      sorts,
      from_date: fromDate,
      to_date: toDate,
      filter_value: filterBtnValue,
    });
  }, [currentPage]);

  useEffect(() => {
    if (search || search === '') {
      setCurrentPage(1);
      setFetchInquiryListPayload({
        page: 1,
        per_page: dropdownPageSize,
        search,
        sorts,
        from_date: fromDate,
        to_date: toDate,
        filter_value: filterBtnValue,
      });
    }
  }, [search]);

  useEffect(() => {
    if (sorts) {
      setFetchInquiryListPayload({
        page: currentPage,
        per_page: dropdownPageSize,
        search,
        sorts,
        from_date: fromDate,
        to_date: toDate,
        filter_value: filterBtnValue,
      });
    }
  }, [sorts]);

  useEffect(() => {
    setFetchInquiryListPayload({
      page: currentPage,
      per_page: dropdownPageSize,
      search,
      sorts,
      from_date: fromDate,
      to_date: toDate,
      filter_value: filterBtnValue,
    });
  }, [fromDate, toDate]);

  useEffect(() => {
    setFetchInquiryListPayload({
      page: currentPage,
      per_page: dropdownPageSize,
      search,
      sorts,
      from_date: fromDate,
      to_date: toDate,
      filter_value: filterBtnValue,
    });
  }, [filterBtnValue]);
  return (
    <div>
      <div className="flex">
      <div className="mx-auto my-8 bg-white px-8 py-4 rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
          <FullPageLoadingModel
            open={Boolean(isLoading)}
          />
          <ReactTableCustom
            dropdownPageSize={dropdownPageSize}
            setDropdownPageSize={setDropdownPageSizeHandler}
            currentPage={currentPage}
            pageChangeHandler={pageChangeHandler}
            data={data}
            columns={columns}
            filterData={filterData}
            paginationProps={inquiryList?.pagination_option}
            setSearchHandler={setSearchHandler}
            setDateHandler={setDateHandler}
            setFiltersHandler={setSortsHandler}
            appliedFilters={filterValues}
            setAppliedFilters={setFilterValues}
            filterFilter={filterFilter}
            setFilterFilter={setFilterFilter}
            setFilterBtnHandler={setFilterBtnHandler}
            placeholder={'Sellar Listing'}
            initialStateSortBy={sorts}
            showPaginationBottom={true}
            columnSearchable="false"
            disableCheckboxSelection="true"
          />
        </div>
      </div>
    </div>
  );
};

export default InquiryListing;
