import React, { useMemo, useEffect,useState } from "react";
import {
  Grid,
  IconButton,
  InputBase,
  Skeleton,
  Typography,
} from '@mui/material';

import  "./ListingViewSection.css";






const DetailsSkeleton = () => {
  return (
    <>
      {new Array(14).fill(1).map((el, idx) => (
        <Grid item xs={2.8} key={Math.random() * idx}>
          <Skeleton variant="rounded" />
          <Skeleton variant="rounded" sx={{ mt: 1 }} />
        </Grid>
      ))}
    </>
  );
};
const NumberOfPropsToBeShown = 20;

const ListingViewSection = ({data}) => {  
 
  const [NumberOfProps, setNumberOfProps] = useState(NumberOfPropsToBeShown);

  const imgSrc = useMemo(
    () => data?.attachments?.find((el) => el.is_primary)?.attachment,
    [data]
  );
  
  return (
    <div className="">
      <Grid container gap={4}>
          <Grid item xs={2} textAlign="center">
              {imgSrc ? (
              <img alt="Product Img" className="listingImg" src={imgSrc} />
              ) : (
              <Skeleton variant="rounded" height="100%" />
              )}
          </Grid>
          <Grid item container xs={9.6} rowGap={4} gap={2}>
            {!data && <DetailsSkeleton />}
            {data && (
              <Grid item xs={2.8}>
                <Typography                  
                  textTransform="capitalize"
                  className="lableText"
                >
                  Stock
                </Typography>
                <Typography mt={1} className="valueText">
                  {data.unique_id}
                </Typography>
              </Grid>
            )}
            {Object.entries(data?.vehicle ?? {})
              ?.slice(0, NumberOfProps)
              ?.map((item, key) => {
                if (item[0] === 'id') return null;
                return (
                  <Grid item xs={2.8} key={Math.random() * key}>
                    <Typography
                      textTransform="capitalize"
                      className="lableText"
                    >
                      {item[0].split('_').join(' ')}
                    </Typography>
                    <Typography mt={1} className="valueText">
                      {item[1]?  item[1] : "N/A"}
                    </Typography>
                  </Grid>
                );
              })}
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="#0F5E91"
                className="hover:cursor-pointer w-fit"
                onClick={() => {
                  NumberOfProps
                    ? setNumberOfProps(undefined)
                    : setNumberOfProps(NumberOfPropsToBeShown);
                }}
              >
                {NumberOfProps ? 'Show more' : 'Show less'}
              </Typography>
            </Grid>
          </Grid>
      </Grid>

    </div>
  );
};

export default ListingViewSection;
