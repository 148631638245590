import React, { useMemo } from "react";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import TopDeals  from "./topDeals";
import PastInquiry  from "./pastInquiry";
import InProgressGraph  from "./inProgressGraph";
import { FetchInventoryQuery,FetchUserQuery,FetchInquiryQuery,FetchFinancingQuery} from "./Queries";
import  "./dashboard.css";

const user_details = JSON.parse(localStorage.getItem('user_details'));

const Dashboard = () => {
    const { data: inventory } = FetchInventoryQuery('GET');
    const inv_data = useMemo(() => inventory?.data || [], [inventory]);

    const { data: user } = FetchUserQuery('GET');
    const user_data = useMemo(() => user?.data || [], [user]);

    const { data: inquiry } = FetchInquiryQuery('GET');
    const inquiry_data = useMemo(() => inquiry?.data || [], [inquiry]);

    const { data: financing } = FetchFinancingQuery('GET');
    const financing_data = useMemo(() => financing?.data || [], [financing]);  

    const handleViewAllInventory = () => {
        window.location = "/inventory";
    };
    const handleViewAllUser = () => {
        window.location = "/users";
    };
    const handleViewAllSl = () => {
        window.location = "/inquiry";
    };
    const handleViewAllFinancing = () => {
        window.location = "/finance-request";
    };
    
    return (        
        <div>
            <div className='flex'>
            <div className="mx-auto my-8 bg-white px-8 py-4 rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                <div className="cont100 mb50">
                    <div className="userWel">Welcome back, {user_details?.name}</div>
                    <div className="userWelText">
                        Track, manage and forecast your customers and orders
                    </div>
                </div>
                <div className="cont25 mb20 plr25">
                    <div className="white-container contpad">
                        <div className="wgtitle" onClick={handleViewAllInventory}><FeedOutlinedIcon />&nbsp;&nbsp;Inventory</div>
                        <div className="wgbody">
                            <div className="wgtotal">
                                <div>Total</div>
                                <div className="tlright">{inv_data?.total}</div>
                            </div>
                            <div className="wgline">
                                <div>Trucks</div>
                                <div className="tlright">{inv_data?.trucks}</div>
                            </div>
                            <div className="wgline">
                                <div>Trailers</div>
                                <div className="tlright">{inv_data?.trailers}</div>
                            </div>
                            <div className="wgline">
                                <div>Vans</div>
                                <div className="tlright">{inv_data?.vans}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cont25 mb20 plr25">
                    <div className="white-container contpad">
                        <div className="wgtitle" onClick={handleViewAllUser}><PersonOutlineIcon />&nbsp;&nbsp;User's</div>
                        <div className="wgbody">
                            <div className="wgtotal">
                                <div>Total</div>
                                <div className="tlright">{user_data?.total_users}</div>
                            </div>                            
                            <div className="wgBgline">
                                <div className="wgBgtextL">
                                    <div>P/D</div>
                                    <div className="tlright">{user_data?.per_day_increase}%</div>
                                </div>
                                <div className="wgBgtextR">
                                    <div>P/W</div>
                                    <div className="tlright">{user_data?.per_week_increase}%</div>
                                </div>
                            </div>
                            <div className="wgBgline">
                                <div className="wgBgtextL">
                                    <div>P/M</div>
                                    <div className="tlright">{user_data?.per_month_increase}%</div>
                                </div>
                                <div className="wgBgtextR">
                                    <div>P/Q</div>
                                    <div className="tlright">{user_data?.per_quarter_increase}%</div>
                                </div>
                            </div>
                            <div className="wgBgline" style={{width: "43%"}}>                                
                                <div>P/Y</div>
                                <div className="tlright">{user_data?.per_year_increase}%</div>                                                           
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="cont25 mb20 plr25">
                    <div className="white-container contpad">
                        <div className="wgtitle" onClick={handleViewAllSl}><MarkUnreadChatAltIcon />&nbsp;&nbsp;Sales Inquiry's</div>
                        <div className="wgbody">
                            <div className="wgtotal">
                                <div>Total</div>
                                <div className="tlright">{inquiry_data?.total_inquiries}</div>
                            </div>
                            <div className="wgBgline">
                                <div className="wgBgtextL">
                                    <div>P/D</div>
                                    <div className="tlright">{inquiry_data?.per_day}</div>
                                </div>
                                <div className="wgBgtextR">
                                    <div>P/M</div>
                                    <div className="tlright">{inquiry_data?.per_month}</div>
                                </div>
                            </div>
                            <div className="wgline">
                                <div>Trucks</div>
                                <div className="tlright">{inquiry_data?.trucks}</div>
                            </div>
                            <div className="wgline">
                                <div>Trailers</div>
                                <div className="tlright">{inquiry_data?.trailers}</div>
                            </div>
                            <div className="wgline" style={{margin:'0'}}>
                                <div>Vans</div>
                                <div className="tlright">{inquiry_data?.vans}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cont25 mb20 plr25">
                    <div className="white-container contpad">
                        <div className="wgtitle" onClick={handleViewAllFinancing}><AccountBalanceOutlinedIcon />&nbsp;&nbsp;Financing</div>
                        <div className="wgbody">
                            <div className="wgtotal">
                                <div>Total</div>
                                <div className="tlright">{financing_data?.total_finance_count}</div>
                            </div>
                            <div className="wgline">
                                <div>With SMR</div>
                                <div className="tlright">{financing_data?.smr_count}</div>
                            </div>
                            <div className="wgline">
                                <div>With Non SMR</div>
                                <div className="tlright">{financing_data?.non_smr_count}</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="clearFix"></div>
                <div className="cont50 mb20 plr25">
                    <div className="white-container">                  
                        <InProgressGraph />
                    </div>
                </div>
                <div className="cont50 mb20 plr25">
                    <div className="white-container conttablepad">
                        <TopDeals />
                    </div>
                    <div className="white-container conttablepad">
                        <PastInquiry />
                    </div>
                </div>
                <div className="clearFix"></div>
            </div>
            </div>
        </div>
    );
}
export default Dashboard;