// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAVp8pqy2B_H2FNC-6fJgaga0pA6e9QeG4',
  authDomain: 'smr1-3aef0.firebaseapp.com',
  projectId: 'smr1-3aef0',
  storageBucket: 'smr1-3aef0.appspot.com',
  messagingSenderId: '355206534797',
  appId: '1:355206534797:web:9143487d44743a2eec34c4',
  measurementId: 'G-LECLBSZF5K'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;


export const getToken = async () => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });    
  } catch (error) {
    console.log("FCM Token Error - ", error);
  }
  return currentToken;
};

export const onMessageListener = (callback) => {
  console.log("AAAAAAAAAAAAAAAAMMMMMMA");
  new Promise(resolve => {
    messaging.onMessage(payload => {
      console.log("BBBBBBBCCCCCCBBBBBBBBBBBBBBBBBBB");
      resolve(payload);
      callback(payload);
    });
  });
}