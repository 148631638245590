import React, { useEffect, useMemo, useState } from "react";
import ReactTableCustom from "components/ReactTableCustom";
import FullPageLoadingModel  from "components/FullPageLoadingModel";
import { FetchTableListQuery } from "./Queries";
import TransactionModel  from "../Transaction/TransactionModel";
import { format } from "date-fns";
import { currencyFormat,mobileFormat,classForTransactionListingStatus,classForTransactionType } from "utils";

const TransactionListing = () => {   
    const [fetchTransactionListPayload, setFetchTransactionListPayload] = useState(null);   
    const { data: transactionList, isLoading} = FetchTableListQuery(fetchTransactionListPayload);
    const data = useMemo(() => transactionList?.results || [], [transactionList]);
    const [filterData, setFilterData] = useState({'status':['Successful','Failed','Pending','Canceled','Refund']});
    
    const columns = useMemo(
        () => [
            
          {
            Header: "Transaction ID",
            accessor: "transaction_number",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p className="fw600 maincolor">{original?.transaction_number}</p>
                )
              },
          },
          {
            Header: "Account",
            accessor: "debited_from",
            width: "100px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p className="fw600 greycolor">{original?.debited_from}</p>
                )
              },
          },
          {
            Header: "Stock",
            accessor: "listing__unique_id",
            width: "100px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p onClick={() => {
                        handleViewItem(original);
                      }} className="fw600 greycolor underline cursorpointer">{original?.listing_unique_id}</p>
                )
              },
          },
          {
            Header: "Name",
            accessor: "debited_from__name",          
            width: "120px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.debited_from_name}</p>
                )
              },
          },
          {
            Header: "Contact",
            accessor: "debited_from__phone",            
            width: "200px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>                        
                        <p>{original && original?.debited_from_phone ? mobileFormat(original.debited_from_phone) : null}</p>                   
                        <p className="font10">{original?.debited_from_email}</p>
                    </div>
                    
                )
              },
          },
          {
            Header: "Date",
            accessor: "created_at",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <>
                    {original?.refund ? (
                        <div>
                            <p>{original && original.refund?.created ? format(new Date(original.refund.created), "MMM dd, yyyy") : null}</p>
                            <p className="font10 fw400 grey60color">{original && original.refund?.created ? format(new Date(original.refund?.created), "hh:mm b") : null}</p>
                        </div>
                    ): (
                        <div>
                            <p>{original && original.charge?.created ? format(new Date(original.charge.created), "MMM dd, yyyy") : null}</p>
                            <p className="font10 fw400 grey60color">{original && original.charge?.created ? format(new Date(original.charge?.created), "hh:mm b") : null}</p>
                        </div>
                    )}
                    </>
                )
            },           
          },   
          {
            Header: "Type",
            accessor: "transaction_for",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    original?.refund ? classForTransactionType('Quotes Refund') : classForTransactionType(original?.transaction_for)
                )
              },
          },
          {
            Header: "Amount",
            accessor: "amount",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return (
                    <p className={`fw700 ${original?.refund ? "redColour" : "greenColour"}`}>{original && original.amount ? currencyFormat(original.amount) : null}</p>
                )
                
              },           
          },                 
          {
            Header: "Status",
            accessor: "status",            
            width: "140px",
            Cell: ({ row: { original } }) => {
                return ( 
                    classForTransactionListingStatus(original?.status)
                )
            },
          },    
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">                  
                    <button
                    onClick={() => {
                      handleViewItem(original);
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/actions-icon.png" alt="Actions" />
                    </span>
                  </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
      );

    const [search, setSearch] = useState(null);    
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownPageSize, setDropdownPageSize] = useState(30);
    const [sorts, setSorts] = useState([ {
                                id: "id",
                                desc: false,
                                },
                            ]);
    const setDropdownPageSizeHandler = (count) => {
        setCurrentPage(1);
        setDropdownPageSize(count);
    };
    const pageChangeHandler = (pageNo) => {
        setCurrentPage(pageNo);
    };
    const setSearchHandler = (searchKeyword) => {
        setSearch(searchKeyword);
    };
    const setDateHandler = (value) => {        
        if (typeof value == 'object' && value != null) { 
            const from_date = format(new Date(value[0]), "yyyy-MM-dd");
            const to_date = format(new Date(value[1]), "yyyy-MM-dd");
            setFromDate(from_date);
            setToDate(to_date);
          }
    };
    const setSortsHandler = (col) => {
        setSorts(col);
    };
    /**********  Filter ***********/
    const [filterValues,setFilterValues]= useState([]);
    const [filterFilter,setFilterFilter]= useState([]);
    const [filterBtnValue,setFilterBtnValue]= useState([]);

    const setFilterBtnHandler = (value) => {        
        if (typeof value == 'object' && value != null) {             
            setFilterBtnValue(value);            
        }
    };


    const [openTransactionModel, setOpenTransactionModel] = useState(false);
    const [transactionModelData, setTransactionModelData] = useState(null);
    
    const handleViewItem = (value) => {
        setOpenTransactionModel(true);
        setTransactionModelData(value);
    };
    const handleOkTransaction = (link) => {
      window.open(`${link}`, "_blank");
      setOpenTransactionModel(false);
    };
    const handleCancelTransaction = () => {
      setOpenTransactionModel(false);
    };

    useEffect(() => {
        setFetchTransactionListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
    }, [dropdownPageSize]);

    useEffect(() => {
        setFetchTransactionListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
    }, [currentPage]);

    useEffect(() => {
        if (search || search === "") {
        setCurrentPage(1);
        setFetchTransactionListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
        }
    }, [search]);

    useEffect(() => {
        if (sorts) {
            setFetchTransactionListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
        }
    }, [sorts]);

    useEffect(() => {
        setFetchTransactionListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue     
        });
      }, [fromDate,toDate]);    

    useEffect(() => {
        setFetchTransactionListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
      }, [filterBtnValue]); 
    return (        
        <div>
            <div className='flex'>
            <div className="mx-auto my-8 bg-white px-8 py-4 rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                <FullPageLoadingModel
                    open={Boolean(isLoading)}
                />
                <ReactTableCustom
                    dropdownPageSize={dropdownPageSize}
                    setDropdownPageSize={setDropdownPageSizeHandler}
                    currentPage={currentPage}
                    pageChangeHandler={pageChangeHandler}
                    data={data}
                    columns={columns}
                    filterData={filterData}
                    paginationProps={transactionList?.pagination_option}
                    setSearchHandler={setSearchHandler}
                    setDateHandler={setDateHandler}
                    setFiltersHandler={setSortsHandler}
                    appliedFilters={filterValues}
                    setAppliedFilters={setFilterValues}
                    filterFilter={filterFilter}
                    setFilterFilter={setFilterFilter}
                    setFilterBtnHandler={setFilterBtnHandler}
                    placeholder={"Transaction List"}
                    initialStateSortBy={sorts}
                    showPaginationBottom={true}
                    columnSearchable='false'
                    disableCheckboxSelection= 'true'
                />
                <TransactionModel
                    open={Boolean(openTransactionModel)}
                    data={transactionModelData}                    
                    handleOk={handleOkTransaction}
                    handleCancel={handleCancelTransaction}
                />
                </div>
            </div>
        </div>

    );
}

export default TransactionListing;