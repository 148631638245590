import React, { useEffect,useState } from "react";
import { Menu } from '@mui/material';
import "./DPDocument.css";
import { Upload } from "antd";
import { useToast } from "services";


const { Dragger } = Upload;

const Imagediv = ({data, docType, docSide, docNumber=0, handleChangeStatus,handleCustomRequest, hideAction=false}) => {  
  
  const responseToast = useToast(); 
  const [allImgFiles, setAllImgFiles] = useState([]);


  useEffect(() => {
    const newArray = [];
    data?.forEach((sr)=>{
      if(sr.document_type=== docType){
        if(docSide!==''){
          if(sr.document_side=== docSide){
            newArray.push(sr);
          }
        } else {
          newArray.push(sr);
        }        
      }      
    })
    setAllImgFiles(newArray);
  }, [data]);
  
  const handleCustRequest = ({ file }) => {
    if(file.type.match('application/pdf')){       
      handleCustomRequest(docType, docSide, file);
    } else {
      responseToast.toastError("Only PDF files are accepted")
    }
  };
  
  const handleOpenDocClick = (event) => {  
    const docurl = event.currentTarget.getAttribute('doc-url');
    window.open(docurl, '_blank');
  };


  /***Acation****/
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeClose = (event) => {
    setAnchorEl(null);
    handleChangeStatus(event.currentTarget.id, event.currentTarget.getAttribute('doc-id'));
  };
  
  return (
    <div className="">      
      {Object.entries(allImgFiles)?.filter((item, index) => index === docNumber*1).map(([key, val]) => {
              const docId = val.id;
              const document = val.document; 
              const status = val.status;           
              return (
                <>    
                      <div className="docCont">
                          <div className="docIcon">
                              <img src="/assets/file-ring-icon.png" className="docFileImg" alt="file" />
                          </div>
                          <div onClick={handleOpenDocClick} doc-url={document} className="docText">
                              <div className="docTextN">{docTypeDiv(docType)} {docSide}</div>
                              <div className="docTextSi">100% uploaded</div>
                              <div className="docTextSt">{statusDiv(status)}</div>
                          </div>
                          { !hideAction && (                         
                          <>
                          <div onClick={handleStatusClick} className="docActionIcon">
                              <img  src="/assets/dot-icon.png" className="docDotImg" alt="Action" title="Action" />
                           </div>
                          <Menu
                                id="doc-status-box"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <div className="statusOpactionbox">
                                  <div
                                        id={'APPROVED'}
                                        onClick={handleChangeClose}
                                        className="statusOptList"
                                        doc-id={docId}                                       
                                      >
                                        Approved
                                  </div>
                                  <div
                                        id={'REJECTED'}
                                        onClick={handleChangeClose}
                                        className="statusOptList redColour"
                                        doc-id={docId}                                       
                                      >
                                        Reject
                                  </div>
                                  <div
                                        id={'REMOVED'}
                                        onClick={handleChangeClose}
                                        className="statusOptList"
                                        doc-id={docId}                                        
                                      >
                                        Removed
                                  </div>
                                </div>
                            </Menu>
                            </>
                            )}
                      </div>
                   
                 
                </>
              );
        })}

      { allImgFiles.length<=docNumber*1 && (        
          <div className="imgCantBo"> 
            <Dragger
              fileList={[]}
              customRequest={handleCustRequest}
              maxCount={1}
              multiple={false}
              disabled={false}
              accept={'application/pdf'}
            >
              <div className="fileIcon">
                <img src="/assets/upload-icon.png" className="fileImg" alt="file" />
                <br/><span className="fileIconText">Click to upload pdf</span>
                </div>
            </Dragger>
          </div>       
      )}
    </div>
  );
};

export default Imagediv;

const statusDiv = (status) => {
  switch (status) {
    case 'APPROVED':
      return <div className="Approved">&#x2022; Approved</div>;
    case 'REJECTED':
      return <div className="Reject">&#x2022; Rejected</div>;
    case 'APPROVAL_PENDING':
      return <div className="Pending">&#x2022; Approval Pending</div>;
    case 'CHANGE_REQUEST_PENDING':
      return <div className="ChangePending">&#x2022; Change Request Pending</div>; 
    default:
      return status;
  }  
};

const docTypeDiv = (doc) => {
  return doc.replace(/_/g, " ");
};


