import React, { useEffect,useState } from "react";
import "./FinancePhoto.css";


const Imagediv = ({data, imgType,imgName, handleViewImgFile}) => {
  const [allImgFiles, setAllImgFiles] = useState([]);

  useEffect(() => {
    const newArray = data?.filter((file) => file.description === imgType);
    setAllImgFiles(newArray);
  }, [data]);

  const handleCustRequest = (event) => {
    handleViewImgFile(event.currentTarget.getAttribute('data-id'), imgName, event.currentTarget.getAttribute('data-url'));
  };
  
  return (
    <div className="">      
      {Object.entries(allImgFiles)?.map(([key, val]) => {
        const imgUrl = val.attachment;             
        return (
          <>
            <div className="cont20 plr25 mb20">
              <div className="fimgTabName">{imgName}</div>
              <div onClick={handleCustRequest} data-id={val.id} data-url={imgUrl} className="fimgCant">
                <img src={imgUrl} className="fImg" alt="img" />
              </div>
            </div>
          </>
        );
        })}    
    </div>
  );
};
export default Imagediv;
