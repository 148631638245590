const classForFinanceListingStatus = (status, isDot=true) => {  
  switch (status) {    
    case "UNDER_PROCESS":
        return ( 
            <p className={"yellowColour " + (isDot? 'disList' : '')}>
                 Application Under Process
            </p>
        )
    case "COMPLETE":
        return ( 
            <p className={"greenColour " + (isDot? 'disList' : '')}>
                Financing Completed
            </p>
        )
    case "DENIED":
        return ( 
            <p className={"redColour " + (isDot? 'disList' : '')}>
                Application Denied
            </p>
        )
    case "Document Pending":
        return ( 
            <p className={"grayColour " + (isDot? 'disList' : '')}>
                Document Pending
            </p>
        )
    case "CANCELED":
        return ( 
            <p className={"redColour " + (isDot? 'disList' : '')}>
                Application Canceled
            </p>
        )
    
  default:
    return status;
}

};

export default classForFinanceListingStatus;
