import { useQuery} from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const sortHelperFun = (key) => {
  switch (key) {
    case "Section":
      return "spot_section_name";
    case "Target screen":
      return "spot_target_screen";
    default:      
      if(key.desc){
        return key.id;
      } else {
        return '-'+key.id;
      }
     
  }
};
// ['Successful','Failed','Pending','Canceled','Refund'
const statusHelperFun = (key) => {
  switch (key) {
    case "Successful":
      return "succeeded";
    case "Failed":
      return "failed";
    case  "Pending":
      return "pending";
    case  "Canceled":
      return "canceled";
    case  "Refund":
      return "refund";
    default:
        return key;      
  }
};

const filterHelperFun = (filter_value) => {
  let filter_url = '';
  let category_url = '';
  let brand_url = '';
  let status_url = '';
  filter_value?.forEach((fv)=>{    
    if(fv.filter==='category'){
      category_url += `${fv.label},`;
    }
    if(fv.filter==='status'){
      const newStr = statusHelperFun(fv.label);
      status_url += `${newStr},`;
    }
    if(fv.filter==='brand'){
      brand_url += `${fv.label},`;
    }   
  })
  if(category_url !==''){
    filter_url += `&category__name__in=${category_url}`;    
  }
  if(status_url !==''){
    filter_url += `&status__in=${status_url}`;
  }
  if(brand_url !==''){
    filter_url += `&vehicle__make__in=${brand_url}`;    
  }
  return filter_url;
};

const FetchTableList = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `transaction/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }
  if (body?.from_date && body?.to_date) {
    url += `&created_at_after=${body?.from_date}&created_at_before=${body?.to_date}`;
  }
  if (body?.filter_value && body?.filter_value.length > 0) {
    url += filterHelperFun(body?.filter_value);
  }
  
  return useAPI({ method, url });
};



export const FetchTableListQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_TRANSACTION_LIST", payload],
    () => FetchTableList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};