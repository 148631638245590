import { useQuery} from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const sortHelperFun = (key) => {
  switch (key) {
    case "Section":
      return "spot_section_name";
    case "Target screen":
      return "spot_target_screen";
    default:      
      if(key.desc){
        return key.id;
      } else {
        return '-'+key.id;
      }
     
  }
};


const filterHelperFun = (filter_value) => {
  let filter_url = '';
  let category_url = '';
  let make_url = '';  
  filter_value?.forEach((fv)=>{    
    if(fv.filter==='category'){
      category_url += `${fv.label},`;
    }    
    if(fv.filter==='make'){
      make_url += `${fv.label},`;
    }   
  })
  if(category_url !==''){
    filter_url += `&category__name__in=${category_url}`;    
  } 
  if(make_url !==''){
    filter_url += `&vehicle__make__in=${make_url}`;    
  }
  return filter_url;
};

const FetchTableList = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `inventory/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }
  if (body?.from_date && body?.to_date) {
    url += `&vehicle__year_min=${body?.from_date}&vehicle__year_max=${body?.to_date}`;
  }
  if (body?.filter_value && body?.filter_value.length > 0) {
    url += filterHelperFun(body?.filter_value);
  }
  
  return useAPI({ method, url });
};



export const FetchTableListQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_INVENTORY_LIST", payload],
    () => FetchTableList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchCategoriesList = (body) => {
  const method = "GET"; 
  let url = `category/`; 
  
  return useAPI({ method, url });
};

export const FetchCategoriesQuery = (payload) => {  
  const responseToast = useToast();
  return useQuery(
    ["GET_INVENTORY_CAT_LIST", payload],
    () => FetchCategoriesList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchMakeList = (body) => {
  const method = "GET"; 
  let url = `vehicle-brands/`;  
  
  return useAPI({ method, url });
};

export const FetchMakeListsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(   
    ["GET_INV_MAKE_LIST", payload],
    () => FetchMakeList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};