import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useToast = () => {
  const toastSuccess = (message) => {
    if (message)
      toast.success(message, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
  };

  const toastError = (message) => {    
    if (message)      
      toast.error(message, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
  };

  const toastInfo = (message) => {
    if (message)
      toast.info(message, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
  };

  return {
    toastSuccess,
    toastInfo,
    toastError,
  };
};

export default useToast;
