import { useQuery, useMutation, QueryClient } from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const UploadDoc = (body) => {
  const method = "POST";
  const url = `users/${body?.docUserId}/documents/`;

  let formData = new FormData();  
  formData.append("document_type", body?.document_type);
  formData.append("document_side", body?.document_side);
  formData.append("document", body?.document);
  
  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
  });
};

export const UploadDocQuery = () => {
  const responseToast = useToast();
  return useMutation(UploadDoc, {
    onSuccess: (response) => {
      responseToast.toastSuccess('Document uploaded successfully');
    },
    onError: (error) => responseToast.toastError(error && error.description),
  });
};

const DeleteDoc = (body) => {
  const method = "DELETE";
  const url = `users/${body?.docUserId}/documents/${body?.id}/`;

  return useAPI({
    method,
    url,
  });
};

export const DeleteDocQuery = () => {
  const responseToast = useToast();
  return useMutation(DeleteDoc, {
    onSuccess: (response) => {
      responseToast.toastSuccess('Document removed successfully');
    },
    onError: (error) => responseToast.toastError(error && error.description),
  });
};




// PATCH doc :

const UpdateDoc = (body) => {
  const method = "PUT";
  const url = `user-documnet/${body?.id}/`;
  const formData = {    
    status: body?.status,
    finance_id: body?.financeId  
  };
  
  return useAPI({
    method,
    url,
    body: formData,
  });
};

export const UpdateDocQuery = () => {
  const responseToast = useToast();
  return useMutation(UpdateDoc, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error.description),
  });
};


const UpdateFinanceDoc = (body) => {
  const method = "PATCH";
  const url = `finance/${body?.financeId}/`;
    return useAPI({
    method,
    url,
    body: body,
  });
};

export const UpdateFinanceDocQuery = () => {
  const responseToast = useToast();
  return useMutation(UpdateFinanceDoc, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error.description),
  });
};