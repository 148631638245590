import { useMutation} from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const ValidationFPDetails = (body) => {
  const method = "POST";
  const url = `auth/send-otp/`;
  const payload = {
    "channel": "EMAIL_OTP",
    "otp_for": "Forgot Password",
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleForgotPasswordFormAPI = () => { 
  const responseToast = useToast(); 
  return useMutation(ValidationFPDetails, {    
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message || "OTP sent on email successfully");
    },
    onError: (error) => {      
      if (error && error.errors) {        
        responseToast.toastError(error.errors[0].message || "Invalid Credentials" );
      }
    },
  });
};

const ValidationOTPDetails = (body) => {
  const method = "POST";
  const url = `auth/verify-otp/`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandleOTPFormAPI = () => { 
  const responseToast = useToast(); 
  return useMutation(ValidationOTPDetails, {    
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message || "OTP sent on email successfully");
    },
    onError: (error) => {      
      if (error && error.errors) {        
        responseToast.toastError(error.errors[0].message || "Invalid Credentials" );
      }
    },
  });
};

const ValidationPassDetails = (body) => {
  const method = "POST";
  const url = `auth/reset-password/`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload} });
};

export const HandlePasswordFormAPI = () => { 
  const responseToast = useToast(); 
  return useMutation(ValidationPassDetails, {    
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message || "Reset Password successfully");
    },
    onError: (error) => {      
      if (error && error.errors) {        
        responseToast.toastError(error.errors[0].message || "Invalid Credentials" );
      }
    },
  });
};

