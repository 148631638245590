import { useQuery} from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const sortHelperFun = (key) => {
  switch (key) {
    case "Section":
      return "spot_section_name";
    case "Target screen":
      return "spot_target_screen";
    default:      
      if(key.desc){
        return key.id;
      } else {
        return '-'+key.id;
      }
     
  }
};

const FetchTableList = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `users/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }
  if (body?.from_date && body?.to_date) {
    url += `&created_at_after=${body?.from_date}&created_at_before=${body?.to_date}`;
  }
  
  
  return useAPI({ method, url });
};



export const FetchTableListQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_USERS_LIST", payload],
    () => FetchTableList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};


const FetchUserDetail = (body) => {
  const method = "GET";   
  let url = `users/${body}/`;  
  return useAPI({ method, url });
};

export const FetchUserDetails = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_User", payload],
    () => FetchUserDetail(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchSellerList = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `user/${body?.user_id}/listing/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }    
  return useAPI({ method, url });
};
export const FetchUserSellerList = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_USERS_SELLER_LIST", payload],
    () => FetchSellerList(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchUserQuote = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `user/${body?.user_id}/quote/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }    
  return useAPI({ method, url });
};
export const FetchUserQuoteList = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_USERS_Quote_LIST", payload],
    () => FetchUserQuote(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchUserSmrEqu = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `user/${body?.user_id}/smr-finance/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }    
  return useAPI({ method, url });
};
export const FetchUserSmrEquList = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_USERS_SMR_ENQ_LIST", payload],
    () => FetchUserSmrEqu(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchUserNonSmrEqu = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `user/${body?.user_id}/non-smr-finance/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }    
  return useAPI({ method, url });
};
export const FetchUserNonSmrEquList = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_USERS_Non_SMR_ENQ_LIST", payload],
    () => FetchUserNonSmrEqu(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchUserTransaction = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `user/${body?.user_id}/transaction/?page=${body?.page || 1}&per_page=${
  //let url = `transaction/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }    
  return useAPI({ method, url });
};
export const FetchUserTransactionList = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_USERS_Transaction_LIST", payload],
    () => FetchUserTransaction(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};