import { createTheme, responsiveFontSizes } from '@mui/material/styles';

/**
 * Theme
 */
const commonTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#0F5E91',
      contrastText: '#FFF',
      light: '#0F5E91',
      dark: '#0C4B74',
      50: '#F0FDF9',
      100: '#CCFBEF',
      200: '#99F6E0',
      300: '#5FE9D0',
      400: '#2ED3B7',
      500: '#0F5E91',
      600: '#0E9384',
      700: '#107569',
      800: '#125D56',
      900: '#134E48',
    },
    secondary: {
      main: '#157EB7',
      contrastText: '#FFF',
      25: 'F5FAFF',
      50: '#E8F3F8',
      100: '#D1E9FF',
      200: '#A1CBE2',
      300: '#73B2D4',
      400: '#4498C5',
      500: '#157EB7',
      600: '#105F89',
      700: '#0B3F5C',
      800: '#05202E',
      900: '#194185',
    },
    gray: {
      25: '#F4F7F7',
      50: '#E6E9E9',
      100: '#CDD2D1',
      200: '#9BA5A4',
      300: '#D1D5DB',
      400: '#364C49',
      500: '#6B7280',
      600: '#031714',
      700: '#374151',
      800: '#010807',
      900: '#000303',
    },
    primaryGreen: '#05945B',
  },
  typography: {
    fontFamily: `"Open Sans", sans-serif -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale !important`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          border: '1px solid #D0D5DD',
          borderRadius: '8px',
          fontWeight: 600,
          padding: '6px 16px',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          background: 'primary',
        },
      },
    },
    //   MuiChip: {
    //     styleOverrides: {
    //       root: {
    //         borderRadius: '1px',
    //       },
    //     },
    //   },
    //   MuiDialog: {
    //     styleOverrides: {
    //       paper: {
    //         borderRadius: '24px',
    //       },
    //     },
    //   },
    //   MuiFormLabel: {
    //     styleOverrides: {
    //       root: {
    //         '&.Mui-focused': {
    //           color: '#3699FF',
    //         },
    //       },
    //       asterisk: {
    //         color: 'red',
    //       },
    //     },
    //   },
    //   MuiInputBase: {
    //     defaultProps: {
    //       color: 'secondary',
    //     },
    //     styleOverrides: {
    //       root: {
    //         '&.MuiOutlinedInput-root': {
    //           backgroundColor: '#fff',
    //           borderRadius: '8px',
    //           minHeight: '44px',
    //         },
    //         input: {
    //           lineHeight: '24px',
    //         },
    //       },
    //     },
    //   },
    //   MuiInputLabel: {
    //     styleOverrides: {
    //       root: {
    //         color: '#1A1A1A',
    //         opacity: '0.7',
    //       },
    //       asterisk: {
    //         color: 'red',
    //       },
    //     },
    //   },

    //   MuiDialogContent: {
    //     styleOverrides: {
    //       root: {
    //         padding: '0px',
    //       },
    //     },
    //   },
    //   MuiTextField: {
    //     styleOverrides: {
    //       root: {
    //         matginTop: '0px',
    //         borderRadisu: '6px',
    //         '& .MuiInputBase-root': {
    //           borderRadius: '6px',
    //         },
    //       },
    //     },
    //   },

    //   MuiFormHelperText: {
    //     styleOverrides: {
    //       root: {
    //         matginTop: '0px',
    //       },
    //     },
    //   },
    //   MuiFab: {
    //     styleOverrides: {
    //       root: {
    //         backgroundColor: 'white',
    //         boxShadow: 'none',
    //       },
    //     },
    //   },
    //   MuiSelect: {
    //     styleOverrides: {
    //       root: {
    //         borderRadius: '32px',
    //         border: '1px solid #F2F2F2',
    //       },
    //       select: {
    //         backgroundColor: '#F2F2F2',
    //         borderRadius: '32px',
    //         border: '1px solid #F2F2F2',
    //       },
    //     },
    //   },
    //   MuiCard: {
    //     styleOverrides: {
    //       root: {
    //         boxShadow: 'none',
    //       },
    //     },
    //   },
    //   MuiAccordion: {
    //     styleOverrides: {
    //       root: {
    //         boxShadow: 'none',
    //       },
    //     },
    //   },
  },
});

// Do your common overrides here
/**
 * Typography - body1
 */
// commonTheme.typography.body1.fontSize = "0.8rem";
// commonTheme.typography.body1.color = commonTheme.palette.text.primary;

/**
 * Typography - caption
 */
commonTheme.typography.caption.color = commonTheme.palette.text.hint;
/**
 *  ** THIS SHOULD BE LAST **
 * Root Theme
 */
const rootTheme = responsiveFontSizes(commonTheme);

export default rootTheme;
