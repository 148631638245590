const classForTransactionType = (status) => {
  switch (status) {    
    case "Inspection":
        return ( 
            <p className="yellowColour">
                Inspection Fee
            </p>
        )
    case "Quotes":
        return ( 
            <p className="maincolor">
                Deposit Amount
            </p>
        )
    case "Quotes Refund":
        return ( 
            <p className="redColour">
                Deposit Refunded
            </p>
        )
    
  default:
    return status;
}

};

export default classForTransactionType;
