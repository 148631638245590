import React from 'react';
import { Menu } from '@mui/material';
import { sellerListingStatus } from 'utils';

const StatusSelectField = ({
  label,
  value,
  statusLists,
  setStatusChangeHandler,
  labelClass,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleStatusClick = (event) => {
    !disabled && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeClose = (event) => {
    setAnchorEl(null);
    setStatusChangeHandler(event.currentTarget.id);
  };

  return (
    <>
      <div className={`statusBoxLabel ${labelClass}`}>{label}</div>
      <div className="statusBox">
        <Menu
          id="status-box"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className="statusOpactionbox">
            {statusLists.map((sl, idx) => (
              <>
                <div
                  id={sl}
                  onClick={handleChangeClose}
                  className="statusOptList"
                  key={Math.random * idx}
                >
                  {sellerListingStatus(sl)}
                </div>
              </>
            ))}
          </div>
        </Menu>
        <div onClick={handleStatusClick} className="statusSelectFiled">
          {sellerListingStatus(value, true)}
        </div>
        <div
          onClick={handleStatusClick}
          className={` ${
            disabled ? 'hover:cursor-not-allowed' : ''
          }  statusSelectFiledIcon`}
        >
          ^
        </div>
      </div>
    </>
  );
};
StatusSelectField.defaultProps = {
  value: '',
  statusLists: [],
};

export default StatusSelectField;
