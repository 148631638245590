import { Box, Typography } from '@mui/material';
import ReactTableCustom from 'components/ReactTableCustom';
import { InquiryStatusList } from 'consts';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { currencyFormat, mobileFormat,classForInquiryListingStatus } from 'utils';
import { useGetQuotesByListingId } from './Queries';

const DepositTable = () => {
  const [searchParams] = useSearchParams();
  const listingId = searchParams.get('listingId');

  const [quotePage, setQuotePage] = useState(1);
  const [sort, setSort] = useState([]);

  const { data: quotesList } = useGetQuotesByListingId({
    id: listingId,
    params: {
      page: quotePage,
      ordering: sort,
      per_page: 100,
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'user__name',
        Cell: ({ row: { original } }) => {
          return <p className="capitalize">{original?.user?.name}</p>;
        },
        disableSortBy: true,
      },
      {
        Header: 'Contact',
        accessor: 'user__phone',
        Cell: ({ row: { original } }) => {
          return (
            <>
              <p className="text-[#545454]">
                {original && original?.user?.phone
                  ? mobileFormat(original.user.phone)
                  : null}
              </p>
              <p className="m-0">{original?.user?.email}</p>
            </>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Date',
        accessor: 'created_at', // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <p className="text-[#545454]">
                {original && original.created_at
                  ? format(new Date(original.created_at), 'MMM dd, yyyy')
                  : null}
              </p>
              <p className="font10 fw400 grey60color">
                {original && original.created_at
                  ? format(new Date(original.created_at), 'hh:mm b')
                  : null}
              </p>
            </div>
          );
        },
        disableSortBy: true,
      },

      {
        Header: 'Mode',
        accessor: 'mode', // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return (
            <p
              className={`${
                original?.mode === 'Request Quote'
                  ? 'text-primaryGreen'
                  : 'text-primaryGold'
              } `}
            >
              {original?.mode}
            </p>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Quote Amount',
        accessor: 'quote_price',
        Cell: ({ row: { original } }) => {
          return (
            <Typography variant="caption">{original && original.quote_price ? currencyFormat(original.quote_price) : null}</Typography>
          );
        },
        disableSortBy: true,
      },
      {
        Header: '2.5% Deposit Amount',
        accessor: 'token_amount',
        Cell: ({ row: { original } }) => {
          return (
            <Typography variant="caption">{original && original.token_amount ? currencyFormat(original.token_amount) : null}</Typography>            
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }) => {
          return classForInquiryListingStatus(original?.status);
        },
        disableSortBy: true,
      },
    ],
    []
  );
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <ReactTableCustom
          data={
            quotesList?.results?.filter(
              (el) => (el.status === InquiryStatusList.DEPOSIT_SUCCESSFUL || el.status === InquiryStatusList.DEPOSIT || el.status === InquiryStatusList.AMOUNT_REFUNDED )
            ) ?? []
          }
          columns={columns}
          showPaginationBottom={true}
          // columnSearchable="true"
          disableCheckboxSelection="true"
          hideTopbar="false"
          paginationProps={quotesList?.pagination_option}
          currentPage={quotePage}
          pageChangeHandler={setQuotePage}
          setFiltersHandler={setSort}
        />
      </Box>
    </>
  );
};

export default DepositTable;
