import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';
import { FetchDealPDetailsQuery, FetchListingDetailsQuery,FetchFinanceQuery,FetchSendDocToSlateFinancialQuery,FetchSendRefundDepositedQuery,FetchAskToUploadDocQuery} from "./Queries";

import ListingViewSection from "components/ListingViewSection";
import DPDocument from "components/DPDocument";

import { currencyFormat, mobileFormat,sellerListingStatus,classForFinanceListingStatus,classForDealsListingStatus } from "utils";
import  "./deals.css";


const DealsInprogressDetails = () => {

    const [searchParams]= useSearchParams()
    const dpId=searchParams.get('id')
    if(dpId==='' || dpId===null ){       
        window.location = "/deals-in-progress";
    }
    
    const [listingId, setListingId] = useState(null);
    const [financeId, setFinanceId] = useState(null);
    const [financeDocuments, setFinanceDocuments] = useState([]);
    const [docUserId, setDocUserId] = useState(null);
    


    const {data: DpDetail, refetch}= FetchDealPDetailsQuery(dpId);
    const dpData = useMemo(() => DpDetail?.data || [], [DpDetail]);
    const { data: listDetails } = FetchListingDetailsQuery(listingId);
    const listDetail = useMemo(() => listDetails?.data || [], [listDetails]);
    const { data: financeData, refetch: refetchFinancial } = FetchFinanceQuery(financeId);  
   
    
    
    
    useEffect(() => {
        setListingId(DpDetail?.data?.listing_obj?.id);
        setFinanceId(DpDetail?.data?.finance_id);
        setDocUserId(DpDetail?.data?.user?.id)
    }, [DpDetail]);

    useEffect(() => {        
        if(financeData?.data?.documents){
            setFinanceDocuments(financeData?.data?.documents);
            setDocUserId(financeData?.data?.user?.id)
        }
    }, [financeData]);
    
    
    const { mutateAsync: SendNotiAskTo } = FetchAskToUploadDocQuery();
    const handleAskToUploadDoc = () => {        
        SendNotiAskTo({'user_id': docUserId, 'listing_id': ''+listingId+''});
    };
   
    const { mutateAsync: SendDoctoSlateFin } = FetchSendDocToSlateFinancialQuery();
    const handleSendDocToSlateFinancial = () => {       
       SendDoctoSlateFin(financeId).then((respData) => {
        if(respData?.status_code===200){
            refetch();
            refetchFinancial();
        }
      });      
    };
    const { mutateAsync: SendRefundDeposited } = FetchSendRefundDepositedQuery();
    const handleRefundDeposited = () => {        
        SendRefundDeposited({'quote_id': dpData.id, 'transaction_id': dpData.transaction}).then((respData) => {
            if(respData?.status_code===200){
                setTimeout(() => {
                    window.location = "/deals-in-progress";
                }, 1000);                
            }
        }); 
    };
     
    
    
    return (  
            <div className='flex'>
                <div className="mx-auto my-8 bg-white rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                    <div className="gray-container font14">
                        <div className="cont25">
                            Owner
                            <span className="userName">{listDetail?.user?.name}</span>
                        </div>
                        <div className="cont25">
                            <div>{mobileFormat(listDetail?.user?.phone)} &nbsp;</div>
                            <div className="fw400 font12 gray70">{listDetail?.user?.email} </div>
                        </div>
                        <div className="cont25">
                            Price &nbsp;&nbsp;&nbsp;<span className="greenColour">{currencyFormat(listDetail?.selling_price)}</span>
                        </div>
                        <div className="cont25" style={{display: "flex"}}>                            
                            Status &nbsp;&nbsp;&nbsp; {sellerListingStatus(listDetail?.status)}
                        </div>
                    </div>
                    <div className="white-container">
                        <ListingViewSection
                            data={listDetail}
                        />
                    </div>
                    <div className="white-container">
                        <div className="container-heading full">
                            <span>Documents</span>
                            
                            {financeId ? (
                                <>
                                {financeData?.data?.status !=='COMPLETE' && (
                                    <span style={{float:'right'}}><Button variant="contained" onClick={handleSendDocToSlateFinancial} className="mainBtn">Send Documents to Slate Financial</Button></span>
                                )}
                                {financeData?.data?.status ==='UNDER_PROCESS' & dpData?.status ==='DEPOSIT_SUCCESSFUL' && (
                                    <span style={{float:'right'}}><Button variant="contained" onClick={handleRefundDeposited} className="warningBtn">Refund Deposited Amount</Button></span>
                                )}                          
                                </>                                                               
                            ):(
                                <span style={{float:'right'}}><Button variant="contained" onClick={handleAskToUploadDoc} className="mainBtn">Ask To Upload Documents</Button></span>
                            )}
                            <span className="DPStatus" >Inquiry Status:&nbsp; {classForDealsListingStatus(dpData?.status, false)}</span>
                            {financeId && (
                                <span className="DPStatus">Finance Status:&nbsp; {classForFinanceListingStatus(financeData?.data?.status, false)}</span>    
                            )}
                            
                            
                        </div>
                        {financeId ? (
                            <div className="full">
                                <DPDocument
                                    data={financeDocuments}
                                    docUserId={docUserId}
                                    financeId={financeId}
                                />
                            </div>
                        ):(                            
                            <div className="noFileIcon">
                                <img src="/assets/no-documents-found.png" className="noFileImg" alt="file" />
                                <br/><span className="noFileIconText">No Documents found</span>
                            </div>                          
                        )}
                        
                    </div>


                </div>
            </div>       
    );
}

export default DealsInprogressDetails;