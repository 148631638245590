import { Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { StatusSelectField } from 'components';
import ListingViewSection from 'components/ListingViewSection';
import DPDocument from "components/DPDocument";
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { currencyFormat, mobileFormat } from 'utils';
import { useGetListingObjectQuery,FetchFinanceQuery } from './Queries';
import TableSection from './TableSection';
const StyledTypo = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
});

const InquiryDetails = () => {
  const [searchParams] = useSearchParams();
  const listingId = searchParams.get('listingId');
  const fId = searchParams.get('financeId');

  const [selectedStatus, setSelectedStatus] = useState();

  const { data: listingObj } = useGetListingObjectQuery({
    id: Number(listingId),
  });
  const { name, email, phone } = listingObj?.data?.user ?? {};


  const [financeId, setFinanceId] = useState(null);
  const [financeDocuments, setFinanceDocuments] = useState([]);
  const [hideAction , setHideAction] = useState(false);
  const [docUserId, setDocUserId] = useState(null);
  const { data: financeData } = FetchFinanceQuery(fId);

  useEffect(() => {        
      if(financeData?.data?.documents){
          setFinanceDocuments(financeData?.data?.documents);
          setDocUserId(financeData?.data?.user?.id);
          setFinanceId(financeData?.data?.id);
          if (financeData?.data?.quote_status==='DEPOSIT_SUCCESSFUL'){
            setHideAction(false);
          } else {
            setHideAction(true);
          }
      }
  }, [financeData]);

  return (
    <>
      <section className="container-gray-border flex justify-between items-center py-5">
        <div className="flex gap-4">
          <StyledTypo fontWeight={600}>Owner</StyledTypo>
          <Typography fontSize={14} className="userName">
            {name}
          </Typography>
        </div>
        <div className="flex flex-col ">
          <Typography fontWeight={600} variant="caption">
            {phone && mobileFormat(phone)}
          </Typography>
          <Typography variant="caption">{email}</Typography>
        </div>
        <div className="flex gap-7">
          <StyledTypo fontWeight={600}>Price</StyledTypo>
          <Typography fontSize={14}>
            <span className="greenColour">{currencyFormat(listingObj?.data?.selling_price)}</span>            
          </Typography>
        </div>
        <div className="flex gap-8 items-center">
          <StatusSelectField
            label="Status"
            labelClass="font-semibold"
            statusLists={[listingObj?.data?.status]}
            value={selectedStatus ?? listingObj?.data?.status}
            setStatusChangeHandler={setSelectedStatus}
            disabled={true}
          />
        </div>
        <IconButton>{/* <MoreVert /> */}</IconButton>
      </section>
      <section className="container-white-border">
        <ListingViewSection data={listingObj?.data} />
      </section>
      
      {financeId && (
        <div className="white-container">
          <div className="container-heading full">
            <span>Documents</span>
          </div>
          <div className="full">
            <DPDocument
                data={financeDocuments}
                docUserId={docUserId}
                financeId={financeId}
                hideAction={hideAction}
            />
          </div>
        </div>
      )}      
      <div className="clearFix"></div>
      <TableSection sellingPrice={listingObj?.data?.selling_price} />
    </>
  );
};

export default InquiryDetails;
