import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';
import { FetchFinanceQuery,HandleUpdateStatusData,FetchSendDocToSlateFinancialQuery} from "./Queries";

import { StatusSelectField} from "components";
import DPDocument from "components/DPDocument";
import FinancePhoto from "components/FinancePhoto";
import ChangeStatusModal  from "./ChangeStatusModal";

import { mobileFormat } from "utils";
import  "./deals.css";


const DealsInprogressDetails = () => {

    const [searchParams]= useSearchParams()
    const fId=searchParams.get('id')
    if(fId==='' || fId===null ){       
        window.location = "/finance-request";
    }
        
    const [financeId, setFinanceId] = useState(fId);
    const [financeDocuments, setFinanceDocuments] = useState([]);
    const [financePhoto, setFinancePhoto] = useState([]);
    const [docUserId, setDocUserId] = useState([]);
    


    
    
    const { data: financeData } = FetchFinanceQuery(fId); 
    useEffect(() => {        
        if(financeData?.data?.documents){
            setFinanceDocuments(financeData?.data?.documents);
            setDocUserId(financeData?.data?.user?.id)
        }
        if(financeData?.data?.documents){
            setFinancePhoto(financeData?.data?.attachments);
        }
        
        setStatusValue(financeData?.data?.status)
    }, [financeData]);   
    
   
    const statusLists=['UNDER_PROCESS','COMPLETE','DENIED'];
    const [statusValue, setStatusValue] = useState(null);
    const [changeStatus, setChangeStatus] = useState(false);
    const [statusData, setStatusData] = useState(null);
    const [reasonsRejection, setReasonsRejection] = useState(null);
    const [howToFix, setHowToFix] = useState(null);
    const setStatusChangeHandler = (value) => {
        setStatusData(value)
        setChangeStatus(true);
    };   
    
    const handleStatusModalClose = () => {
        setChangeStatus(false);        
    };
    const handleStatusModalConfrm = () => {
        setChangeStatus(false); 
        setStatusValue(statusData);
        ValidationStatusForm({id:fId, status: statusData, reason: reasonsRejection, description: howToFix})
        setReasonsRejection(null);
        setHowToFix(null);
    };
    const handleStatusTextChangeValue = (field, value) => {
        if(field==='reasons_rejection'){
            setReasonsRejection(value);
        } else{
            setHowToFix(value);
        }        
    };
    const { mutate: ValidationStatusForm } = HandleUpdateStatusData();

    
   
    const { mutateAsync: SendDoctoSlateFin } = FetchSendDocToSlateFinancialQuery();
    const handleSendDocToSlateFinancial = () => {       
       SendDoctoSlateFin(fId);
    };   
    
    
    return (  
            <div className='flex'>
                <div className="mx-auto my-8 bg-white rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                    <div className="gray-container font14">
                        <div className="cont33">
                            <img src="/assets/user-icon.png" className="userImg" alt="User" />                           
                            <span className="userName">{financeData?.data?.user?.name}</span>
                        </div>
                        <div className="cont33">
                        <div>{mobileFormat(financeData?.data?.user?.phone)} &nbsp;</div>
                            <div className="fw400 font12 gray70">{financeData?.data?.user?.email}</div>
                        </div>                        
                        
                        <div className="cont33">                            
                            <StatusSelectField
                                label="Status"                                
                                placeholder="Select Status"                               
                                value={statusValue}
                                statusLists={statusLists}
                                setStatusChangeHandler={setStatusChangeHandler}                                                    
                                
                            />
                        </div>
                    </div>
                    
                    <div className="white-container">
                        <div className="container-heading full">
                            <span>Documents</span>
                            {financeId ? (
                                <span style={{float:'right'}}><Button variant="contained" onClick={handleSendDocToSlateFinancial} className="mainBtn">Send Documents to Slate Financial</Button></span>                                
                            ):(
                                <span style={{float:'right'}}><Button variant="contained" className="mainBtn">Ask To Upload Documents</Button></span>
                            )}
                        </div>
                        {financeId ? (
                            <div className="full">
                                <DPDocument
                                    data={financeDocuments}
                                    docUserId={docUserId}
                                    financeId={financeId}
                                />
                            </div>
                        ):(                            
                            <div className="noFileIcon">
                                <img src="/assets/no-documents-found.png" className="noFileImg" alt="file" />
                                <br/><span className="noFileIconText">No Documents found</span>
                            </div>                          
                        )}
                        
                    </div>
                    <div className="white-container">
                        <div className="container-heading full">
                            <span>Truck Details</span>                            
                        </div>
                        
                        <div className="full">
                            <FinancePhoto
                                data={financePhoto}
                                docUserId={docUserId}
                                financeId={financeId}
                            />
                        </div>
                        
                        
                    </div>
                    <ChangeStatusModal
                        open={Boolean(changeStatus)}
                        handleClose={handleStatusModalClose}
                        handleConfrm={handleStatusModalConfrm}
                        handleStatusTextChangeValue={handleStatusTextChangeValue}
                        statusData={statusData}
                    />
                </div>
            </div>       
    );
}

export default DealsInprogressDetails;