import React, { useMemo } from "react";
import Button from '@mui/material/Button';
import ReactTableCustom from "components/ReactTableCustom";
import { FetchPastInquiryQuery} from "./Queries";
import { mobileFormat} from "utils";
import  "./dashboard.css";

const PastInquiry = () => {    
    const { data: pastInquiry } = FetchPastInquiryQuery('GET');
    const listData = useMemo(() => pastInquiry?.data || [], [pastInquiry]);
    
    const columns = useMemo(
        () => [            
          {
            Header: "Account",
            accessor: "account_id",
            width: "80px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p className="fw500 greycolor">{original?.account_id}</p>
                )
              },
            disableSortBy: true,
          },          
          {
            Header: "Name",
            accessor: "name",
            width: "140px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p className="fw500 greycolor">{original?.name}</p>
                )
              },
            disableSortBy: true,
          },
          {
            Header: "Contact",
            accessor: "contact_no",
            width: "140px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original && original?.contact_no ? mobileFormat(original.contact_no) : null}</p>
                )
              },
            disableSortBy: true,
          },          
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">
                  <button
                    onClick={() => {
                        handleViewItem(original?.listing_id);
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/actions-icon.png" alt="Actions" />
                    </span>
                  </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
    );

    const handleViewAll = () => {
        window.location = "/inquiry";
    };

    const handleViewItem = (value) => {        
        window.open(`inquiry/details?listingId=${value}`, "_blank")
    };
    
    return (        
        <div>
            <div className="wgtabletitle">
                <div className="wgtabletitleTxt">Past 24hr Inquiry's</div>
                <div className="wgtabletitleBtn"><Button variant="contained" onClick={handleViewAll} className="mainBtn" style={{margin:"0"}}>View All</Button></div>                
            </div>
            <div className="fulltable" >
                <ReactTableCustom                                      
                    data={listData}
                    columns={columns}
                    placeholder={"Top 10 Listing"}                   
                    showPaginationBottom={false}                   
                    hideTopbar={true}
                    showShowSearchTop={false}
                />                    
            </div>
        </div>            
    );
}
export default PastInquiry;