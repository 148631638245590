import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import React, { useState } from 'react';
import TableSearch from 'components/TableSearch';
import Pagination from '@mui/material/Pagination';
//import Select from "@mui/material/Select";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDebounce } from 'utils/CustomHooks/useDebounce';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Menu } from '@mui/material';
import Button from '@mui/material/Button';
import Styles from './ReactTableCustom.module.scss';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import { FilterParentComponent } from 'components/Filter/FilterParentComponent';
import TableBellIcon from 'components/TableBellIcon';

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: '',
};

export default function ReactTableCustom({
  columns,
  data,
  filterData,
  paginationProps,
  dropdownPageSize,
  setDropdownPageSize,
  pageChangeHandler,
  setSearchHandler,
  setDateHandler,
  setSMRTypeHandler,
  setFiltersHandler,
  placeholder,
  hiddenColumns,
  hideTopbar,
  showShowSearchTop = false,
  showPaginationBottom,
  currentPage,
  initialStateSortBy,
  setEditModeHandler,
  allRowsEdit,
  setAllRowsEdit,
  errors,
  setErrors,
  columnSearchable,
  forceRenderKey,
  columnSearch,
  singleRowErrors,
  setSingleRowErrorsHandler,
  multiRowErrors,
  setMultiRowErrorsHandler,
  userAccess,
  disableCheckboxSelection = false,
  smrTypeSelection = false,
  addNewSelection = false,
  yearRangeSelection = false,
  disableFilterSelection = false,
  appliedFilters,
  setAppliedFilters,
  filterFilter,
  setFilterFilter,
  setFilterBtnHandler,
  setYearFilterBtnHandler,
  updateMyData,
  skipPageReset = true,
  ...rest
}) {
  //states
  const [editObj, setEditObj] = React.useState({
    fresh_id: -1,
    isEdit: false,
    data: null,
  });

  //pagination variables
  let total_count = paginationProps?.total;
  let per_page = paginationProps?.per_page;

  // React Table - useTable()
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns || [],
        pageIndex: 0,
        sortBy: initialStateSortBy || [],
      },
      // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: Math.ceil(total_count / per_page),

      manualSortBy: true,
      // autoResetPage: false,
      autoResetSortBy: false,
      disableSortRemove: true,

      editObj,
      setEditObj,
      allRowsEdit,
      setAllRowsEdit,
      errors,
      setErrors,
      singleRowErrors,
      setSingleRowErrorsHandler,
      multiRowErrors,
      setMultiRowErrorsHandler,
      updateMyData,
      autoResetPage: !skipPageReset,
      ...(userAccess ? { userAccess } : {}),
    },

    useSortBy,
    usePagination,
    useRowSelect
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //pagination
    page,
    pageOptions,
    gotoPage,
    visibleColumns,
    setPageSize,
    state: { sortBy },
    exportData,
  } = tableInstance;

  React.useEffect(() => {
    // Set the page size
    if (paginationProps)
      setPageSize(Math.floor(total_count / dropdownPageSize));
  }, [paginationProps]);

  React.useEffect(() => {
    if (setFiltersHandler) setFiltersHandler(sortBy);
  }, [sortBy, setFiltersHandler]);

  //states
  /* const [dropdownPageSize, setDropdownPageSize] = useState(10); */
  const [searchKeyword, setSearchKeyword] = useState(null);
  //helpers
  const handleSearchUpdate = (text) => {
    setSearchKeyword(text?.trim());
  };

  /* Debouncing the search */
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);

  React.useEffect(() => {
    if (setSearchHandler) setSearchHandler(debouncedSearchKeyword);
  }, [debouncedSearchKeyword, setSearchHandler]);

  const [dateRange, setDateRange] = useState(null);
  React.useEffect(() => {
    if (setDateHandler) setDateHandler(dateRange);
  }, [dateRange, setDateHandler]);
  const handleDateChange = (text) => {
    setDateRange(text);
    setDatePickerIsOpen(false);
  };
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const openDatepicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [filterShowBtn, setFilterShowBtn] = useState([]);
  React.useEffect(() => {
    if (setFilterBtnHandler) setFilterBtnHandler(filterShowBtn);
  }, [filterShowBtn, setFilterBtnHandler]);
  const handleShowResults = () => {
    setAnchorEl(null);
    setFilterShowBtn(appliedFilters);
  };

  /** **/

  const [fromYear, setFromYear] = React.useState('');
  const [toYear, setToYear] = React.useState('');
  const [anchorDp, setAnchorDp] = React.useState(null);
  const opendp = Boolean(anchorDp);
  const handleFilterDpClick = (event) => {
    setAnchorDp(event.currentTarget);
  };
  const handleDPClose = () => {
    setAnchorDp(null);
  };

  const max = new Date().getFullYear();
  const min = max - 20;
  const yearList = [];
  for (let i = max; i >= min; i--) {
    yearList.push(i);
  }
  const handleFromYearChange = (event) => {
    setFromYear(event.target.value);
  };
  const handleToYearChange = (event) => {
    setToYear(event.target.value);
  };

  const handleApplyResults = () => {
    setAnchorDp(null);
    const yearFilters = {
      from_year: fromYear,
      to_year: toYear,
    };
    setYearFilterBtnHandler(yearFilters);
  };

  const [smrType, setSmrType] = React.useState('SMR');
  const smrTypeClick = (event) => {
    console.log(event.currentTarget.id);
    setSmrType(event.currentTarget.id);
    setSMRTypeHandler(event.currentTarget.id);
  };

  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const handleAddNewListing = () => {
    window.open(`seller-listing/add-new`, '_blank');
  };

  return (
    <>
      {showShowSearchTop && (
        <div className="table-options-section flex justify-between my-4 mb-12">
          <div className="">&nbsp;</div>
          <div className="flex items-center">
            <div className="">
              <TableSearch
                placeholder={placeholder || 'Search'}
                value={debouncedSearchKeyword}
                onChange={handleSearchUpdate}
              />
            </div>
          </div>
        </div>
      )}
      {!hideTopbar && (
        <div className="table-options-section flex justify-between my-4 mb-12">
          {/* Search Field */}

          <div className="">
            <div className="userWel">Welcome back, {user_details?.name}</div>
            <div className="userWelText">
              Track, manage and forecast your customers and orders
            </div>
          </div>

          <div className="flex items-center">
            <div className="">
              <TableSearch
                placeholder={placeholder || 'Search'}
                value={debouncedSearchKeyword}
                onChange={handleSearchUpdate}
              />
            </div>
            <div className={Styles.calendarCell}>
              {!yearRangeSelection ? (
                <div>
                  <DateRangePicker
                    size="md"
                    format="MMM dd, yyyy"
                    placement="bottomEnd"
                    placeholder="Select Date Range"
                    style={{ width: 0 }}
                    //value={dateRangeValue}
                    cleanable={false}
                    ranges={[
                      {
                        label: 'Today',
                        value: [new Date(), new Date()],
                      },
                    ]}
                    onChange={(e) => handleDateChange(e)}
                    open={datePickerIsOpen}
                  />
                  <img
                    src="/assets/calendar-icon.png"
                    onClick={openDatepicker}
                  />
                </div>
              ) : (
                <div>
                  <Menu
                    id="table-dp-filter"
                    anchorEl={anchorDp}
                    open={opendp}
                    onClose={handleDPClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <div className="tableFilter">
                      <div className="tableFilterTop">
                        <div className="tableFilterTopText">Select Year</div>
                        <div
                          className="tableFilterIcon"
                          onClick={handleDPClose}
                        >
                          <img src="/assets/cross-icon.png" />
                        </div>
                      </div>
                      <div className="yearRange">
                        <Select
                          labelId="from_year"
                          id="from_year"
                          disableUnderline={true}
                          displayEmpty
                          defaultValue=""
                          value={fromYear}
                          className="yearRangeSelect"
                          label="from_year"
                          onChange={handleFromYearChange}
                        >
                          <MenuItem value="">From Year</MenuItem>

                          {yearList.map((fy) => (
                            <MenuItem key={fy} value={fy}>
                              {fy}
                            </MenuItem>
                          ))}
                        </Select>
                        &nbsp;&nbsp;To&nbsp;&nbsp;
                        <Select
                          labelId="to_year"
                          id="to_year"
                          disableUnderline={true}
                          displayEmpty
                          defaultValue=""
                          value={toYear}
                          className="yearRangeSelect"
                          label="fromto_year_year"
                          onChange={handleToYearChange}
                        >
                          <MenuItem value="">To Year</MenuItem>
                          {yearList.map((fy) => (
                            <MenuItem key={fy} value={fy}>
                              {fy}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      <div className="tableFilterBottom">
                        <div className="tableFilterBottomL">
                          <Button
                            variant="outlined"
                            className="tableFilterCBtn"
                            onClick={handleDPClose}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="tableFilterBottomR">
                          <Button
                            variant="contained"
                            className="tableFilterPBtn"
                            onClick={handleApplyResults}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Menu>
                  <img
                    src="/assets/calendar-icon.png"
                    onClick={handleFilterDpClick}
                  />
                </div>
              )}
            </div>
            {!disableFilterSelection && (
              <div className={Styles.buttonCell}>
                <Menu
                  id="table-filter"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div className="tableFilter">
                    <div className="tableFilterTop">
                      <div className="tableFilterTopText">Filter</div>
                      <div className="tableFilterIcon" onClick={handleClose}>
                        <img src="/assets/cross-icon.png" />
                      </div>
                    </div>

                    {Object.entries(filterData)?.map(([key, val]) => {
                      return (
                        <FilterParentComponent
                          ShowSearch={true}
                          isSlider={false}
                          filterFilter={filterFilter}
                          setFilterFilter={setFilterFilter}
                          appliedFilters={appliedFilters}
                          setAppliedFilters={setAppliedFilters}
                          title={key}
                          data={val}
                          key={key}
                        />
                      );
                    })}

                    <div className="tableFilterBottom">
                      <div className="tableFilterBottomL">
                        <Button
                          variant="outlined"
                          className="tableFilterCBtn"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="tableFilterBottomR">
                        <Button
                          variant="contained"
                          className="tableFilterPBtn"
                          onClick={handleShowResults}
                        >
                          Show Results
                        </Button>
                      </div>
                    </div>
                  </div>
                </Menu>
                <img
                  src="/assets/button-base-icon.png"
                  onClick={handleFilterClick}
                />
              </div>
            )}
            <div className={Styles.bellCell}>
              <TableBellIcon isTable={true}/>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between">
        <div className="tableSMRType">
          {smrTypeSelection && (
            <div className="tableSMRTypeLeft mb-8">
              <span
                id="SMR"
                onClick={smrTypeClick}
                className={smrType === 'SMR' ? 'smrTypeActive' : ''}
              >
                SMR Equipment
              </span>
              <span
                id="NON_SMR"
                onClick={smrTypeClick}
                className={smrType === 'NON_SMR' ? 'smrTypeActive' : ''}
              >
                Non SMR Equipment
              </span>
            </div>
          )}
          {addNewSelection && (
            <div className="tableSMRTypeRight mb-8">
              <Button
                variant="contained"
                onClick={handleAddNewListing}
                className="mainBtn"
              >
                Add New Listing
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* apply the table props */}
      <div className='table-full'>
      <table
        key={forceRenderKey || 0}
        {...getTableProps()}
        className="table table-border"
      >
        <thead className="align-middle table-th-blc">
          {
            // Loop over the header rows
            headerGroups.map((headerGroup, headerGroupIndex) => (
              // Apply the header row props
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="font-semibold capitalize tracking-normal text-base align-middle"
                key={`header-row-${headerGroupIndex}`}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, idx) => (
                    // Apply the header cell props
                    <>
                      {!columnSearchable ? (
                        <th
                          className="table-th-plr text-left"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({
                              style: {
                                width:
                                  !disableCheckboxSelection && idx === 0
                                    ? '100px'
                                    : column.width || '100px',
                              },
                            })
                          )}
                        >
                          <span
                            className={`${
                              column.isSorted ? 'sorted' : null
                            } align-middle`}
                          >
                            {
                              // Render the header
                              column.render('Header')
                            }
                          </span>
                          <span className={`align-middle`}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="sorted-svg-icon">
                                  <img
                                    src="/assets/down-icon.png"
                                    className={`inline-block my-auto  w-4`}
                                  />
                                </span>
                              ) : (
                                <span className="sorted-svg-icon">
                                  <img
                                    src="/assets/up-icon.png"
                                    className={`inline-block my-auto  w-4`}
                                  />
                                </span>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </th>
                      ) : (
                        <th className="table-th-plr  text-left">
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({
                                style: {
                                  width: column.width || '100px',
                                },
                              })
                            )}
                          >
                            <span
                              className={`${
                                column.isSorted ? 'sorted' : null
                              } align-middle`}
                            >
                              {
                                // Render the header
                                column.render('Header')
                              }
                            </span>
                            <span className={`align-middle`}>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <span className={Styles.sortedIcon}>
                                    <img
                                      src="/assets/down-icon.png"
                                      className={`inline-block my-auto  w-4`}
                                    />
                                  </span>
                                ) : (
                                  <span className={Styles.sortedIcon}>
                                    <img
                                      src="assets/up-icon.png"
                                      className={`inline-block my-auto  w-4`}
                                    />
                                  </span>
                                )
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                          <div
                            style={
                              idx === 0
                                ? { width: '50px' }
                                : {
                                    width: column.width || '130px',
                                  }
                            }
                          >
                            {column.Filter ? column.render('Filter') : null}
                          </div>
                        </th>
                      )}
                    </>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}

        <tbody {...getTableBodyProps()} className="table-tr-blc">
          {page.length ? (
            ''
          ) : (
            <tr className="odd">
              <td
                colSpan={columns.length}
                className="tables-empty"
                valign="top"
              >
                No matching records found
              </td>
            </tr>
          )}
          {
            // Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props

                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${
                            cell.column.isSorted ? 'sorted' : 'not-sorted'
                          } align-middle table-th-plr text-left`}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
      </div>
      {showPaginationBottom && (
        <div className="flex justify-center p-5">
          {page?.length > 0 ? (
            <Pagination
              count={Math.ceil(total_count / per_page)}
              shape="rounded"
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={(e, value) => pageChangeHandler(value)}
            />
          ) : (
            ' '
          )}
        </div>
      )}
    </>
  );
}
