import React from "react";
import { CommonDialog} from "components";
import Styles from "./ListingPhoto.module.scss";

const ViewPhotoModel = (props) => {
    const { open,data,handleCancel} = props;

    return (
        <>
          <CommonDialog
              open={open}
              onClose={handleCancel}
              title={data?.name}
              actions={false}
              maxWidth="md"
              modelId="ViewPhotoModel"  
              
          >
            <div className={Styles.fPhoto}>
              <img src={data?.url} className={Styles.fImg} alt="img" />
            </div>
          </CommonDialog>
        </>
    );
};
export default ViewPhotoModel;