import { PersonOutline, Logout } from '@mui/icons-material';
import React, { useState } from 'react';
import { Menu } from '@mui/material';
import TableBellIcon from "components/TableBellIcon";

const user_details = JSON.parse(localStorage.getItem('user_details'));

export default function TopSidebar() {  

  const [anchorEl, setAnchorEl] = React.useState(null);
  //Export dropdown handlers
  const openbell = Boolean(anchorEl);
  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutUser = () => {    
    localStorage.clear();
    window.location.reload(); 
  };
  
  return (
    <>
      <div className='topbar-layout'>
        <div className="logotop">
          <a href='/dashboard'><img src="/assets/full-logo-top.png" className='logo' alt="Logo"/></a>
        </div>
        <div className="topbarRight">
          <div style={{float:"right"}}>
            <div className="topbarBell"><TableBellIcon /></div>
            <div onClick={handleUserClick} className="topbarUser">
              <div className="topbarUserimg">
                {user_details?.profile_image?.attachment ? (
                  <img src={user_details?.profile_image?.attachment}  alt="User" />
                ):(
                  <img src="/assets/user-icon.png"  alt="User" />
                )}
                
              </div>
              <div className="topbarDownArrow"><img src="/assets/down-arrow-icon.png" alt="Logo" /></div>
              <div className="topbarUserName">{user_details?.name}</div>
            </div>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={openbell}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div className="statusOpactionbox">
                <div className="statusOptList">
                  <a href='/user-profile' className='profileLink'>
                    <span><PersonOutline sx={{ fontSize: 20 }}/></span>
                    <span className='text'>Account</span>
                  </a>
                </div>
                <div className="statusOptList">
                  <span onClick={logoutUser} className='logoutLink'>
                    <span><Logout sx={{ fontSize: 16 }}/></span>
                    <span className='text'>Log out</span>
                  </span>
                </div>
              </div>
            </Menu>           
          </div>          
        </div>      
      </div>
    </>
  );
}
