const classForDealsMode = (status, withcolor) => {
  switch (status) {
    case 'Request Quote':
      return <p className="greenColour">Request Quote</p>;
    case 'Request Financing':
      return <p className="yellowColour">Request Financing</p>;    
    default:
      return status;
  }
};

export default classForDealsMode;
