import React, { useEffect, useMemo, useState } from "react";
import ReactTableCustom from "components/ReactTableCustom";
import FullPageLoadingModel  from "components/FullPageLoadingModel";
import { FetchTableListQuery } from "./Queries";
import { format } from "date-fns";
import { mobileFormat } from "utils";

const UsersListing = () => {   
    const [fetchUsersListPayload, setFetchUsersListPayload] = useState(null);   
    const { data: usersList,isLoading } = FetchTableListQuery(fetchUsersListPayload);
    const data = useMemo(() => usersList?.results || [], [usersList]);
    const [filterData, setFilterData] = useState({'category':[],'status':[],'brand':[]});

    const columns = useMemo(
        () => [          
          {
            Header: "Name",
            accessor: "name",          
            width: "120px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p>{original?.name}</p>
                )
              },
          },
          {
            Header: "Contact",
            accessor: "phone",            
            width: "200px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>                        
                        <p>{original && original?.phone ? mobileFormat(original.phone) : null}</p>                   
                        <p className="font10">{original?.email}</p>
                    </div>
                    
                )
              },
          },
          {
            Header: "Date",
            accessor: "created_at",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <div>
                        <p>{original && original.created_at ? format(new Date(original.created_at), "MMM dd, yyyy") : null}</p>
                        <p className="font10 fw400 grey60color">{original && original.created_at ? format(new Date(original.created_at), "hh:mm b") : null}</p>
                    </div>
                )
                  
                },           
          },
          {
            Header: "No. of Products",
            accessor: "listing_count",
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p><span className="grayLabel">{original?.listing_count}</span></p>
                )
                  
                },
           
          },          
          {
            Header: "Requested Quote",
            accessor: "quote_count",            
            width: "150px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p><span className="greenLabel">{original?.quote_count}</span></p>
                )
            },
          },
          {
            Header: "Financing Request",
            accessor: "finance_count",            
            width: "160px",
            Cell: ({ row: { original } }) => {
                return ( 
                    <p><span className="yellowLabel">{original?.finance_count}</span></p>
                )
            },
          },          
          {
            Header: "Actions",
            accessor: "actions",
            Cell: ({ row: { original } }) => (
                <div className="text-center">
                  <button
                    onClick={() => {
                      handleViewItem(original?.id,'Transactions');
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/transactions-icon.png" alt="Transactions" title="Transactions"/>
                    </span>
                  </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    onClick={() => {
                      handleViewItem(original?.id,'');
                    }}
                    className="tableActionsBtn"
                  >
                    <span>
                      <img src="/assets/actions-icon.png" alt="Actions" title="Actions"/>
                    </span>
                  </button>
                </div>
              ),           
            width: "90px",
            disableSortBy: true,
            disableExport: true,
          },
        ],
        []
      );

    const [search, setSearch] = useState(null);    
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [dropdownPageSize, setDropdownPageSize] = useState(30);
    const [sorts, setSorts] = useState([ {
                                id: "id",
                                desc: false,
                                },
                            ]);
    const setDropdownPageSizeHandler = (count) => {
        setCurrentPage(1);
        setDropdownPageSize(count);
    };
    const pageChangeHandler = (pageNo) => {
        setCurrentPage(pageNo);
    };
    const setSearchHandler = (searchKeyword) => {
        setSearch(searchKeyword);
    };
    const setDateHandler = (value) => {        
        if (typeof value == 'object' && value != null) { 
            const from_date = format(new Date(value[0]), "yyyy-MM-dd");
            const to_date = format(new Date(value[1]), "yyyy-MM-dd");
            setFromDate(from_date);
            setToDate(to_date);
          }
    };
    const setSortsHandler = (col) => {
        setSorts(col);
    };
    /**********  Filter ***********/
    const [filterValues,setFilterValues]= useState([]);
    const [filterFilter,setFilterFilter]= useState([]);
    const [filterBtnValue,setFilterBtnValue]= useState([]);

    const setFilterBtnHandler = (value) => {        
        if (typeof value == 'object' && value != null) {             
            setFilterBtnValue(value);            
        }
    };

    const handleViewItem = (value,type) => {        
        window.open(`users/details?id=${value}&type=${type}`, "_blank")
    };

    useEffect(() => {
        setFetchUsersListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
    }, [dropdownPageSize]);

    useEffect(() => {
        setFetchUsersListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
    }, [currentPage]);

    useEffect(() => {
        if (search || search === "") {
        setCurrentPage(1);
        setFetchUsersListPayload({
            page: 1,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
        }
    }, [search]);

    useEffect(() => {
        if (sorts) {
        setFetchUsersListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
        }
    }, [sorts]);

    useEffect(() => {
        setFetchUsersListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue     
        });
      }, [fromDate,toDate]);    

    useEffect(() => {
        setFetchUsersListPayload({
            page: currentPage,
            per_page: dropdownPageSize,
            search,
            sorts,
            from_date: fromDate,
            to_date: toDate,
            filter_value: filterBtnValue
        });
      }, [filterBtnValue]); 
    return (        
        <div>
            <div className='flex'>
            <div className="mx-auto my-8 bg-white px-8 py-4 rounded" style={{ width: "100%", padding:"0", margin:"0"}}  >
                <FullPageLoadingModel
                    open={Boolean(isLoading)}
                />
                <ReactTableCustom
                    dropdownPageSize={dropdownPageSize}
                    setDropdownPageSize={setDropdownPageSizeHandler}
                    currentPage={currentPage}
                    pageChangeHandler={pageChangeHandler}
                    data={data}
                    columns={columns}
                    filterData={filterData}
                    paginationProps={usersList?.pagination_option}
                    setSearchHandler={setSearchHandler}
                    setDateHandler={setDateHandler}
                    setFiltersHandler={setSortsHandler}
                    appliedFilters={filterValues}
                    setAppliedFilters={setFilterValues}
                    filterFilter={filterFilter}
                    setFilterFilter={setFilterFilter}
                    setFilterBtnHandler={setFilterBtnHandler}
                    placeholder={"Sellar Listing"}
                    initialStateSortBy={sorts}
                    showPaginationBottom={true}
                    columnSearchable='false'
                    disableCheckboxSelection= 'true'
                    disableFilterSelection= 'true'
                    />
                </div>
            </div>
        </div>

    );
}

export default UsersListing;