const currencyFormat = (num) => {  
  if (isNaN(num)) {
    return " ";
  } else {
    if (Number.isInteger(num)) {
      return '$' + Number.parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') 
    }
    else {
      return '$' + Number.parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') 
    }
  }  
};
export default currencyFormat;