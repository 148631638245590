const classForInquiryListingStatus = (status) => {
  switch (status) {
    case "QUOTE_SENT":
        return ( 
            <p className="yellowColour">
                &#x2022; Quote Sent
            </p>
        )
    case "DEPOSIT":
        return ( 
            <p className="greenColour">
                &#x2022; Deposit Pending
            </p>
        )
    case "DEPOSIT_SUCCESSFUL":
        return ( 
            <p className="greenColour">
                &#x2022; Deposit Successful
            </p>
        )
    case "COMPLETE":
        return ( 
            <p className="grayColour">
                &#x2022; Complete
            </p>
        )
    case "AMOUNT_REFUNDED":
        return ( 
            <p className="redColour">
                &#x2022; Deposit Refunded
            </p>
        )
    case "DENIED":
        return ( 
            <p className="redColour">
                &#x2022; Denied
            </p>
        )
    case "QUOTE_REMOVED":
        return ( 
            <p className="redColour">
                &#x2022; Quote Removed
            </p>
        )        
        
   
  default:
    return status;
}

};

export default classForInquiryListingStatus;
