import { useQuery,useMutation} from "@tanstack/react-query";
import { useAPI, useToast } from "services";

const sortHelperFun = (key) => {
  switch (key) {
    case "Section":
      return "spot_section_name";
    case "Target screen":
      return "spot_target_screen";
    default:      
      if(key.desc){
        return key.id;
      } else {
        return '-'+key.id;
      }
     
  }
};


const filterHelperFun = (filter_value) => {
  let filter_url = '';
  let category_url = '';
  filter_value?.forEach((fv)=>{    
    if(fv.filter==='category'){
      category_url += `${fv.label},`;
    }
    
  })
  if(category_url !==''){
    filter_url += `&listing__category__name__in=${category_url}`;    
  }  
  return filter_url;
};

const FetchTableList = (body) => {
  const method = "GET";
  const sorts = body?.sorts;
  let url = `deals-in-progress/?page=${body?.page || 1}&per_page=${
    body?.per_page
  }&search=${body?.search ? body.search : ""}`;

  if (sorts && sorts.length > 0) {
    url += `&ordering=${sortHelperFun(sorts[0])}`;
  }
  if (body?.from_date && body?.to_date) {
    url += `&created_at_after=${body?.from_date}&created_at_before=${body?.to_date}`;
  }
  if (body?.filter_value && body?.filter_value.length > 0) {
    url += filterHelperFun(body?.filter_value);
  }
  
  return useAPI({ method, url });
};



export const FetchTableListQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DEALS_LIST", payload],
    () => FetchTableList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchCategoriesList = (body) => {
  const method = "GET";
 
  let url = `category/`;

  
  
  return useAPI({ method, url });
};

export const FetchCategoriesQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DEALS_CAT_LIST", payload],
    () => FetchCategoriesList(payload),
    {
      enabled: Boolean(payload?.per_page),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchDealPDetails = (body) => {
  const method = "GET"; 
  let url = `retrive-deals-in-progress/${body}/`;  
  
  return useAPI({ method, url });
};

export const FetchDealPDetailsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DP_DETAILS", payload],
    () => FetchDealPDetails(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchListingDetails = (body) => {
  const method = "GET"; 
  let url = `listing/${body}/`;  
  
  return useAPI({ method, url });
};

export const FetchListingDetailsQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DP_Listing", payload],
    () => FetchListingDetails(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const FetchFinance = (body) => {
  const method = "GET"; 
  let url = `finance/${body}/`;  
  
  return useAPI({ method, url });
};

export const FetchFinanceQuery = (payload) => {
  const responseToast = useToast();
  return useQuery(
    ["GET_DP_finance", payload],
    () => FetchFinance(payload),
    {
      enabled: Boolean(payload),
      onSuccess: () => null,
      onError: (error) => responseToast.toastError(error && error.message),
    }
  );
};

const SendDoctoSlateFin = (body) => {
  const method = "POST";
  const url = `send-documents/`;
  const formData = {
    finance_id: body  
  };
  
  return useAPI({
    method,
    url,
    body: formData,
  });
};

export const FetchSendDocToSlateFinancialQuery = () => {
  const responseToast = useToast();
  return useMutation(SendDoctoSlateFin, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error.message),
  });
};

const SendRefundDeposited = (body) => {
  const method = "POST";
  const url = `refund/`;
  const formData = {
    transaction_id: body.transaction_id,
    quote_id: body.quote_id,
    payment_for: "Quotes"
  };
  
  return useAPI({
    method,
    url,
    body: formData,
  });
};

export const FetchSendRefundDepositedQuery = () => {
  const responseToast = useToast();
  return useMutation(SendRefundDeposited, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error.message),
  });
};

const FetchAskToUploadDoc = (body) => {
  const method = "POST";
  const url = `notify-documents/`; 
  return useAPI({
    method,
    url,
    body: body,
  });
};

export const FetchAskToUploadDocQuery = () => {
  const responseToast = useToast();
  return useMutation(FetchAskToUploadDoc, {
    onSuccess: (response) => {
      responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error.message),
  });
};
